import { adminConstants } from "../_constants";

export function admin(state = {}, action) {
  switch (action.type) {
    case "GET_USER_REQUEST":
      return { ...state, listUser: action.listUser };
    case "GET_LIST_DEPARTMENT":
      return {
        listDepartment: action.listDepartment
      };
    case "MESSAGE":
      return {
        message: action.message
      };

    case adminConstants.ADMIN_DELETE_USER_REQUEST:
      return {
        ...state,
        isDeleteUsersProcessing: action.isDeleteUsersProcessing
      };
    case adminConstants.ADMIN_SEND_MAIL_INVITE_USER_REQUEST:
      return {
        ...state,
        isSendMailUsersProcessing: action.isSendMailUsersProcessing
      };
    default:
      return state;
  }
}
