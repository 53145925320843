import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { userMessages } from "../_messages";

export const userActions = {
  login,
  logout,
  logoutAdmin,
  getAll,
  changePassword,
  forgotPassword,
  forgotPasswordConfirm,
  checkLogin,
  checkFirstTimeLogin,
  checkFirstTimeLoginForgotPassword,
  SuperAdminchangePassword,
  checkContentDownloadToken,
  checkContentDownloadEmail
};



function checkContentDownloadToken(token) {
  return dispatch => {
    userService.checkContentDownloadTokens(token).then(response => {
      
      return response.data;
    }).catch(error => {
      history.push('/content-download/authenticate/error');
      dispatch(alertActions.error(error.data));
    })
  }
}

function checkContentDownloadEmail(email,book) {
  return dispatch => {
    userService
     .checkContentDownloadEmail(email,book)
     .then(contentDownload => {
       dispatch(success(contentDownload));
       if (contentDownload.data.code === 403) {
         history.push("/admin/403");
         
       }
     })
     .catch(error => {
       dispatch(alertActions.error(error.data));
     });
 };

 function success(contentDownload) {
   return {
     type: userConstants.EMAIL_CHECK_SUCCESS,
     contentDownload: contentDownload
   };
 }

}

function checkFirstTimeLogin(token) {
  return dispatch => {
    userService.checkFirstTimeLogin(token).then(response => {
      if(!response.data.first_time_login) {
        localStorage.removeItem("user");
        history.push('/login');
      } 
      return response.data;
    }).catch(error => {
      localStorage.removeItem("user");
      history.push('/login');
      dispatch(alertActions.error(error.data));
    })
  }
}

function checkFirstTimeLoginForgotPassword(token) {
  return dispatch => {
    userService.checkFirstTimeLogin(token).then(response => {
      if(response.data.first_time_login) {
        localStorage.removeItem("user");
        history.push('/change-password-first-time?token='+ token);
      } 
      return response.data;
    }).catch(error => {
      dispatch(alertActions.error(error.data));
    })
  }
}

function checkLogin(token) {
  return dispatch => {
    dispatch(request());
    userService
      .checkLogin(token)
      .then(user => user.data)
      .then(user => {
        dispatch(success());
        history.push("/login");
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });

    function request() {
      return { type: userConstants.CHECK_LOGIN };
    }

    function success() {
      return { type: userConstants.CHECK_LOGIN_SUCCESS };
    }
  };
}

function login(username, password, url) {
  return dispatch => {
    dispatch(request({ username }));

    userService
      .login(username, password)
      .then(user => user.data)
      .then(user => {
        if (user.code !== 200) {
          dispatch(failure(user));
          dispatch(alertActions.error(user.data.message));
        } else {
          if (user.data.is_staff === false) {
            dispatch(success(user));
            history.push("/change-password-first-time");
          } else {
            dispatch(success(user));
            if (url !== "") {
              history.push(url);
            }
            if(url === "/content-download")
            {
              history.push("/content-download");
            }
            else if(url.indexOf("/mail-magazine-article/") !== -1 )
            {
              history.push("/mail-magazine-article/*");
            }
            else if(url === "/mail-magazine")
            {
              history.push("/mail-magazine");
            }
            else{
            if (user.data.count_assessment > 0) {

              history.push("/");
            }  

            else history.push("/first-time-home-page");
          }
        }
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logoutAdmin(token) {
  return dispatch => {
    userService
      .logout(token)
      .then(user => user.data)
      .then(user => {
        if (user.data.code !== 200) {
          dispatch(alertActions.error(userMessages.ERROR_SERVER));
        } else {
          dispatch(success());
          history.push("/admin/login");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(user) {
    return { type: userConstants.LOGOUT };
  }
}

function logout(token) {
  return dispatch => {
    userService
      .logout(token)
      .then(user => user.data)
      .then(user => {
        if (user.data.code !== 200) {
          dispatch(alertActions.error(userMessages.ERROR_SERVER));
        } else {
          dispatch(success());
          history.push("/login");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(user) {
    return { type: userConstants.LOGOUT };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll().then(
      users => dispatch(success(users)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function changePassword(current_password,password, confirmed_password, is_new_user,token) {
  
  return dispatch => {
    dispatch(request());
    userService
      .changePassword(current_password,password, confirmed_password,token)
      .then(user => {
        if (user.status !== 200) {
          dispatch(failure(user));
          dispatch(alertActions.error(user.data.data.message));
        } else {
          dispatch(success());
          if (is_new_user === false) {
            dispatch(
              localStorage.removeItem("user"),
              history.push("/change-password-success")
            );
          } else {
            dispatch(success(user.data));
            history.push("/first-time-home-page");
          }
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function forgotPassword(email) {
  return dispatch => {
    dispatch(request());
    userService
      .forgotPassword(email)
      .then(data => data.data)
      .then(data => {
        if (data.code !== 200) {
          dispatch(failure(data));
          dispatch(alertActions.error(data.data.message));
        } else {
          dispatch(success());
          dispatch(alertActions.success(userMessages.FORGOT_PASSWORD_MESSAGES));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function forgotPasswordConfirm(password, token, role) {
  return dispatch => {
    dispatch(request());
    userService
      .forgotPasswordConfirm(password, token)
      .then(user => user.data)
      .then(user => {
        if (user) {
          if (user.code === 200) {
            dispatch(success());
            dispatch(
              alertActions.success(
                userMessages.FORGOT_PASSWORD_CONFIRM_MESSAGES
              )
            );
            if (role === "admin") {
              setTimeout(() => {
                history.push("/admin/login-admin");
              }, 3000);
            } else history.push("/change-password-success");
          } 
          else if (user.code === 403){
            dispatch(failure(user));
            dispatch(
              alertActions.error(userMessages.FORGOT_PASSWORD_EXPERT_TIME,'forgot-password-expiry')
            );
          }
          else {
            dispatch(failure(user));
            dispatch(
              alertActions.error(user.password)
            );
          }
        } else {
          dispatch(alertActions.error(user.password));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function SuperAdminchangePassword(current_password,password, confirmed_password, is_new_user,token) {
  
  return dispatch => {
    dispatch(request());
    userService
      .changePassword(current_password,password, confirmed_password,token)
      .then(user => {
        if (user.status !== 200) {
          dispatch(failure(user));
          dispatch(alertActions.error(user.data.data.message));
        } else {
          dispatch(success());
          if (is_new_user === false) {
            dispatch(
              localStorage.removeItem("user"),
              history.push("/admin/change-password-success")
            );
          } else {
            dispatch(success(user.data));
            history.push("/admin/company");
          }
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}
