import item1Admin from "../../../assets/styles/img/item1Admin.png";
import item2Admin from "../../../assets/styles/img/item2Admin.png";
import React from "react";
const MenuAdmin = () => (
  <div className="left-menu">
    <ul className="menu">
      <li className="menuitem">
        <a href="/admin" className="content-menu ">
          <img
            src={item1Admin}
            alt="Home"
            title="ホーム"
            className="iconmenu"
          />
          <span className="title-menu">ダッシュボード</span>
        </a>
      </li>
      <li className="menuitem">
        <a href="/admin/account" className="content-menu">
          <img src={item2Admin} alt="" title="進捗管理" className="iconmenu" />
          <span className="title-menu">アカウント管理</span>
        </a>
      </li>
    </ul>
  </div>
);
export default MenuAdmin;
