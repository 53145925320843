import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import emailValidator from "email-validator";
import { loginActions } from "../_actions";
import { loginMessages } from "../_messages";
import { isStagingServer } from "../../_helpers";

import "../../../assets/styles/css/main-phase-2.css";

import Loginpc from "../../../assets/styles/img/logologin.png";
import ErrorIcon from "../../../assets/styles/img/error.png";

class AdminLoginPagePhase2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false,
      isStaging:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }   
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  isValidatorPassword = (email, password) => {
    let flag = false;
    if (!email) {
      this.props.alert.message = loginMessages.EMAIL_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!emailValidator.validate(email)) {
      this.props.alert.message = loginMessages.EMAIL_NOT_TRUE;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!password) {
      this.props.alert.message = loginMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  };
  handleSubmit(e) {
    e.preventDefault();

    let url = "";
    if (this.props.location.state !== undefined) {
      url = this.props.location.state.from.pathname;
      if (this.props.location.state.from.search) {
        url = url + this.props.location.state.from.search;
      }
    }

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    this.isValidatorPassword(username, password);
    if (this.isValidatorPassword(username, password) === true) {
      dispatch(loginActions.login(username.toLowerCase(), password, url));
    }
  }
  render() {
    const { alert } = this.props;
    const { username, password } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper login">
          <div className="login-content">
          {this.state.isStaging===true ? (<div className="staging-header-marker"> Staging </div>):""}
            <div className="logo-login">
              <a href="#/" className="link-logo-login" onClick={event => event.preventDefault()}>
                <img src={Loginpc} alt="" className="icon-logo-login" />
              </a>
            </div>
            <div className="form-login">
              <div className="form-login-content">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className="form-group d-block mb-22">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-form"
                    >
                      メールアドレス
                    </label>
                    <div className="form-input">
                      <input
                        name="username"
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={username}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-mb d-block">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-form"
                    >
                      パスワード
                    </label>
                    <div className="form-input">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <Link to="/admin/forgot-password" className="link-forgot">
                    パスワードをお忘れの場合
                  </Link>
                  {alert.message && (
                    <div className="danger-cm">
                      <img src={ErrorIcon} alt="" />
                      <span className="error-detail">{alert.message}</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="btn-login text-center mt-24">
              <button
                className=" btn bnt-submit-form-phase2 bnt-submit-form1"
                onClick={this.handleSubmit}
              >
                ログインする
              </button>
            </div>
          </div>
          <div className="footer-btn fix-position-footer-btn">
            <div className="wrap-button text-center">
              <p className="design-by text-center">
                © COACH A Co., Ltd. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authAdmin;
  const { alert } = state;
  return {
    loggingIn,
    alert,
  };
}

const connectedAdminLoginPagePhase2 = connect(mapStateToProps)(
  AdminLoginPagePhase2
);
export { connectedAdminLoginPagePhase2 as AdminLoginPagePhase2 };
