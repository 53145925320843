export const adminConstants = {
  LOGIN_ADMIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  LOGIN_ADMIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  LOGIN_ADMIN_FAILURE: "ADMIN_LOGIN_FAILURE",

  ADMIN_DELETE_USER_REQUEST: "ADMIN_DELETE_USER_REQUEST",
  ADMIN_SEND_MAIL_INVITE_USER_REQUEST: "ADMIN_SEND_MAIL_INVITE_USER_REQUEST",

  LOGOUT_ADMIN: "ADMIN_LOGOUT",
  STAGING_API_URL:"https://api.test.3mins.coacha.com"
};
