import React from "react";

import { connect } from "react-redux";

import "../../../../assets/styles/css/main-phase-2.css";

// import Logo from "../../../../assets/styles/img/logo.png";
const FooterAdmin = props => {
  return (
    <footer>
      <p className="design-by-phase2 text-center">
        © COACH A Co., Ltd. All Rights Reserved.
      </p>
    </footer>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, null)(FooterAdmin);
