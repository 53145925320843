import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import Pagination from "react-js-pagination";
import queryString from "query-string";
import {
  userAdminActions,
  workshopAdminActions,
  companyActions,
} from "../_actions";
import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import LeftContentNav from "../Components/LeftContentNav";

import "../../../assets/styles/css/main-phase-2.css";
import { companyServices } from "../_services";

class DetailCompanyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalUsers: 0,
      listUsersAtPage: [],
      isShowFilterBox: false,
      listColumnTableUsers: [
        { id: 0, name: "ステータス", request: "sort_status", sortBy: 0 },
        { id: 1, name: "社員ID", request: "sort_employee_id", sortBy: 0 },
        { id: 2, name: "管理者", request: "sort_is_admin", sortBy: 0 },
        { id: 3, name: "名前", request: "sort_last_name", sortBy: 0 },
        { id: 4, name: "メールアドレス", request: "sort_email", sortBy: 0 },
        { id: 5, name: "ワークショップ日程", request: "sort_date_workshop", sortBy: 0},
        { id: 6, name: "インベントリー", request: "sort_assessment", sortBy: 0 },
        { id: 7, name: "メルマガ配信", request: "sort_mail_magazine", sortBy: 0 },
        { id: 8, name: "メルマガ進捗",request: "sort_newsletter",sortBy: 0 },
        { id: 9, name: "ログイン最終日時", request: "sort_last_login", sortBy: 0 },
        { id: 10, name: "サイト閲覧期限"},
        { id: 11, name: "ログイン案内日時"},
        { id: 12, name: "登録メール再送日時"},
        { id: 13, name: "回答リマインド日時"},
        { id: 14, name: "役職", request: "sort_position_department", sortBy: 0 },
        { id: 15, name: "部署", request: "sort_department", sortBy: 0 },
        { id: 16, name: "ふりがな（姓）"},
        { id: 17, name: "ふりがな（名）"},
        { id: 18, name: "備考"},
      ],
      listUserChecked: [],
      numberUserCheckedAtPage: 0,
      isCheckedAll: false,
      listWorkshop: [],
      showfooterbtn: false,
      searchText: "",
      statusFilter: "",
      assessmentFilter: "",
      workshopFilter: "",
      sortByColumn: "",
      checkedStatusButton: 0,
      checkedInventory:0,
      checkedLoginStatus: 0,
      showWorkShowDetailInfo: false,
      companyUUID:"",
      visibleModal: false,
      visibleModalDeleteCompany: false,
      isDeleteCompany: false,
      visibleAssessmentModal: false,
    };
  }
  componentDidMount() {
    //Component vua load het trang
    window.scrollTo(0, 0);
    this.checkButtonHeader();
    this.setState({ companyUUID: this.props.match.params.companyUUID });
    this.props.dispatch(
      userAdminActions.getListUserAction(
        this.props.match.params.companyUUID,
        this.state.activePage - 1
      )
    );
    // workshopAdminServices.getListWorkshopsService(1);
    // userAdminServices.getActiveAccountCompanyService("");
    this.props.dispatch(
      workshopAdminActions.getListWorkshopsAction(
        this.props.match.params.companyUUID
      )
    );
    this.props.dispatch(
      userAdminActions.getActiveAccountCompanyAction(
        this.props.match.params.companyUUID
      )
    );
    this.props.dispatch(
      userAdminActions.getCompanyDetailInfoAction(
        this.props.match.params.companyUUID
      )
    );
  }
  componentDidUpdate(prevProps) {
    //trang thai updating
    if (prevProps.listUsers !== this.props.listUsers) {
      this.setState({ listUsersAtPage: this.props.listUsers.results }, () => {
        this.numberCheckedAtPagagination();
        this.isCheckedUserStatusShowButton();
      });
      this.setState({ totalUsers: this.props.listUsers.count });
    }

    if (prevProps.workshopsAdmin !== this.props.workshopsAdmin) {
      this.setState({
        listWorkshop: this.props.workshopsAdmin,
      });
    }

    if (
      prevProps.isDeleteCompanySuccess !== this.props.isDeleteCompanySuccess &&
      this.props.isDeleteCompanySuccess === false
    ) {
      this.setState({
        visibleModalDeleteCompany: true,
      });
      this.props.dispatch(companyActions.resetIsDeleteCompanyStatusAction());
    }
    const values = queryString.parse(this.props.location.search);
    if(values.return==='true' && prevProps.listUsers.count && this.props.listUsers.count!==prevProps.listUsers.count ){
      let offset = this.props.listUsers.count%20 === 0 ? Math.floor(this.props.listUsers.count/20) - 1 : Math.floor(this.props.listUsers.count/20);
      this.handlePageChange(offset+1);
    }

  }
  onClickSortActive = (event) => {
    let id_column = parseInt(event.target.getAttribute("id"));
    let array_column_name = this.state.listColumnTableUsers;
    let sortByColumn = array_column_name[id_column].request;

    if (array_column_name[id_column].sortBy < 2)
      array_column_name[id_column].sortBy++;
    else array_column_name[id_column].sortBy = 0;

    array_column_name.forEach((column_name) => {
      if (column_name.id !== id_column) column_name.sortBy = 0;
    });
    if (array_column_name[id_column].sortBy === 1) {
      sortByColumn += "=0";
    }
    if (array_column_name[id_column].sortBy === 2) {
      sortByColumn += "=1";
    }
    if (array_column_name[id_column].sortBy === 0) {
      sortByColumn = "";
    }
    this.setState(
      {
        listColumnTableUsers: array_column_name,
        sortByColumn: sortByColumn,
      },
      () => this.requestListUserSearchSortFilter()
    );
  };

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.pageNumber) {
      this.setState(
        {
          activePage: pageNumber,
          isCheckedAll: false,
          numberUserCheckedAtPage: 0,
        },
        () => {
          this.requestListUserSearchSortFilter();
        }
      );
    }
  }

  onClickShowFilterBox = (event) => {
    event.preventDefault();
    this.setState(
      {
        isShowFilterBox: !this.state.isShowFilterBox,
        listUserChecked: [],
        statusFilter: "",
        assessmentFilter: "",
        workshopFilter: "",
        isCheckedAll: false,
      },
      () => this.requestListUserSearchSortFilter()
    );
  };
  onClickDownloadCSV = (event) => {
    event.preventDefault();
    this.props.dispatch(
      userAdminActions.downloadCSVAction(this.state.companyUUID, this.state.sortByColumn,this.state.statusFilter,this.state.assessmentFilter,this.state.workshopFilter)
    );
  };
  onClickAddUserChecked = (user) => {
    let listUserChecked = this.state.listUserChecked;
    const existingUser = this.state.listUserChecked.find(
      (userChecked) => userChecked.id === user.id
    );
    if (existingUser) {
      listUserChecked = this.state.listUserChecked.filter(
        (userChecked) => userChecked.id !== user.id
      );
      this.setState(
        {
          numberUserCheckedAtPage: this.state.numberUserCheckedAtPage - 1,
        },
        () => {
          if (
            this.state.numberUserCheckedAtPage <
            this.state.listUsersAtPage.length
          ) {
            this.setState({ isCheckedAll: false });
          }
        }
      );
    } else {
      ///Truong hop check kiem tra neu du nguoi bat check all
      this.setState(
        {
          numberUserCheckedAtPage: this.state.numberUserCheckedAtPage + 1,
        },
        () => {
          if (
            this.state.numberUserCheckedAtPage ===
            this.state.listUsersAtPage.length
          ) {
            this.setState({ isCheckedAll: true });
          }
        }
      );
      listUserChecked = [...this.state.listUserChecked, user];
    }
    this.setState({ listUserChecked: listUserChecked }, () => {
      this.isCheckedUserStatusShowButton();
      if (
        this.state.numberUserCheckedAtPage === this.state.listUsersAtPage.length
      ) {
        this.setState({ isCheckedAll: true });
      }
    });
  };

  addOneUserToArray = (user, listCheckedUser) => {
    let existingUser = listCheckedUser.find(
      (userChecked) => userChecked.id === user.id
    );

    if (existingUser) {
      return "";
    } else {
      return user;
    }
  };

  onCheckAllTable = () => {
    let listUserChecked = this.state.listUserChecked;
    let listUserAtPage = this.state.listUsersAtPage;
    listUserAtPage.forEach((user) => {
      let checkUser = this.addOneUserToArray(user, listUserChecked);
      if (checkUser === "") {
        if (this.state.isCheckedAll === true) {
          listUserChecked = listUserChecked.filter(
            (userChecked) => userChecked.id !== user.id
          );
          this.setState({
            numberUserCheckedAtPage: 0,
          });
        }
      } else {
        if (this.state.isCheckedAll === false) {
          listUserChecked = [...listUserChecked, user];
          this.setState({
            numberUserCheckedAtPage: listUserAtPage.length,
          });
        }
      }
    });
    this.setState(
      {
        listUserChecked: listUserChecked,
        isCheckedAll: !this.state.isCheckedAll,
      },
      () => this.isCheckedUserStatusShowButton()
    );
  };
  isDefaultChecked = (user) => {
    let existingUser = this.state.listUserChecked.find(
      (userChecked) => userChecked.id === user.id
    );
    if (existingUser) {
      return true;
    } else return false;
  };
  getColorLoadWorkshopTable = (workshopCode) => {
    try {
      let workshopColor = this.state.listWorkshop.filter(
        (workshop) => workshop.code === workshopCode
      );
      return workshopColor[0].color;
    } catch (error) { }
  };
  numberCheckedAtPagagination = () => {
    let numberChecked = 0;
    this.state.listUsersAtPage.forEach((user) => {
      let isChecked = this.isDefaultChecked(user);
      if (isChecked === true) {
        numberChecked = numberChecked + 1;
      }
    });
    this.setState({ numberUserCheckedAtPage: numberChecked });
    if (
      numberChecked !== 0 &&
      numberChecked === this.state.listUsersAtPage.length
    ) {
      this.setState({ isCheckedAll: true });
    }
  };
  isCheckedUserStatusShowButton = () => {
    let statusCheckedShowButton = 0;
    let existingUserActive = this.state.listUserChecked.find(
      (userChecked) => userChecked.is_active === true
    );
    let existingUserInActive = this.state.listUserChecked.find(
      (userChecked) => userChecked.is_active === false
    );
    if (existingUserActive) {
      statusCheckedShowButton = 1;
    }
    if (existingUserInActive) {
      statusCheckedShowButton = 2;
    }
    if (existingUserActive && existingUserInActive) {
      statusCheckedShowButton = 3;
    }

    let checkedInventoryValue = 0;
    let inventoryFalse = this.state.listUserChecked.find(
      (userChecked) => userChecked.assessment === false
    );
    let inventoryTrue = this.state.listUserChecked.find(
      (userChecked) => userChecked.assessment === true
    );
    if (inventoryFalse) {
      checkedInventoryValue = 1;
    }
    if (inventoryTrue) {
      checkedInventoryValue = -1;
    }

    let loginStatusCheckedButton = 0;
    let workshopcode = this.state.listUserChecked.map((workshop) => {
      return workshop.code_workshop
    });

    let userworkshop = this.state.listWorkshop.find(
      (usercode) => usercode.code === workshopcode[0]
    );

    let checkedusers = this.state.listUserChecked.map((checked) => { 
      return checked
    })

    var loginstatuscounter = 0
    if (this.state.listUserChecked.length > 1)
      for (const data of checkedusers) {
        loginstatuscounter = loginstatuscounter+1
        let checkall = this.state.listWorkshop.find(
          (checkcode) => checkcode.code === data.code_workshop
        )
        if (checkall?.is_login_status) {
          loginStatusCheckedButton = 1;
        }
        if (!checkall?.is_login_status  & loginstatuscounter > 0 ) {
          loginStatusCheckedButton = 0;
          checkedInventoryValue = 0;
          break;
        } 
      }
    else if (userworkshop) {

      if (userworkshop.is_login_status === true) {
        loginStatusCheckedButton = 1;
      }
      if (!userworkshop?.is_login_status) {
        loginStatusCheckedButton = 0;
        checkedInventoryValue = 0;
      }
    }
    this.setState({ checkedStatusButton: statusCheckedShowButton, checkedInventory: checkedInventoryValue, checkedLoginStatus: loginStatusCheckedButton });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeFilterBox = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, activePage: 1, listUserChecked: [], isCheckedAll: false }, () =>
      this.requestListUserSearchSortFilter()
    );
  };
  handleSubmitSearch = (event) => {
    event.preventDefault();
    this.setState({ activePage: 1 }, () => {
      this.requestListUserSearchSortFilter();
    });
  };
  requestListUserSearchSortFilter = () => {
    const {
      searchText,
      statusFilter,
      assessmentFilter,
      workshopFilter,
      sortByColumn,
    } = this.state;

    this.props.dispatch(
      userAdminActions.getListUserAction(
        this.state.companyUUID,
        this.state.activePage - 1,
        searchText,
        statusFilter,
        assessmentFilter,
        workshopFilter,
        sortByColumn
      )
    );
  };
  onClickShowInfoWorkshop = () => {
    this.setState({
      showWorkShowDetailInfo: !this.state.showWorkShowDetailInfo,
    });
  };
  onClickActiveUser = () => {
    const list_users = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.companyUUID}/admin-confirm-completed/0`,
      state: { users: list_users, company: this.props.match.params.companyUUID },
    })
  };
  onClickDeactiveUser = () => {
    const list_users = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.companyUUID}/admin-confirm-completed/1`,
      state: { users: list_users, company: this.props.match.params.companyUUID },
    })
  };
  onClickDeleteUser = () => {
    const list_users = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.companyUUID}/admin-confirm-completed/2`,
      state: { users: list_users, company: this.props.match.params.companyUUID },
    })
  };
  onClickSendMailInviteUser = () => {
    let list_users = this.state.listUserChecked.map((user) => {
      return {
        user_id: user.id,
        workshop_code: user.code_workshop,
      };
    });
    this.props.dispatch(
      userAdminActions.sendMailInviteUserAccountAction(list_users,this.props.match.params.companyUUID)
    );
    this.closeModal();
  };

  onClickSendAssessmentMail = () => {
    let user_list = this.state.listUserChecked.map((user) => {
      return user.id;
    });
    this.props.dispatch(
      userAdminActions.sendAssessmentMailAction(user_list,this.state.companyUUID)
    );
    this.closeAssessmentEmailModal();
  }

  openAssessmentEmailModal = (event) => {
    this.setState({visibleAssessmentModal:true,});
  };

  closeAssessmentEmailModal = (event) => {
    this.setState({visibleAssessmentModal: false});
  };

  openModal = (event) => {
    this.setState({
      visibleModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      visibleModal: false,
      visibleModalDeleteCompany: false,
      isDeleteCompany:false,
    });
  };
  deleteCompany = (event) => {
    event.preventDefault();
    this.setState({ isDeleteCompany: true }, () =>
      this.props.dispatch(
        companyActions.deleteCompanyAction(this.state.idCompany)
      )
    );
  };

  deleteCompanySuccess=(event)=>{
    event.preventDefault();
    this.props.dispatch(
      companyActions.deleteCompanyAction(this.state.companyUUID)
    )
  }
  onScrollTable = () => {
    const $ = require("jquery");
    $(".box-theadtable").scrollLeft($(".box-tbodytable").scrollLeft());
    var scroll_left =
      $(".box-tbodytable").get(0).scrollWidth -
      $(".box-tbodytable").get(0).clientWidth;

    $(".box-tbodytable").scroll(function () {
      if (scroll_left === $(this).scrollLeft()) {
        $(".shadow-table").removeClass("js-shadow-table");
      } else {
        $(".shadow-table").addClass("js-shadow-table");
      }
    });
  };
  checkButtonHeader = () => {
    const $ = require("jquery");
    var scroll_left_button =
      $(".top-content-responsive").get(0).scrollWidth -
      $(".header").get(0).clientWidth -
      $(window).scrollLeft();
    $(".btn-header-responsive").animate(
      { width: scroll_left_button + "px" },
      0
    );
  };
  render() {
    const {
      isShowFilterBox,
      isCheckedAll,
      searchText,
      listWorkshop,
      showWorkShowDetailInfo,
    } = this.state;
    const { activePercent, admin, company } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies enterprise-details">
          <HeaderAdmin isCoachaAdmin="true">Detail Company</HeaderAdmin>
          <div className="content content-fix-phase2">
            <div className="top-content top-content-responsive">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {company.name}
                  </span>
                </li>
                <a style={{marginLeft:"130px"}} href={`/admin/detail-company/${this.state.companyUUID}`} className="linktab active">
                  企業詳細・ユーザーー覧
                </a>
                <a
                  href={`/admin/workshop/${this.state.companyUUID}`}
                  className="linktab"
                >
                  ワークショップー覧
                </a>
                {admin.data.is_supper_admin === true ? (
                  <a
                    href="#/"
                    className="bnt-submit-cm-1-delete"
                    onClick={this.deleteCompany}
                  >
                    この企業を削除する
                  </a>
                ) : (
                  ""
                )}</ul>
              <div className="btn-header-responsive"></div>
            </div>

            <div className="tab-data clearfix">
              <div className="group-tab-content clearfix">
                <div id="tab-1" className="tab-content active clearfix">
                  <div className="content-50">
                    <div className="wrap-tab">
                      <div className="left-content">
                        <LeftContentNav
                          companyUUID={this.state.companyUUID}
                          listWorkshop={listWorkshop}
                          isShowWorkShowDetailInfo={showWorkShowDetailInfo}
                          onClickShowInfoWorkshop={this.onClickShowInfoWorkshop}
                          companyInfo={company}
                          adminAuth={admin}
                        ></LeftContentNav>
                      </div>

                      <div className="wrap-table">
                        <div className="form-table clearfix">
                          <div className="wrapform">
                            <p className="title">
                              ユーザーー覧
                              <span className="title-detail">
                                有効 {activePercent.total_user_active}人 /
                                参加者全体 {activePercent.total_user}人
                              </span>
                              <span style={{marginLeft:"25px"}}className="container-checkbox">
                                <input
                                  type="checkbox"
                                  checked={true}
                                />
                                <span style={{top:"30%"}} className="checkmark"/>
                                <span style={{marginLeft:"0px"}}className="title-detail">選択{this.state.numberUserCheckedAtPage}人</span>
                              </span>
                            </p>
                          </div>
                          <div className="wrapbtn">
                            <div className="search-container">
                              <form onSubmit={this.handleSubmitSearch}>
                                <input
                                  type="text"
                                  placeholder="名前、メールアドレス、部署、ふりがなで検索"
                                  className="txt-search"
                                  name="searchText"
                                  value={searchText}
                                  onChange={this.handleChange}
                                />
                                <button
                                  type="button"
                                  className="search fz-16 search-button-icon-phase2"
                                  onClick={this.handleSubmitSearch}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </form>
                            </div>
                            <div className="link-tggle">
                              <a
                                href="#/"
                                className="form-link"
                                onClick={this.onClickShowFilterBox}
                              >
                                条件で絞り込み
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                />
                              </a>
                            </div>
                            <Link
                              to={`/admin/user-registration/${this.state.companyUUID}`}
                              className="bnt-submit-link"
                            >
                              ユーザーを個別登録
                            </Link>
                            <Link
                              to={`/admin/csv-registration/${this.state.companyUUID}`}
                              className="bnt-submit-link bnt-submit-link1"
                            >
                              ユーザーを一括登録・更新
                            </Link>
                            <a
                              href="#/"
                              className="bnt-submit-link bnt-submit-link2"
                              onClick={this.onClickDownloadCSV}
                            >
                              CSVダウンロード
                            </a>

                          </div>
                        </div>
                        {isShowFilterBox === true ? (
                          <div className="form-table-filter-box clearfix">
                            <form className="form-inline form-inline-filter-box">
                              <div className="form-group form-group-filter-box">
                                <label
                                  htmlFor="exampleSelect1"
                                  className="form-label-filter-box"
                                >
                                  ステータス
                                </label>

                                <select
                                  className="form-control"
                                  name="statusFilter"
                                  onChange={this.handleChangeFilterBox}
                                >
                                  <option value="">
                                    All
                                  </option>
                                  <option value={1}>有効</option>
                                  <option value={0}>無効</option>
                                </select>
                              </div>
                              <div className="form-group form-group-filter-box">
                                <label
                                  htmlFor="exampleSelect1"
                                  className="form-label-filter-box"
                                >
                                  インベントリー
                                </label>
                                <select
                                  className="form-control"
                                  name="assessmentFilter"
                                  onChange={this.handleChangeFilterBox}
                                >
                                  <option value="">
                                    All
                                  </option>
                                  <option value={0}>未回答</option>
                                  <option value={1}>回答済</option>
                                </select>
                              </div>
                              <div className="form-group form-group-filter-box">
                                <label
                                  htmlFor="exampleSelect1"
                                  className="form-label-filter-box"
                                >
                                  ワークショップコード
                                </label>
                                <select
                                  className="form-control"
                                  name="workshopFilter"
                                  onChange={this.handleChangeFilterBox}
                                >
                                  <option value="">
                                    All
                                  </option>
                                  <option value={1}>
                                    未登録
                                  </option>
                                  {this.state.listWorkshop.map((workshop) => (
                                    <option
                                      key={workshop.id}
                                      id={workshop.id}
                                      value={workshop.code}
                                    >
                                      {workshop.code}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                            <div className="count-user-filter-container">
                              <p className="text-count-user-filter-container">
                                対象ユーザー：{this.state.totalUsers}人
                                <span style={{marginLeft:"25px", paddingLeft:"20px"}} className="container-checkbox">
                                  <input type="checkbox" checked={true}/>
                                  <span style={{top:"30%"}} className="checkmark"/>
                                  選択ユーザー：{this.state.numberUserCheckedAtPage}人
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="table-wrap-content phase2-form-input-auto-height">
                          <div className="box-theadtable">
                            <table id={`${admin.data.is_supper_admin===true ? "table-userlist-admin" :
                              "table-userlist"}`} className="table table-striped table-sm js-table">
                              <thead>
                                <tr>
                                  <th className="th-sm hiden-sort th-sm-check">
                                    <label className="container-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={isCheckedAll}
                                        onChange={this.onCheckAllTable}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </th>

                                  {this.state.listColumnTableUsers.map(
                                    (column) => (
                                      <th
                                        key={column.id}
                                        className={`th-sm ${
                                          column.sortBy === 1
                                          ? "active-th-dow"
                                          : ""
                                          } ${
                                            column.sortBy === 2
                                            ? "active-th-up"
                                            : ""
                                          }
                                          ${
                                            (admin.data.is_supper_admin === false&&column.name==='備考') ?
                                            "remarks":""
                                          }
                                        `}
                                        id={column.id}
                                        onClick={column.request ? this.onClickSortActive : ""}
                                      >
                                        {column.name}
                                        {admin.data.is_supper_admin === true&&column.name==='備考' ?
                                          (<p style={{display:"inline",marginLeft:"15px"}}>※</p>):("")}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div
                            className="box-tbodytable"
                            onScroll={this.onScrollTable}
                          >
                            <table id={`${admin.data.is_supper_admin===true ? "table-userlist-admin" :
                              "table-userlist"}`} className="table table-striped table-sm">
                              <tbody>
                                {this.state.listUsersAtPage.map((user) => (
                                  <tr
                                    key={user.id}
                                    className={`${
                                      this.isDefaultChecked(user) === true
                                      ? "checked-list-active"
                                      : ""
                                      }`}
                                  >
                                    <td className="th-sm-check">
                                      <label className="container-checkbox">
                                        <input
                                          name={`checkbox${user.id}`}
                                          type="checkbox"
                                          checked={this.isDefaultChecked(user)}
                                          onChange={() =>
                                            this.onClickAddUserChecked(user)
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </td>
                                    <td>
                                      {user.is_active === true ? (
                                        <div className="text-action-on">
                                          有効
                                        </div>
                                      ) : (
                                        <div className="text-action-off">
                                          停止
                                        </div>
                                      )}
                                    </td>
                                    <td>{user.employee_id}</td>
                                    <td>
                                      {user.is_admin === true ? (
                                        <span className="green-box-text">管理者</span>
                                      ) : user.is_superuser === true ? (<span className="box-text">管理者</span>):(
                                        <span></span>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/admin/${this.state.companyUUID}/user-registration/${user.uuid}`}
                                      >
                                        <span className="text-line color-689B88 cursor-pointer">
                                          {user.last_name} {user.first_name}
                                        </span>
                                      </Link>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>
                                      <span
                                        className="text-rectang color-white"
                                        style={{
                                          backgroundColor: this.getColorLoadWorkshopTable(
                                            user.code_workshop
                                          ),
                                        }}
                                      >
                                        {user.code_workshop}
                                      </span>
                                      <span className="text-date">
                                        {user.date_workshop}
                                      </span>
                                    </td>
                                    <td>
                                      <AssessmentLink user={user} />
                                    </td>
                                    <td>
                                      {user.send_mail_magazine === true ? (
                                        <div className="text-action-on">ON</div>
                                      ) : (
                                        <div className="text-action-off">
                                          OFF
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <span className="txt-position">
                                        {user.mail_magazine_progress}/27 ({Math.round(user.mail_magazine_progress * 100 / 27)}%)
                                      </span>
                                    </td>
                                    <td>
                                      {user.last_login ? user.last_login : ""}
                                    </td>
                                    <td>
                                      {user.access_period}
                                    </td>
                                    <td>
                                      {user.welcome_mail_send_time ? user.welcome_mail_send_time : ""}
                                    </td>
                                    <td>
                                      {user.registration_mail_send_time ? user.registration_mail_send_time.map((registration_mail_date) =>
                                        <span>{registration_mail_date}<br/></span>):""}
                                    </td>
                                    <td>
                                      {user.assessment_mail_send_time ? user.assessment_mail_send_time.map((assessment_mail_date) =>
                                        <span>{assessment_mail_date}<br/></span>): ""}
                                    </td>
                                    <td>
                                      <span className="txt-position">
                                        {user.position_department
                                          ? user.position_department
                                          : ""}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="txt-position">
                                        {user.department}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="txt-position">
                                        {user.last_furigana ? user.last_furigana : ""}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="txt-position">
                                        {user.first_furigana ? user.first_furigana : ""}
                                      </span>
                                    </td>
                                    {admin.data.is_supper_admin === true ? (
                                      <td>
                                        <p className="txt-remarks">
                                          {user.remarks
                                          }
                                        </p>
                                      </td>) : (
                                      ""
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="shadow-table js-shadow-table"></div>
                        </div>
                        {this.state.listUsersAtPage.length === 0 ? (
                          <p className="text-not-data-info-phase2">
                            参加者情報がありません
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pagination-comtainer-custom">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalUsers}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <FooterAdmin></FooterAdmin>
        { this.state.checkedStatusButton > 0 ? (
          <div className="footer-btn footer-btn-fix-phase2">
            <div className="text-center">
              {this.state.checkedStatusButton === 1 & this.state.checkedLoginStatus === 1 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-send-mail margin-lr-15"
                  onClick={this.openModal}
                >
                  登録メールを再送
                </button>
              ) : (
                ""
              )}

              {this.state.checkedStatusButton === 1 & this.state.checkedInventory === 1 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-send-mail margin-lr-15"
                  onClick={this.openAssessmentEmailModal}
                >
                  回答リマインドを送信
                </button>
              ) : (
                ""
              )}

              {this.state.checkedStatusButton === 2 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-send-mail margin-lr-15"
                  onClick={this.onClickActiveUser}
                >
                  ステータスを有効にする
                </button>
              ) : (
                ""
              )}
              {this.state.checkedStatusButton === 1 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-status margin-lr-15"
                  onClick={this.onClickDeactiveUser}
                >
                  ステータスを無効にする
                </button>
              ) : (
                ""
              )}
              <button
                className="bnt-submit-link-footer bnt-submit-link-delete margin-lr-15"
                onClick={this.onClickDeleteUser}
              >
                ユーザーを削除
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal
          visible={this.state.visibleModal}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          width="500"
          height="250"
        >
          <div className="modal-body modal-body-phase2">
            <p className="text-content modal-mes-phase2-fix">
              チェックしたユーザー  （{this.state.numberUserCheckedAtPage}人） に
              <br />
              登録確認メールを再送しますか？
            </p>
            <div className="gr-btn-modal modal-mes-phase2-fix">
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mt-10 mr-20"
                onClick={this.onClickSendMailInviteUser}
              >
                送信する
              </button>
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status mt-10"
                onClick={() => this.closeModal()}
              >
                キャンセル
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.visibleModalDeleteCompany}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          width="500"
          height="250"
        >
          <div className="modal-body modal-body-phase2">
            <p className="text-content modal-mes-phase2-fix">
              企業を削除するには、企業内の参加者データを
              <br />
              すべて削除する必要があります。
            </p>
            <div className="gr-btn-modal modal-mes-phase2-fix">
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mt-40 mr-20"
                onClick={() => this.closeModal()}
              >
                閉じる
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.isDeleteCompany}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          width="600px"
          height="275px"
        >
          <div className="modal-body modal-body-phase2">
            <p className="text-content modal-mes-phase2-fix">
              {company.name}
              <br />
              削除しますか？
            </p>
            <div className="gr-btn-modal modal-mes-phase2-fix">
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status mt-40 bnt-submit-fix-bd"
                onClick={() => this.closeModal()}
              >
                キャンセル
              </button>
              <button
                type="button"
                style={{backgroundColor:"#b25c60",border:"1px solid #b25c60",color:"#fff"}}
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status mt-40 ml-20"
                onClick={this.deleteCompanySuccess}
              >
                削除する
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.visibleAssessmentModal}
          effect="fadeInUp"
          onClickAway={() => this.closeAssessmentEmailModal()}
          width="500"
          height="250"
        >
          <div className="modal-body modal-body-phase2">
            <p className="text-content modal-mes-phase2-fix">
              チェックしたユーザー  （{this.state.numberUserCheckedAtPage}人） に
              <br/>
              回答リマインドメールを送信しますか？
            </p>
            <div className="gr-btn-modal modal-mes-phase2-fix">
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mt-10 mr-20"
                onClick={this.onClickSendAssessmentMail}
              >
                送信する
              </button>
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status mt-10"
                onClick={() => this.closeAssessmentEmailModal()}
              >
                キャンセル
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


function AssessmentLink (props) {
  const user = props.user;
  if (!user.assessment) {
    return (
      <span className="font-weight-bold color-B25C60">
        未回答
      </span>
    );
  }
  return (
    <a
      className="font-weight-bold color-4d4150"
      href="#/"
      onClick={(event) => {
        event.preventDefault();
        window.open(`/admin/consequence/${user.uuid}`, '_blank');
      }}
    >
      回答済
    </a>
  );
}


function mapStateToProps(state) {
  const {
    userAdminReducer,
    workshopAdminReducer,
    authAdminReducer,
    companyReducer,
  } = state;
  const { admin } = authAdminReducer;
  const { listUsers, activePercent, company } = userAdminReducer;
  const { workshopsAdmin } = workshopAdminReducer;
  const { isDeleteCompanySuccess } = companyReducer;
  return {
    listUsers,
    workshopsAdmin,
    activePercent,
    admin,
    company,
    isDeleteCompanySuccess,
  };
}

const connectedDetailCompanyPage = connect(mapStateToProps)(DetailCompanyPage);
export { connectedDetailCompanyPage as DetailCompanyPage };
