import { workshopAdminConstants } from "../_constants";
import { workshopAdminServices } from "../_services";
import { alertActions } from "./";
import { history } from "../../_helpers";

export const workshopAdminActions = {
  getListWorkshopsAction,
  createWorkshopAction,
  getWorkshopDetailAction,
  editWorkshopDetailAction,
  deleteWorkshopDetailAction
};
function getListWorkshopsAction(companyUUID) {
  return dispatch => {
    workshopAdminServices
      .getListWorkshopsService(companyUUID)
      .then(list => {
        if (list.status === 403) {
          history.push("/admin/login/");
        }
        else if (list.code !== 200) {
          dispatch(alertActions.error(list.data.message));
        } else {
          dispatch(success(list.data));
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(data) {
    return {
      type: workshopAdminConstants.GET_LIST_WORKSHOP_ADMIN_REQUEST,
      workshopsAdmin: data
    };
  }
}
function createWorkshopAction(workshop, company_uuid) {
  return dispatch => {
    workshopAdminServices
      .createWorkshopService(workshop)
      .then(data => {
        if (data.data.code !== 200) {
          dispatch(alertActions.error(data.data.date_workshop));
        } else {
          history.push("/admin/workshop/" + company_uuid);
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Get workshop detail info action
function getWorkshopDetailAction(workshop_uuid, company_uuid) {
  return dispatch => {
    workshopAdminServices
      .getWorkshopDetailService(workshop_uuid, company_uuid)
      .then(workshopInfo => {
      if (workshopInfo.status === 403) {
          history.push("/admin/login/");
      }
        dispatch(success(workshopInfo));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(workshopInfo) {
    return {
      type: workshopAdminConstants.GET_WORKSHOP_ADMIN_REQUEST,
      workshopInfo: workshopInfo
    };
  }
}
function editWorkshopDetailAction(workshop_uuid, company_uuid, workshop) {
  return dispatch => {
    workshopAdminServices
      .editWorkshopDetailService(workshop_uuid, workshop)
      .then(res => {
          if (res.status === 403) {
              history.push("/admin/login/");
          }
        if (res.code !== 200) {
          dispatch(alertActions.error(res.data.detail));
        } else {
          history.push("/admin/workshop/" + company_uuid);
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function deleteWorkshopDetailAction(workshop_uuid, company_uuid) {
  return dispatch => {
    workshopAdminServices
      .deleteWorkshopDetailService(workshop_uuid, company_uuid)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error("Error"));
        } else {
          if (res.data.is_delete === true) {
            history.push("/admin/workshop/" + company_uuid);
          }
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
