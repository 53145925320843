import React from "react";
import HighchartsReact from "highcharts-react-official";
import more from "highcharts/highcharts-more";
import * as Highcharts from "highcharts";

import IconQue from "../../../../assets/styles/img/ Question.png";
import Exclamation from "../../../../assets/styles/img/ Checked.png";
import Checked from "../../../../assets/styles/img/ Exclamation.png";


more(Highcharts);


export class AssessmentConsequence extends React.Component {

  render() {
    const { spiderChart, averageCompareUp, averageCompareDown } = this.props;

    const chartOptionsSpider = {
      chart: {
        backgroundColor: 'transparent',
        polar: true,
        type: "area",
        height: 300
      },
      plotOptions: {
        column: {
          colorByPoint: false
        }
      },
      colors: ["#689b88"],

      title: "",
      pane: {
        size: "200%",
        startAngle: -30
      },

      xAxis: {
        categories: [
          `継続<br/><span style="color:cadetblue">${spiderChart[0]}</span>`,
          `傾聴<br/><span style="color:cadetblue">${spiderChart[1]}</span>`,
          `質問<br/><span style="color:cadetblue">${spiderChart[2]}</span>`,
          `目的\n志向<br/><span style="color:cadetblue">${spiderChart[3]}</span>`,
          `個別\n対応<br/><span style="color:cadetblue">${spiderChart[4]}</span>`,
          `方向性\n共有<br/><span style="color:cadetblue">${spiderChart[5]}</span>`,
        ],
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: 'bold'
          },
          useHTML: true,
          formatter: function () {
            return this.value.toString().replace("\n", "<br/>");
          }
        }
      },

      yAxis: {
        max: 100,
        min: 0,
        labels: { enabled: true },
        gridLineInterpolation: "polygon",
        lineWidth: 0
      },

      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}<br/>'
      },

      legend: {
        enabled: false,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical"
      },

      series: [
        {
          name: "",
          data: spiderChart,
          pointPlacement: "on",
          // fillColor: "#a4c3b8",
          fillOpacity: 0.5
        }
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal"
              },
              pane: {
                size: "90%"
              }
            }
          }
        ]
      }
    };

    return (
      <div className="login-box">
        <div className="assessment-consequence-date-print-communication-trends">
          <p className="assessment-consequence-date-print-communication-heading font-weight-bold fz-18">コミュニケーションスタイル</p>
          <p className="assessment-consequence-date-print-communication-sub1">6つのカテゴリ別にみたあなたのコミュニケーションの傾向です。</p>
          <p className="font-weight-bold" style={{marginTop:"0.5rem"}}>【スコアの意味】</p>
          <p>スコアは、世の中のリーダーの自己認識と比較して、あなたが自身のコミュニケーションに関してどのくらい得意(もしくは苦手)だと思って<br/>
             いるかを表したものです。スコアの数値は、世の中のリーダーを100人と仮定した時に、あなたの自己認識が何人のリーダーより上回っているか<br/>
             (得意だと思っているか)を推定した値です。世の中のリーダーの平均を50として、最大値は100、最小値は0です。例えば、スコアが80の場<br/>
             合、あなたは他の80人のリーダーより得意だと思っている、あなたよりも得意だと思っているリーダーは20人いることを意味します。</p>
        <p className="font-weight-bold" style={{marginTop:"1rem"}}>【スコアの算出方法】</p>
        <p>コーチ‧エィが過去に調査した管理職‧リーダー1,258名のデータより、世の中のリーダーの平均値と標準偏差をもとに、あなたのスコアを算出<br/>
           しています。</p>
        </div>
        <div className="chart-spider-custom-wrapper">
          <div className="chart-spider-custom-header">
            <p className="mt-25 fz-18">コミュニケーションスタイル</p>

            <div className="chart-spider-custom-pills">
              <span className="pill-left">日常の関わり</span>
              <span className="pill-right">対話力</span>
            </div>

          </div>

          <div className="chart-spider-custom">
            <HighchartsReact options={chartOptionsSpider} />
          </div>

          <p className="chart-spider-custom-link">
            <a
              href="#myModal-common"
              className="link-submit fz-16"
              data-toggle="modal"
              data-target="#myModal-common"
            >
              <img src={IconQue} alt="" className="mr-2" />項目の意味
            </a>
          </p>
        </div>
        <div className="assessment-consequence-communication-details-wrapper">
          <div className="assessment-consequence-communication-details-section-one">
            <span className="assessment-consequence-communication-details-header-green">日常の関わり</span> <span className="ml-5">相手と信頼関係を築いたり組織参画を促す日常の関わり</span>
            <p className="mt-10 font-weight-bold">継続</p>
            <p className="mt-2">定期的に相手の目標の進捗について話をすること</p>
            <p className="mt-20 font-weight-bold">方向性共有</p>
            <p className="mt-2">組織の方向性と相手の目指していることを互いに共有すること</p>
            <p className="mt-20 font-weight-bold">個別対応</p>
            <p className="mt-2">相手の性格や状況に合った関わりをすること</p>
          </div >
          <div className="assessment-consequence-communication-details-section-two">
            <span className="assessment-consequence-communication-details-header-red">対話力</span> <span className="ml-5">相手の自発的な行動を促す対話のスキル</span>  
            <p className="mt-20 font-weight-bold">傾聴</p>
            <p className="mt-2">相手に関心を示し、最後まで話を聞くこと</p>
            <p className="mt-20 font-weight-bold">質問</p>
            <p className="mt-2">相手の気付き、探求、自発性を促すような質問をすること</p>
            <p className="mt-10 font-weight-bold">目的志向</p>
            <p className="mt-2">目的を持って相手と話をし、明確な結論を互いに共有すること</p>
            
          </div>
        </div>
        <div className="assessment-consequence-strength-weakness-header">
          <p className="assessment-consequence-strength-weakness-main font-weight-bold fz-18">コミュニケーションの強みと課題</p>
          <p >コミュニケーションスキル24項目の中から、世の中のリーダーと比較して相対的に点数の高い3項目と低い3項目を、あなたの自己認識における<br/>
          強み‧課題として抽出しています。</p>
        </div>

        <div className="assessment-consequence-average-details-wrapper">
          <div className="assessment-consequence-average-details-wrapper-sub">
            <div className="assessment-consequence-average-details-section-one">

              <div className="assessment-consequence-average-details-header header-green">
                <p className="fz-18 font-weight-bold ml-5">あなたの強み</p>
                <font className="fz-13 ml-5">
                平均との比較で <font style={{ color: "#5f7b9c" }}>点数が高い</font>上位3項目
                                </font>
              </div>

              {averageCompareUp !== "" ? (
                averageCompareUp.map(itemAverageUp => (
                  <div key={itemAverageUp.id} className="assessment-consequence-average-details-sub text-333333">
                    <img src={Exclamation} className="mr-2" alt="Exclamation" />
                    <p className="fz-16 ">{itemAverageUp.name}</p>
                  </div>
                ))) : (<p className="fz-16 ">該当する項目がありませんでした。</p>)}

            </div>

            <div className="assessment-consequence-average-details-section-two">
              <div className="assessment-consequence-average-details-header header-red">
                <p className="fz-18 font-weight-bold ml-5">あなたの課題</p>
                <font className="fz-13 ml-5">
                平均との比較で <font style={{ color: "#b25c60" }}>点数が低い</font>下位3項目
                                </font>
              </div>
              {averageCompareDown !== "" ? (
                averageCompareDown.map(itemAverageDown => (
                  <div key={itemAverageDown.id} className="assessment-consequence-average-details-sub text-333333">
                    <img src={Checked} className="mr-2" alt="Checked" />
                    <p className="fz-16 ">{itemAverageDown.name}</p>
                  </div>
                ))) : (<p className="fz-16 ">該当する項目がありませんでした。</p>)}

            </div>
          </div>
        </div>
      </div>
    );
  }

}

