import { connect } from "react-redux";
import React from "react";
import { adminActions, alertActions } from "../../../_actions";
import { userMessages } from "../../../_messages";
class CreateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDepartment = this.handleChangeDepartment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      workshop_id: 0,
      department_id: 0
    };
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  handleChangeDepartment(event) {
    this.setState({
      department_id: event.target.value
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    const {
      first_name,
      last_name,
      email,
      department_id,
      workshop_id
    } = this.state;
    const user = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      username: email,
      department_id: department_id,
      workshop_id: workshop_id
    };
    const { dispatch } = this.props;

    if (
      this.isValidatorForm(
        first_name,
        last_name,
        email,
        department_id,
        workshop_id
      )
    ) {
      dispatch(adminActions.createUser(user));
    }
  }
  selectChange(e) {
    this.setState({
      workshop_id: e.target.value
    })
  }

  componentDidMount() {
    this.props.dispatch(adminActions.getWorskhops());
    this.props.dispatch(adminActions.getDepartment());
  }
  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidatorForm(first_name, last_name, email, department_id, workshop_id) {
    if (first_name === "" || first_name === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.FIRST_NAME_NOT_NULL));
      return false;
    }
    if (last_name === "" || last_name === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.LAST_NAME_NOT_NULL));
      return false;
    }
    if (email === "" || email === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.EMAIL_NOT_NULL));
      return false;
    }

    if (this.validateEmail(email) === false) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.INPUT_IS_EMAIL));
      return false;
    }
    if (department_id === 0 || department_id === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.DEPARTMENT_REQUIRE));
      return false;
    }
    if (workshop_id === 0 || workshop_id === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.WORKSHOP_REQUIRE));
      return false;
    }
    return true;
  }

  render() {
    const { first_name, last_name, email } = this.state;
    const { alert, listDepartment, workshops } = this.props;
    return (
      <div id="tab-2" className="tab-content active">
        <div className="participant-addition">
          <div className="form-box mb-3">
            <form className="row">
              <div className="col-2">
                <div className="form-group d-block">
                  <label>
                    <span className="fz-20 mr-1 text-white">※</span>
                    <span className="text-white">名</span>
                  </label>
                  <div className="form-input clearfix">
                    <input
                      onChange={this.handleChange}
                      type="text"
                      name="first_name"
                      value={first_name}
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="form-group d-block">
                  <label>
                    <span className="fz-20 mr-1 text-white">※</span>
                    <span className="text-white">メールアドレス</span>
                  </label>
                  <div className="form-input clearfix">
                    <input
                      onChange={this.handleChange}
                      name="email"
                      value={email}
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group d-block">
                  <label>
                    <span className="fz-20 mr-1 text-white">※</span>
                    <span className="text-white">苗字</span>
                  </label>
                  <div className="form-input clearfix">
                    <input
                      onChange={this.handleChange}
                      type="text"
                      name="last_name"
                      value={last_name}
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="form-group d-block">
                  <label>
                    <span className="fz-20 mr-1">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>

                    <span className="text-white">役割</span>
                  </label>

                  <div className="form-select clearfix">
                    <select
                      onChange={this.handleChangeDepartment}
                      className="form-control"
                      id="selectDepartment"
                      name="department"
                    >
                      <option value={0}>--部門--</option>
                      {listDepartment
                        ? listDepartment.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group d-block">
                <div className="gr-check gr-check-custom">
                  <label className="text-white">参加予定日</label>
                  <select
                    onChange={this.selectChange}
                    className="form-control col-9"
                    name="workshops"
                  >
                    <option value={0}>--参加予定日を選択してください--</option>
                    {workshops
                      ? workshops.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.date_workshop}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </form>
            {alert.message && (
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            )}
            <div className="submit-form-participant">
              <button
                onClick={this.handleSubmit}
                type="submit"
                className="btn-primary--bg mb-3"
              >
                追加
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { listDepartment } = state.admin;
  const { workshops } = state.workshopReducer;
  return {
    alert,
    listDepartment,
    workshops
  };
}
const connectedAdminDashboard = connect(mapStateToProps)(CreateAccount);
export { connectedAdminDashboard as CreateAccount };
