// import config from "config";
import axios from "axios";
import { pad } from "highcharts";
import { authHeaderAdmin, forceReloadWindow, logout } from "../_helpers";


const API_GET_USER = process.env.URL_API + "/v1/users/?limit=20";
const API_GET_ACTIVE_ACCOUNT_DETAIL_COMPANY =
  process.env.URL_API + "/v1/users/count-user/?company_uuid=";
const API_ACTIVE_USER = process.env.URL_API + "/v1/users/active-user/";
const API_DEACTIVE_USER = process.env.URL_API + "/v1/users/deactive-user/";
const API_DELETE_USER = process.env.URL_API + "/v1/users/delete-list/";
const API_SEND_MAIL_INVITE_USER =
  process.env.URL_API + "/v1/users/send-email-request-user/";
const API_SEND_ASSESSMENT_MAIL = process.env.URL_API + "/v1/users/send-assessment-email/"
const API_GET_COMPANY_DETAIL_INFO = process.env.URL_API + "/v1/company/";
const API_CREATE_USER_INFO = process.env.URL_API + "/v1/users/create";
const API_IMPORT_CSV_USER = process.env.URL_API + "/v1/users/import-csv";
const API_GET_CSV_ERRORS = process.env.URL_API + "/v1/users/import-csv/";
const API_EXPORT_CSV_USER = process.env.URL_API + "/v1/users/export-csv/?company_uuid=";
const API_GET_USER_DETAIL_INFO = process.env.URL_API + "/v1/users/";
const API_UPDATE_USER_DETAIL_INFO = process.env.URL_API + "/v1/users/";
const API_GET_SUPER_USER_LIST = process.env.URL_API + "/v1/users/list_super_admin/";
const API_CREATE_SUPER_ADMIN_INFO = process.env.URL_API + "/v1/users/super_admin_create";
const API_UPDATE_SUPER_ADMIN_DETAIL_INFO = process.env.URL_API + "/v1/users/";
const API_GET_SUPER_USER = process.env.URL_API + "/v1/users/list_super_admin/?offset=";
const API_DELETE_SUPER_USER = process.env.URL_API + "/v1/users/delete-super-admin-list/";

export const userAdminServices = {
  getListUserService,
  getActiveAccountCompanyService,
  activeUserService,
  deactiveUserService,
  deleteUserService,
  sendMailInviteUserAccountService,
  sendAssessmentMailService,
  getCompanyDetailInfoService,
  createUserService,
  importCSVUserService,
  getUserInfoService,
  updateUserService,
  downloadCSVService,
  downloadInvalidDataCSV,
  getListSuperUserService,
  createSuperAdminService,
  updateSuperAdminService,
  getSuperUserSearchService,
  deleteSuperAdminService
};
// const headers = {
//   "Content-Type": "application/json",
//   Authorization: authHeaderAdmin()
// };
function getListUserService(
  companyUUID,
  offset,
  search,
  is_active,
  assessment,
  workshop,
  sort_column
) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  let API_GET_FILTER_SORT =
    API_GET_USER + "&offset=" + offset * 20 + "&company_uuid=" + companyUUID;
  if (search) {
    API_GET_FILTER_SORT += "&search=" + search;
  }
  if (is_active) {
    API_GET_FILTER_SORT += "&is_active=" + is_active;
  }
  if (assessment) {
    API_GET_FILTER_SORT += "&assessment=" + assessment;
  }
  if (workshop) {
    API_GET_FILTER_SORT += "&workshop=" + workshop;
  }
  if (sort_column) {
    API_GET_FILTER_SORT += "&" + sort_column;
  }
  return axios
    .get(API_GET_FILTER_SORT, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401 || error.response.status === 403) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

///// Get Super User List
function getListSuperUserService()
{


  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_SUPER_USER_LIST, { headers })
    .then(res => {
      return res.data
      })
   .catch (error => {
    if (error.response.status === 401) {
      logout();
      forceReloadWindow();
    }
  });
}

function getSuperUserSearchService(offset,sort_column, search_text)
{
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  let API_GET_SORT = API_GET_SUPER_USER + offset * 20
  if (sort_column) {
    API_GET_SORT += "&" + sort_column;
  }
  if (search_text){
    API_GET_SORT += "&search=" + search_text;
  }
  return axios
  .get(API_GET_SORT , {
    headers
  })
  .then(res => {
    return res.data;
  })
  .catch(error => {
    if (error.response.status === 401) {
      logout();
      forceReloadWindow();
    }
    return error.response;
  });
}


/////// Count account active
function getActiveAccountCompanyService(companyUUID) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_ACTIVE_ACCOUNT_DETAIL_COMPANY + companyUUID, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}
///// sendMailInviteUserAccount
function sendMailInviteUserAccountService(list_users) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  const body = JSON.stringify({
    list_users: list_users
  });
  return axios
    .post(API_SEND_MAIL_INVITE_USER, body, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      else if(error.response.status === 500){
        return error.response
      }
    });
}
///// Send assessment mail service
function sendAssessmentMailService(list_users) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  const body = JSON.stringify({
    list_users: list_users
  });
  return axios
    .post(API_SEND_ASSESSMENT_MAIL, body, { headers })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      } else {
        return error.response;
      }
    });
}
/////// Get company detail info active
function getCompanyDetailInfoService(companyUUID) {
  let API_GET_COMPANY_DETAIL =
    API_GET_COMPANY_DETAIL_INFO + companyUUID + "/detail/";

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_COMPANY_DETAIL, { headers })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401 || error.response.status === 403) {
        logout();
        forceReloadWindow();
      }
    });
}
///// Create user service
function createUserService(user) {
  const body = JSON.stringify(user);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_CREATE_USER_INFO, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}
///// import user csv service
// function importCSVtUserService(file, company_id) {
//   const data = new FormData();
//   data.append("user_csv", file);
//   data.append("company_id", company_id);

//   const headers = {
//     Authorization: authHeaderAdmin()
//   };

//   return axios
//     .post(API_IMPORT_CSV_USER, data, {
//       headers
//     })
//     .then(res => {
//       return res.data;
//     })
//     .catch(error => {
//       if (error.response.status === 401) {
//         logout();
//         forceReloadWindow();
//       }
//       return error.response;
//     });
// }
///// Active account service
function activeUserService(list_users) {
  const body = JSON.stringify({
    list_users: list_users
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_ACTIVE_USER, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response.data.data.message;
    });
}
///// DeActive account service
function deactiveUserService(list_users) {
  const body = JSON.stringify({
    list_users: list_users
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_DEACTIVE_USER, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}
///// Delete account service
function deleteUserService(list_users, company_uuid) {
  const body = JSON.stringify({
    list_users: list_users,
    company_uuid: company_uuid
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .post(API_DELETE_USER, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}
///// import user csv service
function importCSVUserService(file, company_id) {
  const data = new FormData();
  data.append("user_csv", file);
  data.append("company_id", company_id);

  const headers = {
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_IMPORT_CSV_USER, data, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

/////// Get user detail info active
function getUserInfoService(user_uuid) {
  let API_GET_USER_DETAIL = API_GET_USER_DETAIL_INFO + user_uuid + "/detail/";

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_USER_DETAIL, { headers })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}
///// update user service
function updateUserService(user, user_uuid) {
  let API_UPDATE_USER_DETAIL = API_UPDATE_USER_DETAIL_INFO + user_uuid + "/update/";
  const body = JSON.stringify(user);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .put(API_UPDATE_USER_DETAIL, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}


function downloadCSVService(company_uuid, sort_column,status_filter,assessment_filter,workshop_filter) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  const FileDownload = require('js-file-download');
  let API_EXPORT_CSV_USER_SORT_FILTER = API_EXPORT_CSV_USER + company_uuid;
  if (sort_column !== "") {
    API_EXPORT_CSV_USER_SORT_FILTER = API_EXPORT_CSV_USER_SORT_FILTER +"&"+ sort_column;
  }
  if(status_filter !== ""){
    API_EXPORT_CSV_USER_SORT_FILTER = API_EXPORT_CSV_USER_SORT_FILTER + "&status_filter=" +status_filter;
  }
  if(assessment_filter !== ""){
    API_EXPORT_CSV_USER_SORT_FILTER = API_EXPORT_CSV_USER_SORT_FILTER + "&assessment_filter=" + assessment_filter;
  }
  if(workshop_filter !== ""){
    API_EXPORT_CSV_USER_SORT_FILTER = API_EXPORT_CSV_USER_SORT_FILTER + "&workshop_filter=" + workshop_filter; 
  }
  return axios.get(API_EXPORT_CSV_USER_SORT_FILTER, { headers }).then(res => {
    FileDownload(res.data, 'user_details.csv');
  }).catch(error => {
    return error.response;
  });
}

// Download the erred data in CSV import
function downloadInvalidDataCSV(count, company_uuid) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()    
  }
  const FileDownload = require('js-file-download');
  return axios.get(API_GET_CSV_ERRORS + "?company_uuid=" + company_uuid, { headers }).then(res => {
    let date = new Date();
    FileDownload(res.data,
       date.getFullYear()+'_'+pad(date.getMonth()+1)+'_'+pad(date.getDate())+'-'+count+'.csv');
  }).catch(error => {
    return error.response;
  });
}

///// Create super admin service
function createSuperAdminService(user) {
  const body = JSON.stringify(user);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_CREATE_SUPER_ADMIN_INFO, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}


///// update super admin service
function updateSuperAdminService(user, user_uuid) {
  let API_UPDATE_USER_DETAIL = API_UPDATE_SUPER_ADMIN_DETAIL_INFO + user_uuid + "/super_admin_update/";
  const body = JSON.stringify(user);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .put(API_UPDATE_USER_DETAIL, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

///// Delete account service
function deleteSuperAdminService(list_users) {
  const body = JSON.stringify({
    list_users: list_users
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .post(API_DELETE_SUPER_USER, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}