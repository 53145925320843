// import config from "config";
import axios from "axios";
import { authHeaderAdmin, forceReloadWindow } from "../_helpers";

const API_LOGIN = process.env.URL_API + "/v1/auth/login-admin";
const API_CHECK_LOGIN = process.env.URL_API + "/v1/users/check_login/";
const API_ADMIN_CHECK_LOGIN = process.env.URL_API + "/v1/users/check_admin_login/";
const API_SUPPER_ADMIN_CHECK_LOGIN = process.env.URL_API + "/v1/users/check_supper_admin_login/";
const API_FORGOT_PASSWORD_ADMIN =
  process.env.URL_API + "/v1/users/admin-forgot-password/";
const API_FORGOT_PASSWORD_CONFIRM =
  process.env.URL_API + "/v1/users/forgot-password/confirm/";
const API_CHANGE_PASSWORD = process.env.URL_API + "/v1/users/change-password";
const API_LOGOUT = process.env.URL_API + "/v1/auth/logout";

export const loginServices = {
  login,
  logout,
  checkLogin,
  checkAdminLogin,
  checkSupperAdminLogin,
  forgotPasswordAdminService,
  forgotPasswordConfirmService,
  changePasswordService
};

const headers = {
  "Content-Type": "application/json",
  Authorization: authHeaderAdmin()
};

function checkLogin(token) {
  if (token !== "") {
    const AuthStr = "Bearer ".concat(token);
    return axios
      .get(API_CHECK_LOGIN, { headers: { Authorization: AuthStr } })
      .then(response => {})
      .catch(error => {
        if (error.response.status === 401) {
          logout();
          forceReloadWindow();
        }
        return error.response;
      });
  }
}

function checkAdminLogin(token) {
  if (token !== "") {
    const AuthStr = "Bearer ".concat(token);
    return axios
        .get(API_ADMIN_CHECK_LOGIN, { headers: { Authorization: AuthStr } })
        .then(response => {})
        .catch(error => {
          if (error.response.status === 401) {
            logout();
            forceReloadWindow();
          }
          return error.response;
        });
  }
}
function checkSupperAdminLogin(token) {
  if (token !== "") {
    const AuthStr = "Bearer ".concat(token);
    return axios
        .get(API_SUPPER_ADMIN_CHECK_LOGIN, { headers: { Authorization: AuthStr } })
        .then(response => {})
        .catch(error => {
          if (error.response.status === 401) {
            logout();
            forceReloadWindow();
          }
          return error.response;
        });
  }
}

function login(username, password) {
  const body = {
    username,
    password
  };
  return axios
    .post(API_LOGIN, body)
    .then(res => {
      localStorage.setItem("admin", JSON.stringify(res.data));
      return res;
    })
    .catch(error => {
      return error.response;
    });
}

function logout() {
  // remove user from local storage to log user out
  let user = JSON.parse(localStorage.getItem("admin"));
  const data = {"token": user.data.access_token}
  localStorage.removeItem("admin");

  return axios
    .post(API_LOGOUT, data, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
       return error.response;
    });
  
}
// forgot password admin service
function forgotPasswordAdminService(email) {
  const body = {
    email
  };
  const headers = {
    "Content-Type": "application/json"
  };

  return axios
    .post(API_FORGOT_PASSWORD_ADMIN, body, {
      headers: headers
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return error.response;
    });
}
function forgotPasswordConfirmService(password, token) {
  // const headers = {
  //       "Content-Type": "application/json"
  //   };
  const body = {
    password,
    token
  };

  return axios
    .post(API_FORGOT_PASSWORD_CONFIRM, body, {
      headers: headers
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return error.response;
    });
}
function changePasswordService(current_password,password, confirmed_password) {
  const body = {
    current_password,
    password,
    confirmed_password
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .put(API_CHANGE_PASSWORD, body, {
      headers: headers
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return error.response;
    });
}
