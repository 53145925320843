import { companyConstants } from "../_constants";
import { companyServices } from "../_services";
import { alertActions } from "./";
import { history } from "../../_helpers";

export const companyActions = {
  list,
  create,
  update,
  detail,
  deleteCompanyAction,
  resetIsDeleteCompanyStatusAction,
  userList,
  companyList,
};
function list(offset,sort_column,search_text) {
  return dispatch => {
    companyServices
      .getList(offset,sort_column,search_text)
      .then(listCompany => {
        dispatch(success(listCompany));
        if (listCompany.status === 403) {
          history.push("/admin/login");
        }
      })
      .catch(error => {
        dispatch(failure(error.data));
        dispatch(alertActions.error(error.data));
      });
  };

  function success(listCompany) {
    return {
      type: companyConstants.GET_LIST_COMPANY_SUCCESS,
      listCompany: listCompany
    };
  }
  function failure(error) {
    return { type: companyConstants.GET_LIST_COMPANY_ERROR, error };
  }
  // function request() {
  //   return { type: companyConstants.GET_LIST_COMPANY_REQUEST };
  // }
}

function userList(offset, user_search_text) {
  return dispatch => {
    companyServices.getUserList(offset, user_search_text)
    .then(listUserCompany => {
      dispatch(success(listUserCompany));
      if (listUserCompany.status === 403) {
        history.push("/admin/login");
      }
    })
    .catch(error => {
      dispatch(failure(error.data));
      dispatch(alertActions.error(error.data));
    });
  };

  function success(listUserCompany) {
    return {
      type: companyConstants.GET_USER_LIST_COMPANY_SUCCESS,
      listUserCompany: listUserCompany
    };
  }
  function failure(error) {
    return { type: companyConstants.GET_USER_LIST_COMPANY_ERROR, error };
  }
}

function create(company) {
  return dispatch => {
    companyServices
      .create(company)
      .then(data => {
        if (data.status === 400) {
          dispatch(alertActions.error(data.data.email));
        } else {
          dispatch(success(data));
          history.push("/admin/company");
        }
      })
      .catch(error => {
        dispatch(failure(error.data));
        dispatch(alertActions.error(error.data));
      });
  };

  function success(data) {
    return {
      type: companyConstants.CREATE_COMPANY_SUCCESS
    };
  }
  function failure(error) {
    return { type: companyConstants.CREATE_COMPANY_ERROR, error };
  }
  // function request() {
  //   return { type: companyConstants.CREATE_COMPANY_REQUEST };
  // }
}

function update(uuid, data) {
  return dispatch => {
    companyServices.update(uuid,data).then(company => {
      if (company.status === 403) {
        history.push("/admin/login/");
      }else{
        dispatch(success(company));
      history.push("/admin/detail-company/"+uuid);
      }      
    })
    .catch(error => {
      dispatch(failure(error.data));
      dispatch(alertActions.error(error.data));
    });
  };

  function success(listCompany) {
    return {
      type: companyConstants.UPDATE_COMPANY_REQUEST,
      listCompany: listCompany
    };
  }
  function failure(error) {
    return { type: companyConstants.UPDATE_COMPANY_ERROR, error };
  }
  // function request() {
  //   return { type: companyConstants.UPDATE_COMPANY_REQUEST };
  // }
}

function detail(uuid) {
  return dispatch => {
    companyServices
      .detail(uuid)
      .then(company => {
        if (company.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success(company));
      })
      .catch(error => {
        dispatch(failure(error.data));
        dispatch(alertActions.error(error.data));
      });
  };

  function success(company) {
    return {
      type: companyConstants.DETAIL_COMPANY_SUCCESS,
      company: company
    };
  }
  function failure(error) {
    return { type: companyConstants.DETAIL_COMPANY_ERROR, error };
  }
  // function request() {
  //   return { type: companyConstants.DETAIL_COMPANY_REQUEST };
  // }
}

function deleteCompanyAction(company_uuid) {
  return dispatch => {
    companyServices
      .deleteCompanyService(company_uuid)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error("Error"));
        } else {
          if (res.data.is_delete === true) {
            dispatch(success());
            history.push("/admin/company");
          } else {
            dispatch(failure());
          }
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
  function success() {
    return {
      type: companyConstants.DELETE_COMPANY_SUCCESS
    };
  }
  function failure() {
    return { type: companyConstants.DELETE_COMPANY_FAIL };
  }
}
function resetIsDeleteCompanyStatusAction() {
  return dispatch => {
    dispatch(reset());
  };

  function reset() {
    return { type: companyConstants.RESET_STATUS_DELETE_COMPANY };
  }
}

/// Company List

function companyList(offset,sort_column,filter_id)
{
  return dispatch => {
    companyServices
      .getCompanyList(offset,sort_column,filter_id)
      .then(companyNoUser => {
        if (companyNoUser.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success(companyNoUser));
      })
      .catch(error => {
        dispatch(failure(error.data));
        dispatch(alertActions.error(error.data));
      });
};

function success(companyNoUser) {
  return {
    type: companyConstants.COMPANY_WITH_NO_USER,
    companyNoUser: companyNoUser
  };
}
function failure(error) {
  return { type: companyConstants.DETAIL_COMPANY_ERROR, error };
}   
}
