// import config from "config";
import axios from "axios";
import { authHeaderAdmin, forceReloadWindow, logout } from "../_helpers";

const API_GET_COMPANY = process.env.URL_API + "/v1/company/?offset=";
const API_CREATE_COMPANY = process.env.URL_API + "/v1/company/create/";
const API_UPDATE_COMPANY = process.env.URL_API + "/v1/company/";
const API_DETAIL_COMPANY = process.env.URL_API + "/v1/company/";
const API_DELETE_COMPANY = process.env.URL_API + "/v1/company/";
const API_GET_USER_LIST_COMPANY = process.env.URL_API + "/v1/company/user_search/?offset="
const API_COMPANY_FILTER = process.env.URL_API + "/v1/company/filter/"


export const companyServices = {
  getList,
  create,
  update,
  detail,
  deleteCompanyService,
  getUserList,
  getCompanyList,
};

function getList(offset,sort_column, search_text) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  let API_GET_SORT = API_GET_COMPANY + offset * 20
  if (sort_column) {
    API_GET_SORT += "&" + sort_column;
  }
  if (search_text){
    API_GET_SORT += "&search=" + search_text;
  }
  return axios
    .get(API_GET_SORT , {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

function getUserList(offset, user_search_text) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  if(user_search_text){
    user_search_text = user_search_text.replace("%", "%25");
    user_search_text = user_search_text.replace("&","%26");
    user_search_text = user_search_text.replace("+","%2B");
    user_search_text = user_search_text.replace("#","%23");
    user_search_text = user_search_text.replace(";","%3B");
  }

  let API_GET_USER_LIST = API_GET_USER_LIST_COMPANY + offset * 20;
  if (user_search_text) {
    API_GET_USER_LIST += "&search=" + user_search_text;
  }
  return axios.get(API_GET_USER_LIST, {headers})
  .then(res => {
    return res.data;
  })
  .catch(error => {
    if (error.response.status === 401) {
      logout();
      forceReloadWindow();
    }
    return error.response;
  });
}

function create(company) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .post(API_CREATE_COMPANY, JSON.stringify(company), {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

function update(uuid, company) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_UPDATE_COMPANY + uuid + "/edit/", JSON.stringify(company), {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

function detail(uuid) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_DETAIL_COMPANY + uuid + "/detail/", {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

/////// Delete company
function deleteCompanyService(company_uuid) {
  let API_DELETE_COMPANY_SERVICE = API_DELETE_COMPANY + company_uuid + "/delete/";

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .delete(API_DELETE_COMPANY_SERVICE, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}

///// Get Company List 

function getCompanyList(offset,sort_column,filter_id)
{
  let API_COMPANY_LIST = API_COMPANY_FILTER + filter_id + "/?offset=" + offset * 20
  if (sort_column) {
    API_COMPANY_LIST += "&" + sort_column;
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_COMPANY_LIST,{
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
    
}