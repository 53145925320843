import { connect } from "react-redux"
import React from "react"
import HeaderAdmin from "../../../_componentsAdmin/HeaderAdmin";

import MenuAdmin from "../../../_componentsAdmin/MenuAdmin";
import { CreateAccount } from "../CreateAccount/CreateAccount";
import { adminActions } from "../../../_actions";
import {Link} from "react-router-dom";

class AdminCreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.hendleSubmit = this.hendleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);



        this.state = {
            listUser: this.props.listUser,
            datePicker: 'dd/mm/yyyy'
        }
        const admin = JSON.parse(localStorage.getItem("admin"));
        this.state = {admin}

    }
    componentDidMount() {
        this.props.dispatch(adminActions.getDepartment());
    }

    handleChange(e) {
        this.setState({ datePicker: e.target.value });
    }
    hendleSubmit(e) {

    }
    render() {
        return (
            <div className="page-pc page-pc-height">
                <div className="wrapper home-page-pc landing-page wrapper-fix-pc-color-1a2433">
                    <HeaderAdmin name={this.state.admin.data.user_first_name+ this.state.admin.data.user_last_name}> </HeaderAdmin>
                    <section className="content">
                        <div className="menu-wrap">
                        </div>
                        <MenuAdmin></MenuAdmin>
                        <div className="right-content">
                            {/* <!-- S: Tab --> */}
                            <div className="tab-data clearfix">
                                <div className="nav-tab clearfix">
                                    <ul className="clearfix list-tab">
                                        <li className="fix-padding">
                                            <Link to="/admin/account" className="text-detail text-white fz-24 font-weight-bold">参加者管理</Link>
                                        </li>
                                        <li className="active fix-padding" data-tab="tab-2">
                                            <Link to="/admin/account-create" className="text-detail text-white fz-24 font-weight-bold">参加者個別追加</Link>
                                        </li>
                                        <li className="fix-padding" data-tab="tab-3">
                                            <Link to="/admin/account-import" className="text-detail text-white fz-24 font-weight-bold">参加者一括追加</Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="group-tab-content clearfix">
                                    <CreateAccount />
                                </div>
                            </div>
                            {/* <!-- E: Tab --> */}
                        </div>
                    </section>
                    <a href="#/" id="return-to-top" onClick={event => event.preventDefault()}>
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    </a>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { alert } = state
    return {
        alert
    };
}
const connectedAdminCreateAccount = connect(mapStateToProps)(AdminCreateAccount)
export { connectedAdminCreateAccount as AdminCreateAccount }
