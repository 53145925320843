export const addAnswerToArray = (answers, answerToAdd) => {
  const existingAnswer = answers.find(
    answer => answer.question_id === answerToAdd.question_id
  );
  if (existingAnswer) {
    return answers.map(answer =>
      answer.question_id === answerToAdd.question_id
        ? { ...answer, point: answerToAdd.point }
        : answer
    );
  }
  return [...answers, { ...answerToAdd }];
};

export const addAnswerToQuestionReducer = (answers, questionsAtPage) => {
  return [...answers, ...questionsAtPage];
};

export const addAnswerToQuestions = (questionsAtPage, answer) => {
  const existingAnswer = questionsAtPage.find(
    question => question.id === answer.question_id
  );
  if (existingAnswer) {
    return questionsAtPage.map(question =>
      question.id === answer.question_id
        ? { ...question, point: answer.point}
        : question
    );
  }
  return [...questionsAtPage, { ...answer}];
};
