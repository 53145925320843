import React from "react";
import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { isStagingServer } from "../../_helpers";


class SuperAdminResendRegistrationMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStaging:false
    }  
  }
  componentDidMount() { 
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }
  }

  render() {
    return (
      <div className="bg-color-F4F5F4">
      <div className="wrapper list-companies confirmation-of-user-registration completion-user-deletion">
        <HeaderAdmin></HeaderAdmin>
        <div className="content content-fix-phase2 phase2-content-min-height">
          <div className="top-content">
            <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
              <li className="item-breadcrumb">
                <span className="content-item color-4d4150-header-title">
                登録確認メール再送完了
                </span>
              </li>
            </ul>
          </div>
          <div className="content-930 mt-40">
            <div className="form-box form-box-place mb-3">
              <p className="detail">
              対象ユーザの登録確認メールの再送が完了しました。（計{this.props.location.state.count}件）
              </p>
              {this.props.match.params.idResendMail === '0' ? (
                <div>
                <p className="detail">下記のユーザーのみメール再送に失敗しました（計{this.props.location.state.failed_count}件）<br/>
                該当ユーザのみを選択し直し「登録メールを再送」を再実行ください</p>
               <table className="table table-striped table-sm admin-confirm-success-table-body">
               <tbody>
                 {this.props.location.state.users.map((user) => (
                   <tr>
                     <td>{user.last_name + " " + user.first_name}</td>
                     <td>{user.email}</td>
                   </tr>
                 ))}
               </tbody>
             </table>
             </div>
              ):("")}
              <div className="btn-login text-left mt-20">
                <a
                  href={`/admin/super-admins`}
                  className="bnt-submit-link-footer bnt-submit-link-status"
                >
                  ユーザーー覧へ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin></FooterAdmin>
      </div>
    </div>
    );
  }
}

export {  SuperAdminResendRegistrationMail };