import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { isStagingServer } from "../../_helpers";

import Loginpc from "../../../assets/styles/img/logologin.png";


class AdminForgotChangePasswordSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStaging:false
    }  
  }
  componentDidMount() {
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }   
  }

  render() {
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper login password-change-completed">
        {(this.state.isStaging===true) && (
           <div
             style={{textAlign:"center",fontWeight:"bold",fontSize:"18px",backgroundColor:"rgb(178, 92, 96)",color:"#fff"}}
           >
	             <font>Staging</font>
           </div>
         )}
          <div className="login-content">
            <div className="logo-login">
              <a href="#/" className="link-logo-login" onClick={event => event.preventDefault()}>
                <img src={Loginpc} alt="" className="icon-logo-login" />
              </a>
            </div>
            <div className="form-login">
              <div className="title-login">パスワード変更完了</div>
              <div className="title-detail-login">
                パスワードの変更が完了しました。新しいパスワードでログインしてください。
              </div>
              <Link to="/admin/login" className="link-forgot">
                ログイン画面へ
              </Link>
            </div>
            <div className="footer-btn fix-position-footer-btn">
              <div className="wrap-button text-center">
                <p className="design-by text-center">
                  © COACH A Co., Ltd. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedAdminForgotChangePasswordSuccess = connect(mapStateToProps)(
  AdminForgotChangePasswordSuccess
);
export { connectedAdminForgotChangePasswordSuccess as AdminForgotChangePasswordSuccess };
