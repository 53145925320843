import React from "react";
import { connect } from "react-redux";
import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { isStagingServer } from "../../_helpers";

class AnswerReminderCompletionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: "",
      failed_users:[],
      company_uuid:"",
      isStaging:false  
    }
  }
  
  componentDidMount() {
    this.setState({
      total_users : this.props.location.state.total_users,
      failed_users: this.props.location.state.failed_users ? this.props.location.state.failed_users : [],
      company_uuid: this.props.location.state.company_uuid,
    });

    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }
  }
  render() {
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies confirmation-of-user-registration completion-user-deletion">
          <HeaderAdmin></HeaderAdmin>
          <div className="content content-fix-phase2 phase2-content-min-height">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    回答リマインドメール送信完了
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-930 mt-40">
              <div className="form-box form-box-place mb-3">
                  <p className="detail">
          対象ユーザーの回答リマインドの送信が完了しました。（計{this.state.total_users}件）
                  </p>
                  { this.state.failed_users.length !== 0 ?
                  (<div>
                      <p className="detail">
                  下記のユーザーのみメール送信に失敗しました（計{this.state.failed_users.length}件）
                    </p>
                    <table className="table table-striped table-sm admin-confirm-success-table-body">
                    <thead>
                        <th colSpan='2'>
                            <td>
                            該当ユーザのみを選択し直し「回答リマインドを送信」を再実行ください
                            </td>
                        </th>
                    </thead>
                    <tbody>                   
                      {this.state.failed_users.map((user) => (
                      <tr key={user.id}>
                        <td>{user.full_name}</td>
                        <td>{user.email}</td>
                      </tr>
                      ))}               
                    </tbody>
                    </table> 
                    </div> ):("")}
                <div className="btn-login text-left mt-20 admin-confirm">
                  <a
                    href={`/admin/detail-company/${this.state.company_uuid}`}
                    style={{color:"#4d4150",width:'10rem',fontSize:"14px"}}
                    className="bnt-submit-link-footer  bnt-submit-fix"
                  >
                    ユーザー一覧へ戻る
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin></FooterAdmin>
        </div>
      </div>
    );
  }
}

const connectedAnswerReminderCompletionPage = connect()(AnswerReminderCompletionPage);
export { connectedAnswerReminderCompletionPage as AnswerReminderCompletionPage };