import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import moment from "moment";

import HeaderAdmin from "../../_componentsAdmin/HeaderAdmin";
import MenuAdmin from "../../_componentsAdmin/MenuAdmin";
import { TableAccount } from "./TableAccount/TableAccount";
import {adminActions, alertActions, workshopActions} from "../../_actions";
import {userMessages} from "../../_messages";

import 'react-day-picker/lib/style.css';


function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

class AdminAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeDateTimePicker = this.handleChangeDateTimePicker.bind(
      this
    );

    this.props.dispatch(adminActions.getUser());
    this.isCreateWorkshopSuccess = false;
    this.state = {
      listUser: this.props.listUser,
      datePicker: "dd/mm/yyyy"
    };
    const admin = JSON.parse(localStorage.getItem("admin"));
    this.state = { admin };
  }
  handleChangeDateTimePicker(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    const date = input.value.trim();
    let date_l = date.split("-")
    let format_date = date_l[2]+'-'+date_l[0]+'-'+date_l[1];
    this.setState({ datePicker: format_date });
  }
  addWorkShopDatetime = () => {
    this.isCreateWorkshopSuccess = true;
    let dateTimePicker = this.state.datePicker;
    if (dateTimePicker) {
      let workShopDateTime = {
        name: "ABC",
        date_workshop: dateTimePicker,
        company_id: this.props.admin.data.company_id
      };
      let check_format_date = moment(dateTimePicker, 'YYYY-MM-DD',true).isValid();
      if (check_format_date === false){
        this.props.dispatch(alertActions.error('MM-DD-YYYYの形式'));
      }else{
        this.props.dispatch(
            workshopActions.createWorkShopAction(workShopDateTime)
        );
      }
    }
    else{
      this.props.dispatch(alertActions.error('日付を選択してください'));
    }
  };
  componentWillReceiveProps = nextProps => {
    if (
      nextProps.alert.type === "alert-success" &&
      nextProps.alert.message === userMessages.COMPLETED_CREATE_WORKSHOP
    ) {
      this.isCreateWorkshopSuccess = true;
      try {
        this.timer = setTimeout(() => {
          this.isCreateWorkshopSuccess = false;
          this.forceUpdate();
        }, 4500);
      } catch (error) {}
    }
  };

  render() {
    const {alert} = this.props;
    const FORMAT = 'MM-dd-yyyy';
    return (
      <div className="page-pc page-pc-height">
        <div className="wrapper home-page-pc landing-page wrapper-fix-pc-color-1a2433">
          <HeaderAdmin
            name={
              this.state.admin.data.user_first_name +
              this.state.admin.data.user_last_name
            }
          ></HeaderAdmin>
          <section className="content">
            <div className="menu-wrap"></div>
            <MenuAdmin></MenuAdmin>
            <div className="right-content">
              <form name="form">
                <div className="gr-title">
                  <h2 className="title-common fz-31 mb-30 text-white">アカウント管理</h2>
                  <div className="gr-box-text-title">

                    <DayPickerInput
                        formatDate={formatDate}
                        format={FORMAT}
                        parseDate={parseDate}
                        placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                        onDayChange={this.handleChangeDateTimePicker}
                    />

                    <button
                      type="button"
                      onClick={this.addWorkShopDatetime}
                      className="btn-primary--bg ml-3 mb-0"
                    >
                      追加
                    </button>
                    {this.isCreateWorkshopSuccess && alert.message && (
                        <div className={`alert ${alert.type} gr-btn-item  col-3 margin-left-20`}>
                          {alert.message}
                        </div>
                    )}
                  </div>
                  <p className="title-detail fz-24 font-weight-bold text-white">
                    参加者30人中3名登録済み
                  </p>

                </div>
              </form>

              {/* <!-- S: Tab --> */}
              <div className="tab-data clearfix">
                <div className="nav-tab clearfix">
                  <ul className="clearfix list-tab">
                    <li className="active fix-padding" data-tab="tab-1">
                      <Link
                        to="/admin/account"
                        className="text-detail fz-24 text-white font-weight-bold"
                      >
                        参加者管理
                      </Link>
                    </li>
                    <li className="fix-padding" data-tab="tab-2">
                      <Link
                        to="/admin/account-create"
                        className="text-detail fz-24 text-white font-weight-bold"
                      >
                        参加者個別追加
                      </Link>
                    </li>
                    <li className="fix-padding" data-tab="tab-3">
                      <Link
                        to="/admin/account-import"
                        className="text-detail fz-24 text-white font-weight-bold"
                      >
                        参加者一括追加
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="group-tab-content clearfix">
                  <TableAccount />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authAdmin } = state;
  const { admin } = authAdmin;
  return {
    alert,
    admin
  };
}
const connectedAdminAcount = connect(mapStateToProps)(AdminAccount);
export { connectedAdminAcount as AdminAccount };
