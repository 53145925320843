import { connect } from "react-redux";
import React from "react";
import { adminActions, alertActions } from "../../../_actions";
import { userMessages } from "../../../_messages";
class InsertCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      workshop_id: 0
    };
    this.onClickUpload = this.onClickUpload.bind(this);
    this.handleForce = this.handleForce.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(adminActions.getWorskhops());
  }

  handleForce(event) {
    const { dispatch } = this.props;
    this.setState({
      file: event.target.files[0]
    });
    if (this.isValidatorFileCsv(event.target.files[0])) {
      dispatch(alertActions.success(userMessages.FILE_CSV_CHECK_OK));
    }
  }
  onClickUpload(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    if (this.isValidatorFileCsv(this.state.file)) {
      if (this.isValidatorForm(this.state.file, this.state.workshop_id)) {
        dispatch(
          adminActions.importFile(this.state.file, this.state.workshop_id)
        );
      }
    }
  }
  selectChange(e) {
    this.setState({
      workshop_id: e.target.value
    });
  }
  isValidatorFileCsv(file) {
    const { dispatch } = this.props;
    if (file) {
      if (file.name.toLowerCase().lastIndexOf(".csv") === -1) {
        dispatch(alertActions.error(userMessages.NOT_FILE_CSV));
        return false;
      }
    } else {
      dispatch(alertActions.error(userMessages.FILE_CSV_NOT_EMPTY));
      return false;
    }


    return true;
  }
  isValidatorForm(file, workshop_id) {
    if (file === "" || file === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.FILE_CSV_NOT_EMPTY));
      return false;
    }
    if (workshop_id === 0 || workshop_id === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(userMessages.WORKSHOP_REQUIRE));
      return false;
    }
    return true;
  }
  render() {
    const { alert, workshops } = this.props;
    return (
      <div id="tab-3" className="tab-content active col-6">
        <p className="fz-20 text-6075FF font-weight-bold para-detail">
          CSVテンプレートをダウンロード
        </p>
        <div className="form-box">
          <form action="">
            <div className="form-group d-block">
              <label className="mb-0 text-white">CSVをアップロード</label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input col-6"
                  id="customFile"
                  onChange={this.handleForce}
                  accept=".csv"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {this.state.file ? this.state.file.name : ""}
                </label>
              </div>
            </div>
            <div className="form-group gr-check">
              <label className="text-white">参加予定日</label>
              <select
                onChange={this.selectChange}
                className="form-control col-9"
                id="selectDepartment"
                name="workshops"
              >
                <option value={0}>--参加予定日を選択してください--</option>
                {workshops
                  ? workshops.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.date_workshop}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group">
              {alert.message && (
                <div className={`alert ${alert.type}  col-8`}>
                  {alert.message}
                </div>
              )}
            </div>
          </form>
        </div>
        <button
          type="submit"
          className="btn-primary--bg mb-3 mt-60 btn-import"
          onClick={this.onClickUpload}
        >
          追加
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { workshops } = state.workshopReducer;
  return {
    alert,
    workshops
  };
}
const connectedAdminDashboard = connect(mapStateToProps)(InsertCSV);
export { connectedAdminDashboard as InsertCSV };
