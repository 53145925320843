import { userConstants } from "../_constants";

const INITIAL_STATE = {
  contentDownload: []
};


export function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        is_change_password: false
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        is_change_password: true
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        error: action.error
      };
      case userConstants.EMAIL_CHECK_SUCCESS:
        return {
          ...state,
          contentDownload: action.contentDownload
        };
      case userConstants.EMAIL_CHECK_FAILURE:
      return{
        contentDownload : action.error
      }
    default:
      return state;
  }
}
