import { userAdminConstants } from "../_constants";

const INITIAL_STATE = {
  listUsers: [],
  activePercent: [],
  company: "",
  userInfo: "",
  error_list: [],
  message: "",
  listSuperUser: []
};

export function userAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userAdminConstants.GET_LIST_USER_ADMIN_REQUEST:
      return {
        ...state,
        listUsers: action.listUsers
      };
    case userAdminConstants.GET_ACTIVE_ACCOUNT_COMPANY_REQUEST:
      return {
        ...state,
        activePercent: action.activePercent
      };
    case userAdminConstants.GET_COMPANY_DETAIL_INFO_REQUEST:
      return {
        ...state,
        company: action.company
      };
    case userAdminConstants.GET_USER_INFO_REQUEST:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case userAdminConstants.CSV_UPLOAD_SUCCESS:
      return {
        ...state,
        error_list: action.error_list
      };
    case userAdminConstants.USER_ENABLE_TAG_NONE:
        return {
          ...state,
          message: action.data
        };  
    case userAdminConstants.GET_LIST_SUPER_USER__REQUEST:
      return {
        ...state,
        listSuperUser: action.listSuperUser
      };
    default:
      return state;
  }
}
