export const loginConstants = {
  LOGIN_ADMIN_REQUEST: "ADMIN_LOGIN_REQUEST_P2",
  LOGIN_ADMIN_SUCCESS: "ADMIN_LOGIN_SUCCESS_P2",
  LOGIN_ADMIN_FAILURE: "ADMIN_LOGIN_FAILURE_P2",
  LOGOUT_ADMIN: "ADMIN_LOGOUT_P2",
  CHECK_LOGIN: "CHECK_LOGIN_P2",
  CHECK_LOGIN_SUCCESS: "CHECK_LOGIN_SUCCESS_P2",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST_P2",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS_P2",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE_P2",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST_P2",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS_P2",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE_P2"
};
