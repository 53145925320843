import React from "react";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {
    Router,
    Switch,
    BrowserRouter
} from "react-router-dom";
import {connect} from "react-redux";
import {history} from "../_helpers";
import {alertActions} from "../_actions";
import { PrivateRoute, PublicRoute } from "../_components";
import {HomePage} from "../HomePage";
import {LoginPage} from "../LoginPage";
import {FirstTimeLogin} from "../FirstTimeLogin";
import {FirstTimeHomePage} from "../FirstTimeLogin/FirstTimeHomePage";
import {AssessmentHomePage} from "../AssessmentPage/AssessmentHomePage";
import {PreAssessmentPage} from "../AssessmentPage/PreAssessmentPage";
import {ChangePassword} from "../ChangePasswordPage";
import {ChangePasswordFirstTime, SuperAdminChangePasswordFirstTime} from "../ChangePasswordFirstTimePage";
import {ChangePasswordSuccess} from "../ChangePasswordPage/ChangePasswordSuccessPage";
import {ForgotPassword} from "../ForgotPasswordPage";
import {ForgotPasswordConfirm} from "../ForgotPasswordPage/ForgotPasswordConfirm";
import {AdminAccount} from "../Administrator/AdminAccount";
import {AdminCreateAccount} from "../Administrator/AdminAccount/CreateAccount";
import {AdminImportAccount} from "../Administrator/AdminAccount/InsertCSV";
import {ConsequencePage} from '../AssessmentPage/ConsequencePage';
import {NotFoundErrorPage} from "../ErrorPage";
// Phase 2: Admin
import {AdminLoginPagePhase2} from '../AdminDashboard/AdminLoginPage/AdminLoginPage';
import {AdminForgotPasswordPage} from "../AdminDashboard/AdminForgotPasswordPage";
import {AdminForgotPasswordConfirm} from "../AdminDashboard/AdminForgotPasswordConfirm";
import {AdminForgotChangePasswordSuccess} from "../AdminDashboard/AdminForgotChangePasswordSuccess";
import {ResendRegistrationMailSuccess,SuperAdminResendRegistrationMail} from "../AdminDashboard/ResendRegistrationMailSuccess"
import {ListCompanyPage} from "../AdminDashboard/ListCompanyPage";
import {DetailCompanyPage} from "../AdminDashboard/DetailCompanyPage";
import {NewCompanyPage} from "../AdminDashboard/NewCompanyPage";
import {EditCompanyPage} from "../AdminDashboard/EditCompanyPage";
import {AdminConfirmSuccess} from "../AdminDashboard/AdminConfirmSuccess";
import {UserRegistrationPage} from "../AdminDashboard/UserRegistrationPage";
import {CSVRegistrationPage} from "../AdminDashboard/CSVRegistrationPage";
import {AdminSettingPassword} from "../AdminDashboard/AdminSettingPassword";
import {ListWorkshopPage} from "../AdminDashboard/ListWorkshopPage";
import {NewWorkshopPage} from "../AdminDashboard/NewWorkshopPage";
import {UserConsequencePage} from "../AdminDashboard/UserConsequencePage";
import {AnswerReminderCompletionPage} from "../AdminDashboard/AnswerReminderCompletionPage"
import {Page403} from "../AdminDashboard/Page403";
import {AdminNotFoundErrorPage} from "../AdminDashboard/ErrorPage";
import {SuperAdminManagement, SuperAdminRegistrationPage} from "../AdminDashboard/SuperAdminManagement";
import { SuperAdminConfirmSuccess } from "../AdminDashboard/AdminConfirmSuccess/SuperAdminConfirmSuccess";
import ContentDownload from "../ContentDownload/ContentDownload";
import ContentDownloadToken from "../ContentDownload/ContentDownloadToken";
import {SorryPage} from "../ErrorPage/SorryPage";
import {Error} from "../ContentDownload/Error";
class App extends React.Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
            if (action === 'PUSH') {
                window.scrollTo(0, 0);
            }
        });
    }

    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Router history={history}>
                    <Switch>
                        <PrivateRoute.Frontend exact path="/" component={HomePage}/>
                        <PrivateRoute.Admin path='/admin/account' component={AdminAccount}/>
                        <PrivateRoute.Admin path='/admin/account-create' component={AdminCreateAccount}/>
                        <PrivateRoute.Admin path='/admin/account-import' component={AdminImportAccount}/>
                        
                        <PublicRoute.Frontend path="/login" component={LoginPage}/>
                        <PublicRoute.Frontend exact path="/first-time-login" component={FirstTimeLogin}/>
                        <PublicRoute.Frontend exact path="/change-password-first-time" component={ChangePasswordFirstTime}/>
                        <PrivateRoute.Frontend exact path="/first-time-home-page" component={FirstTimeHomePage}/>
                        <PrivateRoute.Frontend exact path="/change-password" component={ChangePassword}/>
                        <PublicRoute.Frontend path="/change-password-success" component={ChangePasswordSuccess}/>
                        <PublicRoute.Frontend path="/forgot-password" component={ForgotPassword}/>
                        <PublicRoute.Frontend exact path="/forgot-password-confirm" component={ForgotPasswordConfirm}/>
                        <PrivateRoute.Frontend exact path="/assessment-home-page" component={AssessmentHomePage}/>
                        <PrivateRoute.Frontend exact path="/pre-assessment-page" component={PreAssessmentPage}/>
                        <PrivateRoute.Frontend exact path="/consequence" component={ConsequencePage} />
                        <PrivateRoute.Frontend exact path="/content-download" component={ContentDownload}/>
                        <PrivateRoute.Frontend  path = "/mail-magazine-article/*" component={SorryPage}/>
                        <PrivateRoute.Frontend  path = "/mail-magazine" component={SorryPage}/>
                        <PublicRoute.Frontend exact path="/content-download/authenticate" component={ContentDownloadToken}/>
                        <PublicRoute.Frontend  exact path="/content-download/authenticate/error" component={Error}/>


                        {/* Admin Phase 2 */}
                        <PrivateRoute.Admin exact path="/admin" component={ListCompanyPage}/>
                        <PublicRoute.Admin exact path="/admin/login" component={AdminLoginPagePhase2}/>
                        <PublicRoute.Admin exact path='/admin/forgot-password' component={AdminForgotPasswordPage}/>
                        <PublicRoute.Admin exact path="/admin/forgot-password-confirm" component={AdminForgotPasswordConfirm}/>
                        <PublicRoute.Admin exact path="/admin/forgot-password-completed" component={AdminForgotChangePasswordSuccess}/>
                        <PublicRoute.Admin exact path="/admin/resend-registration-mail-success/:idResendMail" component={ResendRegistrationMailSuccess}/>

                        <PrivateRoute.Admin exact path="/admin/company" component={ListCompanyPage}/>
                        <PrivateRoute.Admin exact path="/admin/company/new" component={NewCompanyPage}/>
                        <PrivateRoute.Admin exact path="/admin/company/:companyUUID/edit" component={EditCompanyPage}/>
                        <PrivateRoute.Admin exact path="/admin/detail-company/:companyUUID" component={DetailCompanyPage}/>
                        <PublicRoute.Admin exact path="/admin/:companyUUID/admin-confirm-completed/:idActiveDeactiveDelete" component={AdminConfirmSuccess}/>
                        <PrivateRoute.Admin exact path="/admin/user-registration/:companyUUID" component={UserRegistrationPage}/>
                        <PrivateRoute.Admin exact path="/admin/:companyUUID/user-registration/:userUUID" component={UserRegistrationPage}/>
                        <PrivateRoute.Admin exact path="/admin/csv-registration/:companyUUID" component={CSVRegistrationPage}/>
                        <PrivateRoute.Admin exact path="/admin/workshop/:companyUUID" component={ListWorkshopPage}/>
                        <PrivateRoute.Admin exact path="/admin/create-workshop/:companyUUID" component={NewWorkshopPage}/>
                        <PrivateRoute.Admin exact path="/admin/workshop/:companyUUID/edit/:workshopUUID" component={NewWorkshopPage}/>
                        <PrivateRoute.Admin exact path="/admin/consequence/:userUUID" component={UserConsequencePage}/>
                        <PrivateRoute.Admin exact path="/admin/:companyUUID/assessment-reminder-completed" component={AnswerReminderCompletionPage}/>
                        <PublicRoute.Admin exact path="/admin/setting-password" component={AdminSettingPassword}/>
                        <PublicRoute.Admin exact path="/admin/403" component={Page403}/>

                        {/* Super Admin Phase  */}
                        <PrivateRoute.Admin path='/admin/super-admins' component={SuperAdminManagement}/>
                        <PrivateRoute.Admin path='/admin/super-admin-registration/:wscompanyUUID' component={SuperAdminRegistrationPage}/>
                        <PrivateRoute.Admin exact path="/admin/:wscompanyUUID/super-admin-registration/:userUUID" component={SuperAdminRegistrationPage}/>
                        <PublicRoute.Admin exact path="/admin/:wscompanyUUID/super-admin-confirm-completed/:idActiveDeactiveDelete" component={SuperAdminConfirmSuccess}/>
                        <PublicRoute.Frontend path="/admin/change-password-first-time" component={SuperAdminChangePasswordFirstTime}/>
                        <PublicRoute.Admin exact path="/admin/super-admin-resend-registration-mail-success/:idResendMail" component={SuperAdminResendRegistrationMail}/>   
                        <PrivateRoute.Admin path="/admin/*" component={AdminNotFoundErrorPage}/>
                        <PrivateRoute.Frontend  path="/*" component={NotFoundErrorPage}/>               

                    </Switch>
                </Router>
            </BrowserRouter>
        );
    }
}

const connectedApp = connect()(App);
export {connectedApp as App};