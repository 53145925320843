import React from "react";
import { connect } from "react-redux";

import ModalQuestionCommon from "../../AssessmentPage/components/ModalQuestionCommon";
import { AssessmentConsequence } from "../../AssessmentPage/components/AssessmentConsequence";

import { questionAdminActions } from "../_actions";
import HeaderAdmin from "../Components/Header/HeaderAdmin";

class ConsequencePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radar: [],
      average_compare_up: [],
      average_compare_down: [],
      spiderChart: [0, 0, 0, 0, 0, 0]
    };
  }

  componentDidMount() {
    if (!this.props.analystInfo || this.props.analystInfo.length === 0) {
      this.props.dispatch(
        questionAdminActions.getHistoryResultAction(this.props.match.params.userUUID)
      );
    }
    //Component vua load het trang
    try {
      this.setState(
        {
          radar: this.props.analystInfo.radar[0],
          average_compare_up: this.props.analystInfo.average_compare_up,
          average_compare_down: this.props.analystInfo.average_compare_down
        },
        () => {
          const spiderChartArrayValue = [
            Math.round(this.state.radar.score_retention),
            Math.round(this.state.radar.score_listen),
            Math.round(this.state.radar.score_ask_questions),
            Math.round(this.state.radar.score_advance),
            Math.round(this.state.radar.score_personal_reciprocal),
            Math.round(this.state.radar.score_sharing_purpose)
          ];
          this.setState({ spiderChart: spiderChartArrayValue });
        }
      );
    } catch (error) {
    }
  }
  componentDidUpdate = prevProps => {
    if (this.props.analystInfo !== prevProps.analystInfo) {
      try {
        this.setState(
          {
            radar: this.props.analystInfo.radar[0],
            average_compare_up: this.props.analystInfo.average_compare_up,
            average_compare_down: this.props.analystInfo.average_compare_down
          },
          () => {
            if (this.props.radar !== "") {
              const spiderChartArrayValue = [
                Math.round(this.state.radar.score_retention),
                Math.round(this.state.radar.score_listen),
                Math.round(this.state.radar.score_ask_questions),
                Math.round(this.state.radar.score_advance),
                Math.round(this.state.radar.score_personal_reciprocal),
                Math.round(this.state.radar.score_sharing_purpose)
              ];
              this.setState({ spiderChart: spiderChartArrayValue });
            }
          }
        );
      } catch (error) {
      }
    }
  };

  printGraph = () => {
    window.print();
  }

  render() {
    const {
      spiderChart,
      average_compare_up,
      average_compare_down,
    } = this.state;
    return (
      <div className="wrapper mobile assessment-first assessment-consequence assessment-first-fix assessment-consequence-fix assessment-consequence-top admin-fix">

        <HeaderAdmin  user = {this.props.analystInfo} date={this.props.analystInfo.assessment_date}/>

        <div className="assessment-consequence-date">
          <p className="assessment-consequence-date-heading">3分間コーチ・インベントリー  結果レポート</p>
          <NameLabel analystInfo={this.props.analystInfo} />
          <p className="assessment-consequence-date-date">回答日：{this.props.analystInfo.assessment_date}</p>
        </div>


        <div className="content fix-content-consequence-white-bg">
          <div className="content-vertwo">
            <AssessmentConsequence
              spiderChart={spiderChart}
              averageCompareUp={average_compare_up}
              averageCompareDown={average_compare_down}
            />

            {/* Button */}
            <div className="assessment-consequence-btn from-group text-center">

              <button
                onClick={this.printGraph}
                type="button"
                className="bnt-submit bnt-submit-fix btn-assesment-submit bg-color-white mb-24"
              >
                結果を印刷する
              </button>

            </div>
          </div>
        </div>
        <div className="design-by fix-design-by-consequence">
          © COACH A Co., Ltd. All Rights Reserved.
        </div>

        <ModalQuestionCommon></ModalQuestionCommon>

      </div>

    );
  }
}

function NameLabel(props) {
  if (!props.analystInfo || props.analystInfo.length === 0) {
    return null;
  }
  const user = props.analystInfo.user;
  return (
    <p className="assessment-consequence-date-user">
      {`${user.last_name} ${user.first_name}`}
      <HonorificTag />
    </p>
  );
}


function HonorificTag(props) {
  return (
    <>
      <span className="screen-only">さん</span>
      <span className="print-only">様</span>
    </>
  )
}


function mapStateToProps(state, origProps) {
  const { questionAdminReducer } = state;
  const { analystInfo } = questionAdminReducer;
  return {
    userUUID: origProps.match.params.userUUID,
    analystInfo,
  };
}
const connectedUserConsequencePage = connect(mapStateToProps)(ConsequencePage);
export { connectedUserConsequencePage as UserConsequencePage };
