import React, { useState } from "react";

import { connect } from "react-redux";
import { questionActions } from "../../../_actions";

const QuestionGroup = ({
  question,
  onAddAnswerToArray
}) => {
  const { id, name, point } = question;
  const [state, setState] = useState({
    point_in: point
  });

  let listAnswers = [
    { id: "1", value: "1", checked: false },
    { id: "2", value: "2", checked: false },
    { id: "3", value: "3", checked: false },
    { id: "4", value: "4", checked: false },
    { id: "5", value: "5", checked: false },
    { id: "6", value: "6", checked: false },
    { id: "7", value: "7", checked: false }
  ];
  const onChooseAnswer = event => {
    var answer = {
      question_id: question.id,
      point: event.target.value
    };
    onAddAnswerToArray(answer);
    setState({ point_in: event.target.value });
    // addAnswer(answer);
    //checked={`${onChecked}`}
  };
  listAnswers.forEach(answer => {
    if (answer.value === point) answer.checked = true;
  });

  return (
    <div
    className={`question-gr-check-box ${
      state.point_in ? "bg-color-F4F5F4":"bg-color-FFEBEC"}`}
    >
      <div className="content-question-check">
        <span className="question-number-check">
          <span className="d-inline-block mr-1">Q</span>
          {id}
        </span>
        {state.point_in ? ("") : (
          <span className="dager-line">回答が記入されていません</span>
        )}
        <p className="question-detail-check">{name}</p>
      </div>
      <div className="gr-checkbox clearfix">
        {listAnswers.map(answer => (
          <div key={answer.id} className="checkbox-item">
            <div className="form-check-label">
              <label className="container-checkbox">
                <input
                  type="radio"
                  onChange={onChooseAnswer}
                  name={`${id}checkbox`}
                  value={answer.value}
                  defaultChecked={answer.checked}
                  required
                />
                <span
                  className={`checkmark ${
                    answer.value <= state.point_in ? "color-question-click" : ""
                  }`}
                >
                  {answer.value}
                </span>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="box-text-ques d-flex justify-content-between">
        <span className="text-ques-ct d-block fz-12 text-left">
          全くあて
          <br />
          はまらない
        </span>
        <span className="text-ques-ct d-block fz-12 text-center">
          どちらとも
          <br />
          いえない
        </span>
        <span className="text-ques-ct d-block fz-12 text-right">
          とてもよく
          <br />
          あてはまる
        </span>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addAnswer: function addAnswer(answer) {
      return dispatch(questionActions.addAnswer(answer));
    }
  };
}

export default connect(null, mapDispatchToProps)(QuestionGroup);
