import React from "react";
import { connect } from "react-redux";

import Header from "../../_components/Header";
import Footer from "../../_components/Footer";
import ScrollToTop from "../../_components/ScrollToTop";

class AssessmentHomePage extends React.Component {
  componentDidMount() {
    // this.props.dispatch(userActions.getAll());
  }

  render() {
    // const { user, users } = this.props;
    return (
      <div className="wrapper mobile assessment-home">
        <Header></Header>
        <section className="content">
          <div className="content-mobile">
            <div className="login-box">
              <h2 className="title-login">アセスメントホーム</h2>
            </div>
            <a href="#/" className="link-list" onClick={event => event.preventDefault()}>
              ・３回目アセスメント
            </a>
            <a href="#/" className="link-list" onClick={event => event.preventDefault()}>
              ・３回目アセスメント
            </a>
            <a href="#/" className="link-list" onClick={event => event.preventDefault()}>
              ・３回目アセスメント
            </a>
          </div>
        </section>
        <Footer></Footer>
        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users
  };
}

const connectedAssessmentHomePage = connect(mapStateToProps)(
  AssessmentHomePage
);
export { connectedAssessmentHomePage as AssessmentHomePage };
