import { connect } from "react-redux";
import React from "react";
import { adminActions, alertActions } from "../../../_actions";

import Modal from "react-awesome-modal";
class TableAccount extends React.Component {
  constructor(props) {
    super(props);

    this.handlePagination = this.handlePagination.bind(this);
    this.handleNextAndPrevious = this.handleNextAndPrevious.bind(this);
    this.handleFirstAndLast = this.handleFirstAndLast.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.isUpdateSuccess = false;

    this.state = {
      curentPage: 1,
      countUser: 0,
      // day la list data khi check vao check box
      arrayUser: [],

      isChecked: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      ischeckAll: false,
      sortByDep: "0",
      sortByMail: "0",
      visible: false,
      modalName: ""
    };
  }

  handlePagination(event) {
    this.setCountUser();
    this.setState({ curentPage: event.target.text });
    this.props.dispatch(
      adminActions.sortUserByAction(
        event.target.text * 10 - 10,
        this.state.sortByMail,
        this.state.sortByDep
      )
    );
    this.isCheckeds();
  }
  handleNextAndPrevious(urlNextAndPrevious) {
    //set count user
    this.setCountUser();
    // set index page
    const query = new URLSearchParams(urlNextAndPrevious);
    const mail = query.get("offset");
    const curentPage = Math.ceil(Number(mail / 10) + 1);

    this.setState({ curentPage: curentPage });

    this.props.dispatch(adminActions.nextAndPrevious(urlNextAndPrevious));
    this.isCheckeds();
  }
  handleFirstAndLast(count, flat) {
    const offset = (Math.ceil(Number(count) / 10) - 1) * 10;
    const curentPage = Math.ceil(Number(count) / 10);

    this.setCountUser();
    if (flat === 1) {
      // this.props.dispatch(adminActions.pagination(0));
      this.props.dispatch(
        adminActions.sortUserByAction(
          0,
          this.state.sortByMail,
          this.state.sortByDep
        )
      );
      // set index page
      this.setState({ curentPage: 1 });
      this.isCheckeds();
    } else {
      // this.props.dispatch(adminActions.pagination(offset));
      this.props.dispatch(
        adminActions.sortUserByAction(
          offset,
          this.state.sortByMail,
          this.state.sortByDep
        )
      );
      // set index page
      this.setState({ curentPage: curentPage });
      this.isCheckeds();
    }
  }

  handleCheckBox(id, index) {
    let arrayUser = this.state.arrayUser;
    const curentPage = this.state.curentPage;
    if (arrayUser.length > 0) {
      for (let i = 0; i < arrayUser.length; i++) {
        if (arrayUser[i].id === id) {
          arrayUser.splice(i, 1);
          this.setState({ arrayUser: arrayUser });
          this.isCheckeds();
          return;
        }
      }
    }
    arrayUser.push({ id: id, index: index, curentPage: curentPage });
    this.setState({ arrayUser: arrayUser });
    this.isCheckeds();
  }

  setChecked(event) {
    const array = this.state.arrayUser;
    const index = array.indexOf(event.target.id);
    if (index !== -1) {
      return true;
    }
    return false;
  }
  setCountUser() {
    const count = this.props.listUser.count;
    this.setState({ countUser: count });
  }
  isCheckeds() {
    const isCheckeds = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ];
    let counts = 0;
    const arrayUser = this.state.arrayUser;
    const listUser = this.props.listUser.results;
    if (arrayUser.length > 0) {
      for (let i = 0; i < listUser.length; i++) {
        for (let j = 0; j < arrayUser.length; j++) {
          if (arrayUser[j].id === listUser[i].id) {
            isCheckeds[i] = true;
            counts++;
          }
        }
      }
    }

    if (counts === 10) {
      this.setState({
        ischeckAll: true
      });
    } else {
      this.setState({
        ischeckAll: false
      });
    }
    this.setState({ isChecked: isCheckeds });
  }
  handleCheckAll() {
    const checkAll = this.state.ischeckAll;
    let arrayUser = this.state.arrayUser;
    const curentPage = this.state.curentPage;
    if (checkAll === false) {
      this.props.listUser.results.forEach((item, i) => {
        let existingUser = arrayUser.find(user => user.id === item.id);
        if (!existingUser)
          arrayUser.push({ id: item.id, index: i, curentPage: curentPage });
      });
      this.setState({ arrayUser: arrayUser });
      this.isCheckeds();
    } else {
      this.props.listUser.results.forEach((item, i) => {
        this.handleCheckBox(item.id, i);
      });
    }
    this.setState({ ischeckAll: !checkAll });
  }
  componentWillReceiveProps = nextProps => {
    if (
      nextProps.isDeleteUsersProcessing !== this.props.isDeleteUsersProcessing &&
      nextProps.isDeleteUsersProcessing === true
    ) {
      this.setState({ arrayUser: [] }, () => {
        this.props.dispatch(adminActions.resetIsDeleteUsersProcessingAction());
        this.isCheckeds();
      });
    }
  };
  componentDidUpdate = prevProps => {
    if (prevProps.listUser !== this.props.listUser) {
      this.isCheckeds();
    }
  };
  deleteUser = () => {
    this.isUpdateSuccess = true;
    let arrayUser = this.state.arrayUser;
    let listUsersDelete = arrayUser.map(user => user.id);
    if (listUsersDelete.length === 0) {
      this.props.dispatch(alertActions.error("ユーザーを選択してください"));
    } else {
      this.setState({ curentPage: 1 });
      this.props.dispatch(adminActions.deleteUserAction(listUsersDelete, 1));
    }
  };
  sendMailInviteUser = () => {
    this.isUpdateSuccess = true;
    let arrayUser = this.state.arrayUser;
    let listUsersSendMail = arrayUser.map(user => user.id);
    if (listUsersSendMail.length === 0) {
      this.props.dispatch(alertActions.error("ユーザーを選択してください"));
    } else {
      this.props.dispatch(
        adminActions.sendMailInviteUserAction(listUsersSendMail)
      );
    }
  };
  onChooseSortBy = event => {
    let offest = this.state.curentPage * 10 - 10;
    let sortByDep = this.state.sortByDep;
    let sortByMail = this.state.sortByMail;
    switch (event.target.value) {
      case "sortDep":
        // sortByDep == "0" ? (sortByDep = "1") : (sortByDep = "0");
        sortByDep = "1";
        sortByMail = "0";

        break;
      case "sortMail":
        sortByDep = "0";
        sortByMail = "1";

        break;

      default:
        sortByDep = "0";
        sortByMail = "0";
        break;
    }
    this.setState({ sortByDep: sortByDep, sortByMail: sortByMail });
    this.props.dispatch(
      adminActions.sortUserByAction(offest, sortByMail, sortByDep)
    );
  };
  openModal(event) {
    this.setState({
      visible: true,
      modalName: event.target.value
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  clickYesModalDeleteSendMail() {
    if (this.state.modalName === "delete") {
      this.deleteUser();
    }
    if (this.state.modalName === "sendMail") {
      this.sendMailInviteUser();
    }
    this.closeModal();
  }
  render() {
    const { listUser, alert } = this.props;
    const { curentPage, isChecked, ischeckAll } = this.state;
    return (
      <div id="tab-1" className="tab-content active">
        <Modal
          visible={this.state.visible}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          width="300"
        >
          <div className="modal-body">
            {this.state.modalName === "delete" ? (
              <p className="mb-0 fz-20">参加者を削除しますか。</p>
            ) : (
              ""
            )}
            {this.state.modalName === "sendMail" ? (
              <p className="mb-0 fz-20">参加者にメールを送信しますか。</p>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.clickYesModalDeleteSendMail()}
            >
              {this.state.modalName === "delete" ? "削除" : ""}
              {this.state.modalName === "sendMail" ? "送信" : ""}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.closeModal()}
            >
              キャンセル
            </button>
          </div>
        </Modal>
        <div className="table-data mt-5">
          <div className="gr-form clearfix">
            <div className="gr-btn-item">
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  onChange={this.handleCheckAll}
                  checked={ischeckAll}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="gr-btn-item">
              <button
                type="button"
                className="btn-gr"
                value="delete"
                onClick={event => this.openModal(event)}
              >
                削除
              </button>

              <button
                type="submit"
                className="btn-gr"
                value="sendMail"
                onClick={event => this.openModal(event)}
              >
                メール配信
              </button>
            </div>

            {this.isUpdateSuccess && alert.message && (
              <div
                className={`alert ${alert.type} gr-btn-item  col-3 margin-left-20`}
              >
                {alert.message}
              </div>
            )}

            <div className="gr-btn-item">
              <div className="form-select">
                <select
                  className="form-control"
                  id="exampleSelect1"
                  onChange={this.onChooseSortBy}
                >
                  <option defaultValue>絞り込み...</option>
                  <option value="sortDep">役割順</option>
                  <option value="sortMail">メールアドレス順</option>
                </select>
              </div>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">名前</th>
                <th scope="col">メールアドレス</th>
                <th scope="col">役割</th>
              </tr>
            </thead>
            <tbody>
              {listUser
                ? Object.keys(listUser.results).map((item, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <label className="container-checkbox">
                          <input
                            checked={isChecked[i]}
                            onChange={() =>
                              this.handleCheckBox(listUser.results[item].id, i)
                            }
                            type="checkbox"
                            name="checkbox"
                            className="checkbox"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </th>
                      <td>
                        <a
                          className="link-table-box"
                          href="#/"
                          onClick={event => event.preventDefault()}
                        >
                          {listUser.results[item].last_name}
                          {listUser.results[item].first_name}
                        </a>
                      </td>
                      <td> {listUser.results[item].email} </td>
                      <td>{listUser.results[item].department}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {listUser ? (
            <nav aria-label="Page navigation" className="mt-20 tab-panigation">
              <ul className="pagination">
                {
                  <li
                    className={`page-item ${
                      listUser.previous === null ? " disabled-li" : ""
                    }`}
                  >
                    <a
                      href="#/"
                      className="page-link"
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleNextAndPrevious(listUser.previous);
                      }}
                    >
                      前へ
                    </a>
                  </li>
                }
                <li
                  className={`page-item ${
                    listUser.previous === null ? " disabled-li" : ""
                  }`}
                >
                  <a
                    href="#/"
                    className="page-link"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleFirstAndLast(listUser.count, 1);
                    }}
                  >
                    最初へ
                  </a>
                </li>
                {[...Array(Math.ceil(Number(listUser.count) / 10))].map(
                  (item, i) => (
                    <li
                      key={(i = i + 1)}
                      onClick={this.handlePagination}
                      className={`page-item ${curentPage === i ? "active" : ""}`}
                    >
                      <a
                        href="#/"
                        className="page-link"
                        onClick={event => event.preventDefault()}
                      >
                        {i}
                      </a>
                    </li>
                  )
                )}
                <li
                  className={`page-item ${
                    listUser.next === null ? " disabled-li" : ""
                  }`}
                >
                  <a
                    href="#/"
                    className="page-link"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleFirstAndLast(listUser.count, 2);
                    }}
                  >
                    最後へ
                  </a>
                </li>
                <li
                  className={`page-item ${
                    listUser.next === null ? " disabled-li" : ""
                  }`}
                >
                  <a
                    href="#/"
                    className="page-link"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleNextAndPrevious(listUser.next);
                    }}
                  >
                    後へ
                  </a>
                </li>
              </ul>
            </nav>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const {
    listUser,
    isDeleteUsersProcessing,
    isSendMailUsersProcessing
  } = state.admin;
  return {
    alert,
    listUser,
    isDeleteUsersProcessing,
    isSendMailUsersProcessing
  };
}
const connectedAdminDashboard = connect(mapStateToProps)(TableAccount);
export { connectedAdminDashboard as TableAccount };
