import * as Sentry from '@sentry/browser';


export function setupSentry () {
  Sentry.init({
    dsn: process.env.SENTRY_URL,
    environment: process.env.NODE_ENV,
  });
}

export function reportToSentry (error, errorInfo) {
  Sentry.withScope(scope => {
    scope.setExtras(errorInfo);
    const eventId = Sentry.captureException(error);
    console.info('reported to Sentry:', eventId);
  });
}
