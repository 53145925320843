import React from "react";
import { Link } from "react-router-dom";

const NavItem = props => {
  const {
    homeActive,
    assessmentActive,
    consequenceActive
  } = props;
  return (
    <div>
      {assessmentActive ? (
        ""
      ) : (
        <ul className="list-menu clearfix">
          <li
            className={`list-menu-item d-block ${homeActive ? "active" : ""}`}
          >
            <Link to="/" className="link-list-menu d-block">
              <span className="img-gr">
                <i className="fas fa-home" />
              </span>
              <span className="title-menu d-block-on-pc">ホーム</span>
            </Link>
          </li>

          <li
            className={`list-menu-item d-block ${consequenceActive ? "active" : ""}`}
          >
            <Link to="/consequence" className="link-list-menu d-block">
              <span className="img-gr">
                <i className="fas fa-file-medical-alt" />
              </span>
              <span className="title-menu d-block-on-pc">結果レポート</span>
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};
export default NavItem;
