export const questionConstants = {
  QUESTIONS_GETALL_REQUEST: "QUESTIONS_GETALL_REQUEST",
  QUESTIONS_GETALL_FAILURE: "QUESTIONS_GETALL_FAILURE",

  ADD_ANSWER: "ADD_ANSWER",

  SUBMIT_ALL_ANSWERS: "SUBMIT_ALL_ANSWERS",
  SUBMIT_ALL_ANSWERS_SUCCESS: "SUBMIT_ALL_ANSWERS_SUCCESS",
  SUBMIT_ALL_ANSWERS_FAILURE: "SUBMIT_ALL_ANSWERS_FAILURE",

  SEND_MAIL_COMPLETED_QUESTIONS: "SEND_MAIL_COMPLETED_QUESTIONS",
  GET_HISTORY_RESULT_ANSWER_QUESTIONS: "GET_HISTORY_RESULT_ANSWER_QUESTIONS"
};
