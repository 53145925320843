import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ModalQuestionCommon from "../components/ModalQuestionCommon";
import { AssessmentConsequence } from "../components/AssessmentConsequence";

import { questionActions } from "../../_actions";
import Header from "../../_components/Header";
import { PrivateErrorPage } from "../../ErrorPage";

class ConsequencePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			radar: [],
			average_compare_up: [],
			average_compare_down: [],
			spiderChart: [0, 0, 0, 0, 0, 0]
		};
	}
	componentDidMount() {
		// questionService.getHistoryResultAnswerQuestion();
		if (!this.props.analystInfo || this.props.analystInfo.length === 0) {
			this.props.dispatch(
				questionActions.getHistoryResultAnswerQuestionAction()
			);
		}
		//Component vua load het trang
		try {
			this.setState(
				{
					radar: this.props.analystInfo.radar[0],
					average_compare_up: this.props.analystInfo.average_compare_up,
					average_compare_down: this.props.analystInfo.average_compare_down
				},
				() => {
					const spiderChartArrayValue = [
						Math.round(this.state.radar.score_retention),
						Math.round(this.state.radar.score_listen),
						Math.round(this.state.radar.score_ask_questions),
						Math.round(this.state.radar.score_advance),
						Math.round(this.state.radar.score_personal_reciprocal),
						Math.round(this.state.radar.score_sharing_purpose)
					];
					this.setState({ spiderChart: spiderChartArrayValue });
				}
			);
		} catch (error) {
		}
	}
	componentDidUpdate = prevProps => {
		if (this.props.analystInfo !== prevProps.analystInfo) {
			try {
				this.setState(
					{
						radar: this.props.analystInfo.radar[0],
						average_compare_up: this.props.analystInfo.average_compare_up,
						average_compare_down: this.props.analystInfo.average_compare_down
					},
					() => {
						if (this.props.radar !== "") {
							const spiderChartArrayValue = [
								Math.round(this.state.radar.score_retention),
								Math.round(this.state.radar.score_listen),
								Math.round(this.state.radar.score_ask_questions),
								Math.round(this.state.radar.score_advance),
								Math.round(this.state.radar.score_personal_reciprocal),
								Math.round(this.state.radar.score_sharing_purpose)
							];
							this.setState({ spiderChart: spiderChartArrayValue });
						}
					}
				);
			} catch (error) {
			}
		}
	};

	printGraph = () => {
		window.print();
	}

	render() {
		const { spiderChart } = this.state;
		const { alert } = this.props;
		if (alert.type === 'alert-danger') {
		  return <PrivateErrorPage />;
		}
		return (
			<div className="wrapper mobile assessment-first assessment-consequence assessment-first-fix assessment-consequence-fix assessment-consequence-top">

				<Header consequenceActive="true" date={this.props.analystInfo.assessment_date}></Header>

				<div className="assessment-consequence-date">
					<p className="assessment-consequence-date-heading">3分間コーチ・インベントリー  結果レポート</p>
					{this.props.user ? (<p className="assessment-consequence-date-user">{this.props.user.data.user_last_name+" "+this.props.user.data.user_first_name+"さん"}</p>) :("")}					

					<p className="assessment-consequence-date-date">回答日：{this.props.analystInfo.assessment_date}</p>

				</div>


				<div className="content fix-content-consequence-white-bg">
					<div className="content-vertwo">
						<AssessmentConsequence
							spiderChart={spiderChart}
							averageCompareUp={this.state.average_compare_up}
							averageCompareDown={this.state.average_compare_down}
						/>

						{/* Button */}
						<div className="assessment-consequence-btn from-group text-center">

							<button
								onClick={this.printGraph}
								type="button"
								className="bnt-submit bnt-submit-fix btn-assesment-submit bg-color-white mb-24"
							>
								結果を印刷する
              					</button>


							<Link to="/">
								<button
									type="button"
									className="bnt-submit bg-color-4D4150 mb-24 ml-5"
								>
									ホームへ戻る
                					</button>
							</Link>
						</div>
					</div>
				</div>
				<div className="design-by fix-design-by-consequence">
					© COACH A Co., Ltd. All Rights Reserved.
        		</div>

				<ModalQuestionCommon></ModalQuestionCommon>

			</div>

		);
	}
}

function mapStateToProps(state) {
	const { questionsReducer, authentication, alert } = state;
	const { analystInfo } = questionsReducer;
	const { radar } = analystInfo;
	const { user } = authentication;
	return {
		user,
		analystInfo,
		radar,
		alert
	};
}
const connectedConsequencePage = connect(mapStateToProps)(ConsequencePage);
export { connectedConsequencePage as ConsequencePage };
