import axios from "axios";
import {authHeader, history} from "../_helpers";


const API_LOGIN = process.env.URL_API + "/v1/auth/login";
const API_CHECK_LOGIN = process.env.URL_API + "/v1/users/check_login/";
const API_LOGOUT = process.env.URL_API + "/v1/auth/logout";
const API_CHANGE_PASSWORD = process.env.URL_API + "/v1/users/change-password";
const API_FORGOT_PASSWORD = process.env.URL_API + "/v1/users/forgot-password/";
const API_FORGOT_PASSWORD_CONFIRM = process.env.URL_API + "/v1/users/forgot-password/confirm/";
const API_CHECK_FIRST_TIME_LOGIN = process.env.URL_API + "/v1/users/check_first_time_login/"
const API_CHECK_CONTENT_DOWNLOAD_TOKEN = process.env.URL_API + "/content-download/content_download_authenticate"
const API_CHECK_CONTENT_DOWNLOAD_EMAIL = process.env.URL_API + "/content-download/validate_email_download/"
const CONTENT_DOWNLOAD_BOOK1 = process.env.URL_API + "/content-download/book/1/";
const CONTENT_DOWNLOAD_BOOK2 = process.env.URL_API + "/content-download/book/2/";
export const userService = {
    login,
    logout,
    changePassword,
    forgotPassword,
    forgotPasswordConfirm,
    checkLogin,
    checkFirstTimeLogin,
    checkContentDownloadTokens,
    checkContentDownloadEmail
};

function checkContentDownloadTokens(token) {
    if (!token) {
        throw new Error(`got invalid token: ${token}`);
    }

    const api = API_CHECK_CONTENT_DOWNLOAD_TOKEN + "?token=" + token;
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.get(api, {headers : headers}).then(response => {

        if(response.data.data.token_status === false){
            history.push('/content-download/authenticate/error');
          }

        return response.data;

    }).catch(error => {
        return error.response;
    });
}

function checkContentDownloadEmail(email,book) {
    if (!email) {
        throw new Error(`got invalid token: ${email}`);
    }

    const api = API_CHECK_CONTENT_DOWNLOAD_EMAIL + "?email=" + email;
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.get(api, {headers : headers}).then(response => {
        if(response.data.code == 200 && book == 1){
            window.location.href = CONTENT_DOWNLOAD_BOOK1
            return response.data;

          }
          else if(response.data.code == 200 && book == 2){
            window.location.href = CONTENT_DOWNLOAD_BOOK2
            return response.data;
          }
        
            return response.data;
         
    }).catch(error => {
        return error.response;
    });
}

function checkFirstTimeLogin(token) {
    if (!token) {
        throw new Error(`got invalid token: ${token}`);
    }

    const api = API_CHECK_FIRST_TIME_LOGIN + "?token=" + token;
    const headers = {
        "Content-Type": "application/json",
        Authorization: authHeader()
    };

    return axios.get(api, {headers : headers}).then(response => {

        if(response.data.data.is_active === false){
            userService.logout(token);
            history.push('/login');
          }
        return response.data;

    }).catch(error => {
        return error.response;
    });
}

function checkLogin(token) {
    if (token !== '') {
        const AuthStr = 'Bearer '.concat(token);
        return axios.get(API_CHECK_LOGIN, {headers: {Authorization: AuthStr}}).then(response => {
        })
            .catch(error => {
                if (error.response.status === 401) {
                    logout(token);
                }
                return error.response;
            });
    }
}

function login(username, password) {
    const body = {
        username,
        password
    };
    return axios
        .post(API_LOGIN, body)
        .then(res => {
            localStorage.setItem("user", JSON.stringify(res.data));
            return res;
        })
        .catch(error => {
            return error.response;
        });
}

function logout(token) {
    const body = {
        token
    };
    return axios
        .post(API_LOGOUT, body)
        .then(res => {
            // remove user from local storage to log user out
            localStorage.removeItem("user");
            return res;
        })
        .catch(error => {
            return error.response;
        });
}

function changePassword(current_password,password, confirmed_password,token) {
    const body = {
        current_password,
        password,
        confirmed_password,
        token
    };
    const headers = {
        "Content-Type": "application/json",
        Authorization: authHeader()
    };

    return axios
        .put(API_CHANGE_PASSWORD, body, {
            headers: headers
        })
        .then(data => {
            localStorage.setItem("user", JSON.stringify(data.data));
            return data;
        })
        .catch(error => {
            return error.response;
        });
}

function forgotPassword(email) {
    const body = {
        email
    };
    const headers = {
        "Content-Type": "application/json"
    };

    return axios
        .post(API_FORGOT_PASSWORD, body, {
            headers: headers
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            return error.response;
        });
}

function forgotPasswordConfirm(password, token) {
    const body = {
        password,
        token
    };
    const headers = {
        "Content-Type": "application/json",
        Authorization: authHeader()
    };

    return axios
        .post(API_FORGOT_PASSWORD_CONFIRM, body, {
            headers: headers
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            return error.response;
        });
}
