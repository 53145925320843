export const companyConstants = {
  GET_LIST_COMPANY_REQUEST: "GET_LIST_COMPANY_REQUEST",
  GET_LIST_COMPANY_ERROR: "GET_LIST_COMPANY_ERROR",
  GET_LIST_COMPANY_SUCCESS: "GET_LIST_COMPANY_SUCCESS",
  GET_USER_LIST_COMPANY_SUCCESS: "GET_USER_LIST_COMPANY_SUCCESS",
  GET_USER_LIST_COMPANY_ERROR: "GET_USER_LIST_COMPANY_ERROR",

  CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_ERROR: "CREATE_COMPANY_ERROR",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",

  UPDATE_COMPANY_REQUEST: "UPDATE_COMPANY_REQUEST",
  UPDATE_COMPANY_ERROR: "UPDATE_COMPANY_ERROR",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",

  DETAIL_COMPANY_REQUEST: "DETAIL_COMPANY_REQUEST",
  DETAIL_COMPANY_ERROR: "DETAIL_COMPANY_ERROR",
  DETAIL_COMPANY_SUCCESS: "DETAIL_COMPANY_SUCCESS",

  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAIL: "DELETE_COMPANY_FAIL",
  RESET_STATUS_DELETE_COMPANY: "RESET_STATUS_DELETE_COMPANY",
  COMPANY_WITH_NO_USER :"COMPANY WITH NO USER",
  COMPANY_WITH_ONE_USER : "COMPANY WITH ONE USER",
  COMAPNY_WITH_MAIL_MAGAZINE_PERIOD : "COMAPNY_WITH_MAIL_MAGAZINE_PERIOD",
  COMPANY_WITH_USER_ACTIVE : "COMPANY_WITH_USER_ACTIVE"
};
