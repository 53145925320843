import { workshopAdminConstants } from "../_constants";

const INITIAL_STATE = {
  workshopsAdmin: [],
  workshopInfo: ""
};

export function workshopsAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case workshopAdminConstants.GET_LIST_WORKSHOP_ADMIN_REQUEST:
      return {
        ...state,
        workshopsAdmin: action.workshopsAdmin
      };
    case workshopAdminConstants.GET_WORKSHOP_ADMIN_REQUEST:
      return {
        ...state,
        workshopInfo: action.workshopInfo
      };
    default:
      return state;
  }
}
