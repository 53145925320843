import React, { Component } from "react";
import HeaderAdmin from "../Components/Header";
import { connect } from "react-redux";
import "../../../assets/styles/css/bootstrap.css";
import "../../../assets/styles/css/main-phase-2.css";
import { Link } from "react-router-dom";
import { userAdminActions, loginActions } from "../_actions";
import { getAccessTokenAdmin } from "../../_helpers";
import Pagination from "react-js-pagination";
import FooterAdmin from "../Components/Footer";
import Modal from "react-awesome-modal";

class SuperAdminManagement extends Component {
  constructor(props) {
    super();
    const admin = JSON.parse(localStorage.getItem("admin"));
    this.state = { admin };
    this.state = {
      activeUserPage: 1,
      activePage: 1,
      listColumnTableUsers: [
        { id: 0, name: "ステータス", request: "sort_status", sortBy: 0 },
        { id: 1, name: "名前", request: "sort_name", sortBy: 0 },
        { id: 2, name: "メールアドレス", request: "sort_email", sortBy: 0 },
        { id: 3, name: "登録者", request: "sort_reguid", sortBy: 0 },
        {
          id: 4,
          name: "ワークショップ日程",
          request: "sort_date_workshop",
          sortBy: 0,
        },
        {
          id: 5,
          name: "インベントリー",
          request: "sort_assessment",
          sortBy: 0,
        },
        
        { id: 6, name: "ログイン最終日時", request: "sort_date", sortBy: 0 },
        { id: 7, name: "登録メール再送日時" },
        { id: 8, name: "備考" },
      ],
      data: [],
      checkedStatusButton: 0,
      isCheckedAll: false,
      listUserChecked: [],
      numberUserCheckedAtPage: 0,
      company_uuid: "",
      ws_company_uuid: "",
      sortByColumn: "",
      searchText: "",
      userSearchText: "",
      userSearchTextFlag: false,
      totalUsers: 0,
      visibleModal: false,
    };
  }
  onClickAddUserChecked = (data) => {
    let listUserChecked = this.state.listUserChecked;
    const existingUser = this.state.listUserChecked.find(
      (userChecked) => userChecked.id === data.id
    );

    if (existingUser) {
      listUserChecked = this.state.listUserChecked.filter(
        (userChecked) => userChecked.id !== data.id
      );
      this.setState(
        {
          numberUserCheckedAtPage: this.state.numberUserCheckedAtPage - 1,
        },
        () => {
          if (
            this.state.numberUserCheckedAtPage <
            this.props.listSuperUser.results.length
          ) {
            this.setState({ isCheckedAll: false });
          }
        }
      );
    } else {
      ///Truong hop check kiem tra neu du nguoi bat check all
      this.setState(
        {
          numberUserCheckedAtPage: this.state.numberUserCheckedAtPage + 1,
        },
        () => {
          if (
            this.state.numberUserCheckedAtPage ===
            this.props.listSuperUser.results.length
          ) {
            this.setState({ isCheckedAll: true });
          }
        }
      );
      listUserChecked = [...this.state.listUserChecked, data];
    }
    
    this.setState({ listUserChecked: listUserChecked }, () => {
      this.isCheckedUserStatusShowButton();
      if (
        this.state.numberUserCheckedAtPage ===
        this.props.listSuperUser.results.length
      ) {
        this.setState({ isCheckedAll: true });
      }
    });
  };
  isDefaultChecked = (data) => {
    let existingUser = this.state.listUserChecked.find(
      (userChecked) => userChecked.id === data.id
    );
    if (existingUser) {
      return true;
    } else return false;
  };
  addOneUserToArray = (data, listCheckedUser) => {
    let existingUser = listCheckedUser.find(
      (userChecked) => userChecked.id === data.id
    );

    if (existingUser) {
      return "";
    } else {
      return data;
    }
  };
  isCheckedUserStatusShowButton = () => {
    let statusCheckedShowButton = 0;

    let existingUserActive = this.state.listUserChecked.find(
      (userChecked) => userChecked.is_active === true
    );

    let existingUserInActive = this.state.listUserChecked.find(
      (userChecked) => userChecked.is_active === false
    );

    if (existingUserActive) {
      statusCheckedShowButton = 1;
    }
    if (existingUserInActive) {
      statusCheckedShowButton = 2;
    }
    if (existingUserActive && existingUserInActive) {
      statusCheckedShowButton = 3;
    }

    let checkedInventoryValue = 0;
    let inventoryFalse = this.state.listUserChecked.find(
      (userChecked) => userChecked.assessment === false
    );
    let inventoryTrue = this.state.listUserChecked.find(
      (userChecked) => userChecked.assessment === true
    );
    if (inventoryFalse) {
      checkedInventoryValue = 1;
    }
    if (inventoryTrue) {
      checkedInventoryValue = -1;
    }

    this.setState({
      checkedStatusButton: statusCheckedShowButton,
      checkedInventory: checkedInventoryValue,
    });
  };
  onCheckAllTable = () => {
    let listUserChecked = this.state.listUserChecked;
    let listUserAtPage = this.props.listSuperUser.results;
    listUserAtPage.forEach((data) => {
      let checkUser = this.addOneUserToArray(data, listUserChecked);
      if (checkUser === "") {
        if (this.state.isCheckedAll === true) {
          listUserChecked = listUserChecked.filter(
            (userChecked) => userChecked.id !== data.id
          );
          this.setState({
            numberUserCheckedAtPage: 0,
          });
        }
      } else {
        if (this.state.isCheckedAll === false) {
          listUserChecked = [...listUserChecked, data];
          this.setState({
            numberUserCheckedAtPage: listUserAtPage.length,
          });
        }
      }
    });
    this.setState(
      {
        listUserChecked: listUserChecked,
        isCheckedAll: !this.state.isCheckedAll,
      },
      () => this.isCheckedUserStatusShowButton()
    );
  };
  componentDidMount() {
    if (getAccessTokenAdmin() !== "") {
      this.props.dispatch(loginActions.checkLoginAdmin(getAccessTokenAdmin()));
      this.props.dispatch(
        loginActions.checkLoginRoleSupperAdmin(getAccessTokenAdmin())
      );

    }

    window.scrollTo(0, 0);

    this.props.dispatch(userAdminActions.getListSuperUserAction());
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.listSuperUser !== this.props.listSuperUser) {
      this.setState({
        totalUsers: this.props.listSuperUser.count,
      });
      if (this.props.listSuperUser.count > 0) {
        this.setState({
          ws_company_uuid: this.props.listSuperUser.results[0].ws_company_uuid,
        });
      }
    }
  };

  requestlistSuperUserSearch = () => {
    const { sortByColumn, searchText } = this.state;
    this.setState({ userSearchTextFlag: false, activePage: 1 });

    this.props.dispatch(
      userAdminActions.getSuperUserSearchAction(0, sortByColumn, searchText)
    );
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmitSearch = (event) => {
    event.preventDefault();
    this.setState({ activePage: 1 }, () => {
      this.requestlistSuperUserSearch();
    });
  };
  onClickDeleteUser = () => {
    const listSuperUser = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.ws_company_uuid}/super-admin-confirm-completed/2`,
      state: {
        users: listSuperUser,
        company: this.state.ws_company_uuid,
      },
    });
  };
  onClickDeactiveUser = () => {
    const list_users = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.ws_company_uuid}/super-admin-confirm-completed/1`,
      state: { users: list_users },
    });
  };

  onClickActiveUser = () => {
    const list_users = [...this.state.listUserChecked];
    this.props.history.push({
      pathname: `/admin/${this.state.ws_company_uuid}/super-admin-confirm-completed/0`,
      state: { users: list_users },
    });
  };

  onClickSendMailInviteUser = () => {
    let list_users = this.state.listUserChecked.map((user) => {
      return {
        user_id: user.id,
        workshop_code: user.code_workshop,
      };
    });
    this.props.dispatch(
      userAdminActions.sendMailInviteSuperUserAccountAction(list_users,this.props.match.params.companyUUID)
    );
    this.closeModal();
  };

  openModal = (event) => {
    this.setState({
      visibleModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      visibleModal: false,
      visibleModalDeleteCompany: false,
      isDeleteCompany:false,
    });
  };

  onClickSortActive = (event) => {
    let id_column = parseInt(event.target.getAttribute("id"));
    let array_column_name = this.state.listColumnTableUsers;

    let sortByColumn = array_column_name[id_column].request;
    if (array_column_name[id_column].sortBy < 2)
      array_column_name[id_column].sortBy++;
    else array_column_name[id_column].sortBy = 0;

    array_column_name.forEach((column_name) => {
      if (column_name.id !== id_column) column_name.sortBy = 0;
    });
    if (array_column_name[id_column].sortBy === 1) {
      sortByColumn += "=0";
    }
    if (array_column_name[id_column].sortBy === 2) {
      sortByColumn += "=1";
    }
    if (array_column_name[id_column].sortBy === 0) {
      sortByColumn = "";
    }
    this.setState(
      {
        listColumnTableCompany: array_column_name,
        sortByColumn: sortByColumn,
      },
      () => this.requestlistSuperUserSearch()
    );
  };
  handlePageChange(pageNumber) {
    if (this.state.userSearchTextFlag) {
      if (pageNumber !== this.state.activeUserPage) {
        this.setState({ activeUserPage: pageNumber }, () => {
          this.props.dispatch(
            userAdminActions.getSuperUserSearchAction(
              this.state.activeUserPage - 1,
              this.state.userSearchText
            )
          );
        });
      }
    } else {
      if (pageNumber !== this.state.pageNumber) {
        this.setState({ activePage: pageNumber }, () => {
          this.props.dispatch(
            userAdminActions.getSuperUserSearchAction(
              this.state.activePage - 1,
              this.state.sortByColumn,
              this.state.searchText
            )
          );
        });
      }
    }
  }
  onScrollTable = () => {
    const $ = require("jquery");
    $(".box-theadtable").scrollLeft($(".box-tbodytable").scrollLeft());
    var scroll_left =
      $(".box-tbodytable").get(0).scrollWidth -
      $(".box-tbodytable").get(0).clientWidth;

    $(".box-tbodytable").scroll(function () {
      if (scroll_left === $(this).scrollLeft()) {
        $(".shadow-table").removeClass("js-shadow-table");
      } else {
        $(".shadow-table").addClass("js-shadow-table");
      }
    });
  };
  

  render() {
    const { isCheckedAll, searchText } = this.state;
    const { listSuperUser } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies enterprise-details">
          <HeaderAdmin isCoachaAdmin="true"></HeaderAdmin>
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item">スーパーアドミン一覧</span>
                  <p
                    style={{
                      display: "inline",
                      marginLeft: "25px",
                      color: "#333333",
                      fontSize: "20px",
                    }}
                  >
                    ※
                  </p>
                </li>
              </ul>
              <Link
                to={`/admin/super-admin-registration/${this.state.ws_company_uuid}`}
                className="super-admin-bnt-submit-cm bg-4D4150"
              >
                スーパーアドミンを追加する
              </Link>
              <p
                style={{
                  display: "inline",
                  marginLeft: "25px",
                  color: "#333333",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "10px",
                }}
              >
                ※
              </p>
            </div>
            <div className="content-140">
              <div className="wrap-table">
                <div className="form-table clearfix">
                  <div className="wrapform">
                    <div className="wrapbtn">
                      <div className="search-container">
                        <form onSubmit={this.handleSubmitSearch}>
                          <input
                            type="text"
                            placeholder="メールアドレス"
                            className="txt-search"
                            name="searchText"
                            value={searchText}
                            onChange={this.handleChange}
                          />
                          <button
                            type="button"
                            className="search"
                            onClick={this.handleSubmitSearch}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-wrap-content ">
                  <div className="box-theadtable">
                    <div className=" super-table-scroll ">
                    <table
                        id="super-table-width"
                        className="table table-striped "
                      >
                        <thead>
                          <tr>
                            <th className="th-sm hiden-sort super-admin-table-td-width ">
                              <label className="container-checkbox">
                                <input
                                  type="checkbox"
                                  name="checkAll"
                                  checked={isCheckedAll}
                                  onChange={this.onCheckAllTable}
                                />
                                <span className="checkmark" />
                              </label>
                            </th>

                            {this.state.listColumnTableUsers.map((column) => (
                              <th
                                key={column.id}
                                className={`super-admin-colum-width ${
                                  column.sortBy === 1 ? "active-th-dow" : ""
                                } ${column.sortBy === 2 ? "active-th-up" : ""}
                                        `}
                                id={column.id}
                                onClick={this.onClickSortActive}
                              >
                                {column.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {listSuperUser.results
                            ? Object.keys(listSuperUser.results).map(
                                (item, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      this.isDefaultChecked(
                                        listSuperUser.results
                                      ) === true
                                        ? ""
                                        : ""
                                    }`}
                                  >
                                    {}
                                    <td className="super-admin-table-td-width">
                                      <label className="container-checkbox">
                                        <input
                                          name={`checkbox${listSuperUser.results[item].id}`}
                                          type="checkbox"
                                          checked={this.isDefaultChecked(
                                            listSuperUser.results[i]
                                          )}
                                          onChange={() =>
                                            this.onClickAddUserChecked(
                                              listSuperUser.results[i]
                                            )
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </td>
                                    <td className="super-admin-table-td-width-status">
                                      {listSuperUser.results[item].is_active ===
                                      true ? (
                                        <div className="text-action-on">
                                          有効
                                        </div>
                                      ) : (
                                        <div className="text-action-off">
                                          停止
                                        </div>
                                      )}
                                    </td>
                                    <td className="super-admin-table-td-width-name">
                                      <span className="text-line color-689B88">
                                        <Link
                                          to={`/admin/${listSuperUser.results[item].company_uuid}/super-admin-registration/${listSuperUser.results[item].uuid}`}
                                        >
                                          <span className="text-line color-689B88 cursor-pointer">
                                            {
                                              listSuperUser.results[item]
                                                .full_name
                                            }
                                          </span>
                                        </Link>
                                      </span>
                                    </td>
                                    <td className="super-admin-table-td-width-email">
                                      {listSuperUser.results[item].email}
                                    </td>
                                    <td>{listSuperUser.results[item].registered_user}</td>
                                    <td>
                                      <span
                                        className="text-rectang color-white"
                                        style={{
                                          backgroundColor: listSuperUser.results[item].workshop_color
                                        }}
                                      >
                                        {listSuperUser.results[item].code_workshop}
                                      </span>
                                      <span className="text-date">
                                        {listSuperUser.results[item].date_workshop}
                                      </span>
                                    </td>
                                    <td>
                                      <AssessmentLink user={listSuperUser.results[item]} />
                                    </td>
                                    <td className="super-admin-table-td-width-date">
                                      {listSuperUser.results[item].last_login}
                                    </td>
                                    <td className="super-admin-table-td-width-resendemail">
                                    {listSuperUser.results[item].registration_mail_send_time ? listSuperUser.results[item].registration_mail_send_time.map((registration_mail_date) => 
                                    <span>{registration_mail_date}<br/></span>):""}
                                    </td>
                                    <td className="super-admin-table-td-width-email">
                                    {listSuperUser.results[item].remarks}
                                    </td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </table>
                </div>
                    <div
                      className="box-tbodytable"
                      onScroll={this.onScrollTable}
                    ></div>
                    <div className="shadow-table js-shadow-table"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-comtainer-custom"></div>
          </div>
        </div>
        {this.state.checkedStatusButton > 0 ? (
          <div className="footer-btn footer-btn-fix-phase2">
            <div className="text-center">
            {this.state.checkedStatusButton === 1 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-send-mail margin-lr-15"
                  onClick={this.openModal}
                >
                  登録メールを再送
                </button>
              ) : (
                  ""
                )}

              {this.state.checkedStatusButton === 2 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-send-mail margin-lr-15"
                  onClick={this.onClickActiveUser}
                >
                  ステータスを有効にする
                </button>
              ) : (
                ""
              )}
              {this.state.checkedStatusButton === 1 ? (
                <button
                  className="bnt-submit-link-footer bnt-submit-link-status margin-lr-15"
                  onClick={this.onClickDeactiveUser}
                >
                  ステータスを無効にする
                </button>
              ) : (
                ""
              )}
              <button
                className="bnt-submit-link-footer bnt-submit-link-delete margin-lr-15"
                onClick={this.onClickDeleteUser}
              >
                ユーザーを削除
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal
          visible={this.state.visibleModal}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          width="500"
          height="250"
        >
          <div className="modal-body modal-body-phase2">
            <p className="text-content modal-mes-phase2-fix">
              チェックしたユーザー （{this.state.numberUserCheckedAtPage}人） に
              <br />
              登録確認メールを再送しますか？
            </p>
            <div className="gr-btn-modal modal-mes-phase2-fix">
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mt-10 mr-20"
                onClick={this.onClickSendMailInviteUser}
              >
                送信する
              </button>
              <button
                type="button"
                className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status mt-10"
                onClick={() => this.closeModal()}
              >
                キャンセル
              </button>
            </div>
          </div>
        </Modal>
        <div className="pagination-comtainer-custom">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            activePage={
              this.state.userSearchTextFlag
                ? this.state.activeUserPage
                : this.state.activePage
            }
            itemsCountPerPage={20}
            totalItemsCount={this.state.totalUsers}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>

        <FooterAdmin></FooterAdmin>
      </div>
    );
  }
}
function AssessmentLink (props) {
  const user = props.user;
  if (!user.assessment) {
    return (
      <span className="font-weight-bold color-B25C60">
        未回答
      </span>
    );
  }
  return (
    <a
      className="font-weight-bold color-4d4150"
      href="#/"
      onClick={(event) => {
        event.preventDefault();
        window.open(`/admin/consequence/${user.uuid}`, '_blank');
      }}
    >
      回答済
    </a>
  );
  }

function mapStateToProps(state) {
  const { listSuperUser } = state.userAdminReducer;
  return {
    listSuperUser,
  };
}

const connectedSuperAdminManagement =
  connect(mapStateToProps)(SuperAdminManagement);
export { connectedSuperAdminManagement as SuperAdminManagement };
