import { adminConstants, userConstants } from "../_constants";
import { adminService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { userMessages } from "../_messages";

export const adminActions = {
  login,
  logout,
  getUser,
  importFile,
  exportFile,
  createUser,
  pagination,
  nextAndPrevious,
  getDepartment,
  getWorskhops,
  deleteUserAction,
  sendMailInviteUserAction,
  resetIsDeleteUsersProcessingAction,
  sortUserByAction,
  createWorkShopAction,
  checkLoginAdmin,
  forgotPasswordAdminAction
};

function checkLoginAdmin(token) {
  return dispatch => {
    dispatch(request());
    adminService
      .checkLogin(token)
      .then(user => user.data)
      .then(user => {
        dispatch(success());
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });

    function request() {
      return { type: userConstants.CHECK_LOGIN };
    }

    function success() {
      return { type: userConstants.CHECK_LOGIN_SUCCESS };
    }
  };
}

function login(username, password) {
  return dispatch => {
    dispatch(
      request({
        username
      })
    );

    adminService
      .login(username, password)
      .then(admin => admin.data)
      .then(admin => {
        if (admin.code !== 200) {
          dispatch(failure(admin));
          dispatch(alertActions.error(admin.data.message));
        } else {
          dispatch(success(admin));
          history.push("/admin");
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request(admin) {
    return {
      type: adminConstants.LOGIN_ADMIN_REQUEST,
      admin
    };
  }

  function success(admin) {
    return {
      type: adminConstants.LOGIN_ADMIN_SUCCESS,
      admin
    };
  }

  function failure(error) {
    return {
      type: adminConstants.LOGIN_ADMIN_FAILURE,
      error
    };
  }
}

function logout() {
  adminService.logout();
  return {
    type: adminConstants.LOGOUT_ADMIN
  };
}

function getUser() {
  return dispatch => {
    adminService
      .getUser()
      .then(user => {
        dispatch(success(user));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function success(user) {
  return {
    type: "GET_USER_REQUEST",
    listUser: user
  };
}
function exportFile(){
  return dispatch => {
    adminService.exportFile().then(() => {
      dispatch(alertActions.success(userMessages.EXPORT_SUCCESS));
    }).catch(error => {
      dispatch(alertActions.error(error.data));
    });
  };
}

function importFile(file, workshop_id) {
  return dispatch => {
    dispatch(alertActions.waiting(userMessages.IMPORT_WAITING));
    adminService
      .importFile(file, workshop_id)
      .then(user => {
        dispatch(alertActions.success(userMessages.IMPORT_SUCCESS));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function createUser(user) {
  return dispatch => {
    dispatch(alertActions.success(userMessages.PROCESSING));
    adminService
      .createUser(user)
      .then(user => {
        if (user.status === 400) {
          dispatch(alertActions.error(user.data.email));
        } else {
          dispatch(alertActions.success(userMessages.CREATE_SUCCESS));
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function pagination(offset) {
  return dispatch => {
    adminService
      .pagination(offset)
      .then(user => {
        dispatch(success(user));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function nextAndPrevious(urlNextAndPrevious) {
  return dispatch => {
    adminService
      .nextAndPrevious(urlNextAndPrevious)
      .then(user => {
        dispatch(success(user));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

function getDepartment() {
  return dispatch => {
    adminService
      .getDepartment()
      .then(list => {
        if (list.code !== 200) {
          dispatch(alertActions.error(list.data.message));
        } else {
          dispatch(success(list.data));
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(list) {
    return {
      type: "GET_LIST_DEPARTMENT",
      listDepartment: list
    };
  }
}

function getWorskhops(user_id) {
  return dispatch => {
    adminService
      .getWorkshops(user_id)
      .then(list => {
        if (list.code !== 200) {
          dispatch(alertActions.error(list.data.message));
        } else {
          dispatch(success(list.data));
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(data) {
    return {
      type: "WORKSHOP_GET_ALL_REQUEST",
      workshops: data
    };
  }
}

function deleteUserAction(listUsersDelete, pageCurrent) {
  return dispatch => {
    // dispatch(request());
    dispatch(alertActions.waiting(userMessages.PROCESSING));
    adminService.deleteUserAccount(listUsersDelete).then(response => {
      if (response.status !== 200) {
        dispatch(failure());
        dispatch(alertActions.error(response));
      } else {
        dispatch(success());
        dispatch(alertActions.success(userMessages.COMPLETED_DELETE_USERS));
        // dispatch(getUser());
        dispatch(adminActions.pagination(pageCurrent * 10 - 10));
      }
    });
  };

  function success() {
    return {
      type: adminConstants.ADMIN_DELETE_USER_REQUEST,
      isDeleteUsersProcessing: true
    };
  }

  function failure() {
    return {
      type: adminConstants.ADMIN_DELETE_USER_REQUEST,
      isDeleteUsersProcessing: false
    };
  }
}

function resetIsDeleteUsersProcessingAction() {
  return dispatch => {
    dispatch(reset());
  };

  function reset() {
    return {
      type: adminConstants.ADMIN_DELETE_USER_REQUEST,
      isDeleteUsersProcessing: false
    };
  }
}

function sendMailInviteUserAction(listUsersDelete) {
  return dispatch => {
    // dispatch(request());
    dispatch(alertActions.waiting(userMessages.PROCESSING));
    adminService.sendMailInviteUserAccount(listUsersDelete).then(response => {
      if (response.status !== 200) {
        dispatch(failure());
        dispatch(alertActions.error(response));
      } else {
        dispatch(success());
        dispatch(alertActions.success(userMessages.COMPLETED_SEND_EMAIL));
      }
    });
  };

  function success() {
    return {
      type: adminConstants.ADMIN_SEND_MAIL_INVITE_USER_REQUEST,
      isSendMailUsersProcessing: true
    };
  }

  function failure() {
    return {
      type: adminConstants.ADMIN_SEND_MAIL_INVITE_USER_REQUEST,
      isSendMailUsersProcessing: false
    };
  }
}

////////// Action sort user by
function sortUserByAction(offset, sortMail, sortDep) {
  return dispatch => {
    adminService
      .sortUserByService(offset, sortMail, sortDep)
      .then(user => {
        dispatch(success(user));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

//// Active create workshop
function createWorkShopAction(workshop) {
  return dispatch => {
    // dispatch(request());
    adminService.createWorkShopService(workshop).then(response => {
      if (response.status !== 200) {
        dispatch(failure());
        dispatch(alertActions.error(response));
      } else {
        dispatch(success());
        dispatch(alertActions.success("Completed"));
      }
    });
  };

  function success() {
    return {
      type: adminConstants.ADMIN_SEND_MAIL_INVITE_USER_REQUEST,
      isSendMailUsersProcessing: true
    };
  }

  function failure() {
    return {
      type: adminConstants.ADMIN_SEND_MAIL_INVITE_USER_REQUEST,
      isSendMailUsersProcessing: false
    };
  }
}
////Admin forgot password action
function forgotPasswordAdminAction(email) {
  return dispatch => {
    dispatch(request());
    adminService
      .forgotPasswordAdminService(email)
      .then(data => data.data)
      .then(data => {
        if (data.code !== 200) {
          dispatch(failure(data));
          dispatch(alertActions.error(data.data.message));
        } else {
          dispatch(success());
          dispatch(alertActions.success(userMessages.FORGOT_PASSWORD_MESSAGES));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}
