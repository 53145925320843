import React from "react";
import { connect } from "react-redux";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import {userAdminActions} from "../_actions/";
import ErrorIcon from "../../../assets/styles/img/error.png";

class AdminConfirmSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listTextComplete: [
        {
          id: 0,
          name: "active",
          title: "ユーザー有効化の確認",
          contentList: [
            "下記のユーザーのアカウントを有効化します。",
            "ユーザーが初回パスワード未設定の場合、「登録メールを再送」ください。",
            "無効化の際にメルマガ配信設定もOFFになっています。必要に応じて再度ONに変更ください。",
          ],
          tableHeading: "有効化対象ユーザー",
          mainButtonText: "有効化する",
          buttonText: "キャンセル"
        },
        {
          id: 1,
          name: "deactive",
          title: "ユーザー無効化の確認",
          content: "対象ユーザーの無効化が完了しました。",
          contentList: [
            "下記のユーザーのアカウントを無効化します。メールマガジン配信設定もOFFに変更します。",
            "無効化されたユーザーはサイトにログインできなくなります。",
            "ユーザー情報は削除されないので、再度有効化することも可能です。",
            "その際は、必要に応じてメールマガジン配信設定を再度ONに変更ください。",
          ],
          tableHeading:"無効化対象ユーザー",
          mainButtonText:"無効化する",
          buttonText:"キャンセル"
        },
        {
          id: 2,
          name: "delete",
          title: "ユーザー削除の確認",
          content: "対象ユーザーの削除が完了しました。",
          contentList: [
            "下記のユーザーのアカウントを削除します。",
            "削除したユーザーのアカウントや登録情報、インベントリー回答結果を 復元することはできません。ご注意ください。",
          ],
          tableHeading:"削除対象ユーザー",
          mainButtonText:"削除する",
          buttonText:"キャンセル"
        }
      ]
    };
    this.onClickMainButton = this.onClickMainButton.bind(this);
  } //idActiveDeactiveDelete

  onClickMainButton = () => {

    const idActiveDeactiveDelete = this.props.match.params.idActiveDeactiveDelete;
    const company = this.props.location.state.company;
    const list_users = this.props.location.state.users.map((user) => user.id);
    switch (idActiveDeactiveDelete) {
      case '0':
        this.props.dispatch(userAdminActions.activeUserAction(list_users, company));
        break;
      case '1':
        this.props.dispatch(userAdminActions.deactiveUserAction(list_users, company));
        break;
      case '2':
        this.props.dispatch(userAdminActions.deleteUserAction(list_users, company));
        break;
      default:
    }
    
  };
  createMarkup = (message) => {
    return {
      __html: message,
    };
  };
  render() {

    const { listTextComplete } = this.state;
    const idActiveDeactiveDelete = this.props.match.params.idActiveDeactiveDelete;
    if (['0', '1', '2'].indexOf(idActiveDeactiveDelete) < 0) {
      // invalid page id
      return null;
    }
    const isDelete = idActiveDeactiveDelete === '2';
    const { alert } = this.props;

    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies confirmation-of-user-registration completion-user-deletion">
          <HeaderAdmin></HeaderAdmin>
          <div className="content content-fix-phase2 phase2-content-min-height">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {listTextComplete[idActiveDeactiveDelete].title}
                  </span>
                </li>
              </ul>
              
            </div>
            <div className="content-930 mt-40">
              <div className="form-box form-box-place mb-3">
                {listTextComplete[idActiveDeactiveDelete].contentList.map((content, index) => (
                  <p key={index} className="detail">
                    {content}
                  </p>
                ))}
                <p className="admin-confirm-success-table-head">
                  {listTextComplete[idActiveDeactiveDelete].tableHeading}
                </p>
                <table className="table table-striped table-sm admin-confirm-success-table-body">
                  <tbody>
                    {this.props.location.state.users.map((user) => (
                      <tr key={user.id}>
                        <td>{user.last_name + " " + user.first_name}</td>
                        <td>{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="btn-login text-left mt-20 admin-confirm">
                  <button
                    className={isDelete ? "bnt-submit-link-footer mr-15 bg-color-b25c60" : "bnt-submit-link-footer mr-15 bg-color-4D4150"}
                    onClick={this.onClickMainButton}
                  >
                    {listTextComplete[idActiveDeactiveDelete].mainButtonText}
                  </button>
                  <a
                    href={`/admin/detail-company/${this.props.match.params.companyUUID}`}
                    style={{color:"#4d4150",width:'10rem',fontSize:"14px"}}
                    className="bnt-submit-link-footer  bnt-submit-fix"
                  >
                    {listTextComplete[idActiveDeactiveDelete].buttonText}
                  </a>
                   
                </div>
                {typeof alert.message !== "undefined" ? ( 
                <div className="btn-login text-left mt-20 admin-confirm">
                {alert && (
                    <div className="danger-cm">
                      <img src={ErrorIcon} alt="" />
                      <span className="error-detail"
                      dangerouslySetInnerHTML={this.createMarkup(alert.message)}
                      ></span>
                    </div>
                  )}
                </div>
                ):(
                ""
                )}
              </div>
            </div>
          </div>
          <FooterAdmin></FooterAdmin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const connectedAdminConfirmSuccess = connect(mapStateToProps)(AdminConfirmSuccess);
export { connectedAdminConfirmSuccess as AdminConfirmSuccess };
