import { workshopConstants } from "../_constants";
import { workshopService } from "../_services";
import { alertActions } from "./";

import { userMessages } from "../_messages";


export const workshopActions = { createWorkShopAction };

//// Active create workshop
function createWorkShopAction(workshop) {
  return dispatch => {
    dispatch(alertActions.waiting(userMessages.PROCESSING));
    workshopService.createWorkShopService(workshop).then(response => {
      if (response.status !== 200) {
        dispatch(alertActions.error(response.data.date_workshop));
      } else {
        dispatch(success(response.data.data));
        dispatch(alertActions.success(userMessages.COMPLETED_CREATE_WORKSHOP));
      }
    });
  };
  function success(data) {
    return {
      type: workshopConstants.WORKSHOP_CREATE_WORKSHOP,
      workshops: data
    };
  }
}
