import { userAdminConstants } from "../_constants";
import { userAdminServices } from "../_services";
import { alertActions } from "./";
import { history } from "../../_helpers";
// import { loginMessages } from "../_messages";


export const userAdminActions = {
  getListUserAction,
  getActiveAccountCompanyAction,
  activeUserAction,
  deactiveUserAction,
  deleteUserAction,
  sendMailInviteUserAccountAction,
  sendAssessmentMailAction,
  getCompanyDetailInfoAction,
  createUserAction,
  importCSVUserAction,
  getUserInfoAction,
  updateUserAction,
  downloadCSVAction,
  getListSuperUserAction,
  createSuperAdminAction,
  updateSuperAdminAction,
  activeSuperAdminAction,
  deactiveSuperAdminAction,
  deleteSuperAdminAction,
  getSuperUserSearchAction,
  sendMailInviteSuperUserAccountAction
};
function getListUserAction(
  companyUUID,
  offset,
  search,
  is_active,
  assessment,
  workshop,
  sort_column,
  value_sort_column
) {
  return dispatch => {
    userAdminServices
      .getListUserService(
        companyUUID,
        offset,
        search,
        is_active,
        assessment,
        workshop,
        sort_column,
        value_sort_column
      )
      .then(listUsers => {
        if (listUsers.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success(listUsers));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(listUsers) {
    return {
      type: userAdminConstants.GET_LIST_USER_ADMIN_REQUEST,
      listUsers: listUsers
    };
  }
}

///// Super user search list 
function getSuperUserSearchAction(offset,sort_column,search_text)
{
  return dispatch => {
    userAdminServices
      .getSuperUserSearchService(offset,sort_column,search_text)
      .then(listSuperUser => {
        dispatch(success(listSuperUser));
        if (listSuperUser.status === 403) {
          history.push("/admin/login");
        }
      })
      .catch(error => {
       
        dispatch(alertActions.error(error.data));
      });
  };

  function success(listSuperUser) {
    return {
      type: userAdminConstants.GET_LIST_SUPER_USER__REQUEST,
      listSuperUser: listSuperUser
    };
  }
 

}
////Get Super user list
function getListSuperUserAction(){
  return dispatch => {
    userAdminServices
    .getListSuperUserService()
    .then(listSuperUser => {
      
      dispatch(success(listSuperUser))
    })
    .catch(error => {
      dispatch(alertActions.error(error.data));
    });
  };

  function success(listSuperUser) {
    return {
      type: userAdminConstants.GET_LIST_SUPER_USER__REQUEST,
      listSuperUser: listSuperUser
    };
  }
}

function getActiveAccountCompanyAction(companyUUID) {
  return dispatch => {
    userAdminServices
      .getActiveAccountCompanyService(companyUUID)
      .then(countPercent => {
        dispatch(success(countPercent.data));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(activePercent) {
    return {
      type: userAdminConstants.GET_ACTIVE_ACCOUNT_COMPANY_REQUEST,
      activePercent: activePercent
    };
  }
}
///// Active account action
function activeUserAction(list_users, company_uuid) {
  return dispatch => {
    userAdminServices
      .activeUserService(list_users)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/detail-company/"+ company_uuid);
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Deactive account action
function deactiveUserAction(list_users, company_uuid) {
  return dispatch => {
    userAdminServices
      .deactiveUserService(list_users)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/detail-company/"+ company_uuid );
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Delete account action
function deleteUserAction(list_users, company_uuid) {
 return dispatch => {
    userAdminServices
      .deleteUserService(list_users, company_uuid)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/detail-company/"+ company_uuid );
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

///// Send assessment mail action
function sendAssessmentMailAction(list_users, company_uuid) {
  return dispatch => {
    userAdminServices
      .sendAssessmentMailService(list_users)
      .then(res => {
        if (res.code === 200){
          history.push({
            pathname: `/admin/${company_uuid}/assessment-reminder-completed`,
            state: { total_users: res.data.total_users,company_uuid:company_uuid}
          })
        } 
        if (res.status === 500) {
          history.push({
            pathname: `/admin/${company_uuid}/assessment-reminder-completed`,
            state: { 
              total_users: res.data.data.total_users,
              failed_users: res.data.data.failed_users,
              company_uuid: company_uuid
            }
          })
        }
      })
  };
}

///// Send mail invite account action
function sendMailInviteUserAccountAction(list_users,company) {
  return dispatch => {
    userAdminServices
      .sendMailInviteUserAccountService(list_users)
      .then(res => {
        if (res.status !== 200) {
          if(res.status === 500){
            history.push({pathname:`/admin/resend-registration-mail-success/0`,state: {company: company ,users:res.data.data.users,count:res.data.data.success_count,failed_count:res.data.data.failed_count}})
          }
          else{
            dispatch(alertActions.error(res));
          }  
        }
        else{
          history.push({pathname: `/admin/resend-registration-mail-success/1`,
          state: { count: list_users.length, company: company }}) 
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Active account action
function getCompanyDetailInfoAction(companyUUID) {
  return dispatch => {
    userAdminServices
      .getCompanyDetailInfoService(companyUUID)
      .then(companyInfo => {
        dispatch(success(companyInfo));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(companyInfo) {
    return {
      type: userAdminConstants.GET_COMPANY_DETAIL_INFO_REQUEST,
      company: companyInfo
    };
  }
}
///// Create user action
function createUserAction(user, company_uuid) {
  return dispatch => {
    userAdminServices
      .createUserService(user)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res.data.detail, res.data.name));
        } else {
          history.push("/admin/detail-company/" + company_uuid + "/?return=true");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Update user action
function updateUserAction(user, user_uuid, company_uuid) {
  return dispatch => {
    userAdminServices
      .updateUserService(user, user_uuid)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res.data.detail));
        } else {
          history.push("/admin/detail-company/" + company_uuid);
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Create user action
function importCSVUserAction(file, company_id, company_uuid) {
  return dispatch => {
    dispatch(alertActions.waiting("プロセッシング..."));
    userAdminServices
      .importCSVUserService(file, company_id)
      .then(res => {
        if (res.code !== 200) {
          dispatch(alertActions.error(res.data.detail));
          if (res.code == 1001) {
            dispatch(alertActions.success(res.message));
            dispatch(warning(res.data));
          }
        } else {
          dispatch(alertActions.success(res.message));
          if (res.data.length !== 0){
            dispatch(warning(res.data)); 
            userAdminServices.downloadInvalidDataCSV(res.data.length, company_uuid); 
        }
      }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function warning(error_list) {
    return {
      type: userAdminConstants.CSV_UPLOAD_SUCCESS,
      error_list: error_list
    };
  }
}
///// Active account action
function getUserInfoAction(user_uuid) {
  return dispatch => {
    userAdminServices
      .getUserInfoService(user_uuid)
      .then(userInfo => {
        dispatch(success(userInfo));
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };

  function success(userInfo) {
    return {
      type: userAdminConstants.GET_USER_INFO_REQUEST,
      userInfo: userInfo
    };
  }
}

function downloadCSVAction(company_uuid, sort_column,status_filter,assessment_filter,workshop_filter) {
  return dispatch => {
    userAdminServices.downloadCSVService(company_uuid, sort_column,status_filter,assessment_filter,workshop_filter)
    .then(csvInfo => {
      if (csvInfo.status === 200){
        dispatch(success(csvInfo));
      } else{
        dispatch(alertActions.error(csvInfo.data.detail));
      }
    }).catch(error => {
      dispatch(alertActions.error(error.data));
    });
  };

  function success(csvInfo) {
    return {
      type: userAdminConstants.CSV_DOWNLOAD_SUCCESS,
      csvInfo: csvInfo
    };
  }
}

///// Update super admin action
function updateSuperAdminAction(user, user_uuid) {
  return dispatch => {
    userAdminServices
      .updateSuperAdminService(user, user_uuid)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res.data.detail));
        } else {
          history.push("/admin/super-admins");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}


///// Create super admin action
function createSuperAdminAction(user) {
  return dispatch => {
    userAdminServices
      .createSuperAdminService(user)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res.data.detail, res.data.name));
        } else {
          history.push("/admin/super-admins");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

///// Super Admin Active account action
function activeSuperAdminAction(list_users, company_uuid) {
  return dispatch => {
    userAdminServices
      .activeUserService(list_users)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/super-admins");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Deactive Super Admin account action
function deactiveSuperAdminAction(list_users, company_uuid) {
  return dispatch => {
    userAdminServices
      .deactiveUserService(list_users)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/super-admins");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
///// Delete Super Admin account action
function deleteSuperAdminAction(list_users) {
 return dispatch => {
    userAdminServices
      .deleteSuperAdminService(list_users)
      .then(res => {
        if (res.status !== 200) {
          dispatch(alertActions.error(res));
        } else {
          history.push("/admin/super-admins");
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}

///// Send mail invite account action
function sendMailInviteSuperUserAccountAction(list_users,company) {
  return dispatch => {
    userAdminServices
      .sendMailInviteUserAccountService(list_users)
      .then(res => {
        if (res.status !== 200) {
          if(res.status === 500){
            history.push({pathname:`/admin/super-admin-resend-registration-mail-success/0`,state: {users:res.data.data.users,count:res.data.data.success_count,failed_count:res.data.data.failed_count}})
          }
          else{
            dispatch(alertActions.error(res));
          }  
        }
        else{
          history.push({pathname: `/admin/super-admin-resend-registration-mail-success/1`,
          state: { count: list_users.length }}) 
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });
  };
}
