import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../../../../assets/styles/css/main-phase-2.css";

import { loginActions } from "../../_actions";
import { isStagingServer } from "../../../_helpers";

import Logo from "../../../../assets/styles/img/logo-white.png";
import Group3mb from "../../../../assets/styles/img/Group3-mb.png";
import ArrowDow from "../../../../assets/styles/img/Arrow-dow.png";
import IconHeader from "../../../../assets/styles/img/icon-header.png";
import UserIcon from "../../../../assets/styles/img/building-solid.svg"

const HeaderAdmin = (props) => {
  const { date, user, admin, logout,isCoachaAdmin } = props;
  let userInfo;
  let assessment_date;
  if (user) {
    if (user.user !== undefined) {
      userInfo = user.user.last_name + " " + user.user.first_name+"さん"
      assessment_date = date
    }
  }
  const classList = [
    'content-logo-mb-horizontal',
    'content-logo-mb-horizontal-style-fix',
    'style-fix-position',
  ];
  return (
    <div>
    <header className="header">
      <div className="header-content">
        <div className={classList.join(' ')}>
          <span className="consequence-header">
            <img className="logo-screen" src={Logo} alt="" />
            <img className="logo-print" src={Group3mb} alt="" />
            <p className="consequence-header-user">{userInfo}</p>
            <p className="consequence-header-date">回答日：{assessment_date}</p>
          </span>
          <ul className="list-text">
            <li className="list-text-item list-text-item-active">
              <span className="color-C8B7CC">管理画面</span>
            </li>
            {admin.data.is_supper_admin === true ? (
              <li className="list-text-item list-text-item-active">
                <a href="/admin/company">
                  <span className="color-white">企業一覧</span>
                </a>
               
              </li>
            ) : (
                ""
              )}
              {admin.data.is_supper_admin === true ? (
              <li className="list-text-item">
                <a href="/admin/super-admins">
                  <span className="color-white">スーパーアドミン一覧</span>
                </a>
                <p style={{ display: "inline", marginLeft: "15px", color: "#ffffff", fontWeight: "bold",fontSize:"18px"}}>※</p>                
              </li>
            ) : (
                ""
              )}
          </ul>
          <a
            href="#navbarSupportedContent"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="setting-box">
              <img src={ArrowDow} alt="" />
            </span>
          </a>
          <div
            className="list-menu-mb mb-0 collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="list-menu-mb-ct">
              <li className = "list-menu-mb-item">
                <div className="list-menu-link">
                  <div className="email-user-info">
                    <span>{admin.data.email}</span>
                  </div>
                </div>
              </li>
              <li className="list-menu-mb-item">
                <Link className="list-menu-link" to="/admin/setting-password">
                  <div className="width-100-percent">パスワード変更</div>
                </Link>
              </li>
              <li className="list-menu-mb-item">
                <Link
                  to="/admin/login"
                  className="list-menu-link"
                  onClick={logout}
                >
                  <div className="width-100-percent">ログアウト</div>
                </Link>
              </li>
            </ul>
          </div>
          <ul className="clearfix list-menu-fix-phase2">
            <li className="list-menu-item">
              <a href="#/" className="link-list-menu d-block" onClick={event => event.preventDefault()}>
              {admin.data.is_supper_admin === true && isCoachaAdmin ? (
                <p style={{ display: "inline", color: "#ffffff", fontWeight: "bold",marginRight:"15px",fontSize:"15px"}}>※Coach A管理者のみ</p>
                  ) : ("")}
                <span className="img-gr img-gr-fix-phase2">
                {admin.data.is_supper_admin === true ? (
                  <img src={IconHeader} alt="" />):(<div className="user-admin-img-wrapper"><img className="user-admin-img" src={UserIcon} alt="" /></div>)}
                </span>
                <span className="title-menu-fix-phase2">
                  {admin
                    ? admin.data.user_last_name +
                    " " +
                    admin.data.user_first_name
                    : ""}
                </span>
              </a>
            </li>
          </ul>
        </div>
        {isStagingServer() ? (<div className="staging-header-marker"> Staging </div>):""}
      </div>
    </header>
    </div>
  );
};

function mapStateToProps(state) {
  const { authAdminReducer } = state;
  const { admin } = authAdminReducer;
  return { admin };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: function logout() {
      return dispatch(loginActions.logout());
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);