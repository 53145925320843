import { questionAdminConstants } from "../_constants";


const INITIAL_STATE = {
  analystInfo: []
};


export function questionAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case questionAdminConstants.GET_HISTORY_RESULT:
      return {
        ...state,
        analystInfo: action.analystInfo
      };
    default:
      return state;
  }
}
