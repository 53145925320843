import React from "react";

import { connect } from "react-redux";
import { loginActions } from "../_actions";
import { userActions } from "../../_actions";
import queryString from "query-string";
import { loginMessages } from "../_messages";
import { isStagingServer } from "../../_helpers";

import Loginpc from "../../../assets/styles/img/logologin.png";
import ErrorIcon from "../../../assets/styles/img/error.png";

class AdminForgotPasswordConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmed_password: "",
      token: "",
      submitted: false,
      isStaging:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({ token: values.token });
    this.props.dispatch(userActions.checkFirstTimeLoginForgotPassword(values.token));
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    } 
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { password, confirmed_password, token } = this.state;
    const { dispatch } = this.props;
    if (this.isValidatorPassword(password, confirmed_password) === true) {
      dispatch(loginActions.forgotPasswordConfirmAction(password, token));
    }
  }

  isValidatorPassword(password, confirmed_password) {
    let flag = false;
    if (!password) {
      this.props.alert.message = loginMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!confirmed_password) {
      this.props.alert.message = loginMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (confirmed_password !== password) {
      this.props.alert.message = loginMessages.PASSWORD_NOT_MATCH;
      this.props.alert.type = "alert-danger";
      return flag;
    }

    var strongRegex = new RegExp("[A-Za-z0-9@#$%^&+=]{8,}");
    const isOk = strongRegex.test(password);
    console.log(isOk);
    if (!isOk) {
      this.props.alert.message = loginMessages.PASSWORD_IS_WEAK;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  }

  render() {
    const { alert } = this.props;
    const { confirmed_password, password } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper login password-reissue">
        {(this.state.isStaging===true) && (
           <div
             style={{textAlign:"center",fontWeight:"bold",fontSize:"18px",backgroundColor:"rgb(178, 92, 96)",color:"#fff"}}
           >
	             <font>Staging</font>
           </div>
         )}
          <div className="login-content">
            <div className="logo-login">
              <a href="#/" className="link-logo-login" onClick={event => event.preventDefault()}>
                <img
                  src={Loginpc}
                  alt=""
                  className="icon-logo-login"
                />
              </a>
            </div>
            <div className="form-login">
              <div className="title-login">パスワード再設定</div>
              <div className="form-login-content form-login-content-fix-9-4-2020">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className="form-group d-block mb-22">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-form"
                    >
                    新しいパスワード（英字大文字・英字小文字・数字（・記号@#$%^&+=のみ）を含む8文字以上）
                    </label>
                    <div className="form-input">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group d-block form-group-mb">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-form"
                    >
                      パスワードをもう一度入力
                    </label>
                    <div className="form-input">
                      <input
                        name="confirmed_password"
                        type="password"
                        className="form-control"
                        id="confirmed_password"
                        value={confirmed_password}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  {alert.message && (
                    <div className="danger-cm">
                      <img src={ErrorIcon} alt="" />
                      <span className="error-detail"> 
                      { alert.name === 'forgot-password-expiry' ?
                        (<a href={'/admin/forgot-password'}> {alert.message} </a>) : (alert.message) 
                      }
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="btn-login text-center mt-24">
              <button
                className=" btn bnt-submit-form-phase2 bnt-submit-form1"
                onClick={this.handleSubmit}
              >
                パスワードを変更する
              </button>
            </div>
            <div className="footer-btn fix-position-footer-btn">
              <div className="wrap-button text-center">
                <p className="design-by text-center">
                  © COACH A Co., Ltd. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedAdminForgotPasswordConfirm = connect(mapStateToProps)(
  AdminForgotPasswordConfirm
);
export { connectedAdminForgotPasswordConfirm as AdminForgotPasswordConfirm };
