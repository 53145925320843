import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../../../assets/styles/css/main-phase-2.css";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { getAccessTokenAdmin } from "../../_helpers";
import { workshopAdminActions, userAdminActions, loginActions } from "../_actions";
import { alertActions } from "../../_actions";

import ErrorIcon from "../../../assets/styles/img/error.png";

import dateFnsFormat from "date-fns/format";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";
import moment from "moment";

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
class NewWorkshopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      date_workshop: "",
      company_id: "",
      remark: "",
      workshop_uuid: "",
      isUpdate: false,
      total_user: "",
      company_uuid:"",
      is_login_status:""
    };
  }
  componentDidMount = () => {
    this.setState({
      company_uuid: this.props.match.params.companyUUID
    });
    this.props.dispatch(
      loginActions.checkLoginRoleSupperAdmin(getAccessTokenAdmin())
    );
    if (
      this.props.match.params.companyUUID &&
      this.props.match.params.workshopUUID
    ) {
      this.setState({
        company_uuid: this.props.match.params.companyUUID,
        workshop_uuid: this.props.match.params.workshopUUID,
        isUpdate: true
      });
      this.props.dispatch(
        workshopAdminActions.getWorkshopDetailAction(
          this.props.match.params.workshopUUID,
          this.props.match.params.companyUUID
        )
      );
    }
    this.props.dispatch(
      userAdminActions.getCompanyDetailInfoAction(
        this.props.match.params.companyUUID
      )
    );
  };
  componentDidUpdate = prevProps => {
    if (prevProps.company !== this.props.company){
      this.setState({ company_id: this.props.company.id})
    }
    //trang thai updating
    if (prevProps.workshopInfo !== this.props.workshopInfo) {
      this.setState({
        date_workshop: this.props.workshopInfo.date_workshop,
        name: this.props.workshopInfo.name,
        remark: this.props.workshopInfo.remark,
        total_user: this.props.workshopInfo.total_user,
        is_login_status: this.props.workshopInfo.is_login_status
      });
    }
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleChangeDateTimePicker = (selectedDay, modifiers, dayPickerInput) => {
    let input = dayPickerInput.getInput();
    let date = input.value.trim();
    let date_l = date.split("-");
    let format_date = date_l[0] + "-" + date_l[1] + "-" + date_l[2];
    if (date_l[2] === undefined || format_date === '-undefined-undefined') {
      this.setState({ date_workshop: "" })
    }
    else {
        this.setState({ date_workshop: format_date });  
    }
  };
  isValidatorForm = date_workshop => {
    const { dispatch } = this.props;
    if (date_workshop === "" || date_workshop === undefined) {
      dispatch(alertActions.error("日程を入力してください", "date_workshop"));
      return false;
    }

    let date_workshop_check = moment(
      date_workshop,
      "YYYY-MM-DD",
      true
    ).isValid();
    if (!date_workshop_check) {
      dispatch(alertActions.error("日付の表記が不正です", "date_workshop"));
      return false;
    }
    return true;
  };
  isCheckedRadio = () => {};
  handleRadioChange = event => {
    const { name, value } = event.target;
    const stateValue = value === "1" ? true : false;
    this.setState({ [name] : stateValue });
  }
  submitForm = (event) => {
    const {
      name,
      date_workshop,
      company_id,
      remark,
      isUpdate,
      workshop_uuid,
      is_login_status
    } = this.state;

    event.preventDefault();
    let workshop = {
      name: name,
      date_workshop: date_workshop,
      remark: remark,
      company_id: company_id,
      is_login_status: is_login_status === "" ? true : is_login_status
    };
    if (this.isValidatorForm(this.state.date_workshop)) {
      if (isUpdate === true) {
        this.props.dispatch(
          workshopAdminActions.editWorkshopDetailAction(
            workshop_uuid,
            this.state.company_uuid,
            workshop
          )
        );
      } else
        this.props.dispatch(
          workshopAdminActions.createWorkshopAction(workshop, this.state.company_uuid)
        );
    }
  };
  deleteWorkshop = () => {
    const { company_uuid, workshop_uuid } = this.state;
    this.props.dispatch(
      workshopAdminActions.deleteWorkshopDetailAction(workshop_uuid, company_uuid)
    );
  };
  render() {
    const FORMAT = "yyyy-MM-dd";
    const { alert, company } = this.props;
    const {
      name,
      date_workshop,
      company_uuid,
      remark,
      isUpdate,
      total_user,
      is_login_status,
    } = this.state;
    let isCheckedLoginStatusRadio;
    if (is_login_status === true || is_login_status === "") {
      isCheckedLoginStatusRadio = true;
    }else{
      isCheckedLoginStatusRadio = false;
    }
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies enterprise-details registration-change-of-workshop">
          <HeaderAdmin />
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb active-line">
                  <span className="content-item color-4d4150-header-title">
                    ワークショップ日程の登録・変更
                  </span>
                </li>
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {company.name}
                  </span>
                </li>
              </ul>
            </div>

            <div className="content-930 mt-40">
              <div className="content-form-568">
                <div className="form-box form-box-place mb-3">
                  <form>
                    <div className="group-form-control clearfix">
                      <div className="form-group d-block">
                        <label htmlFor="datepicker" className="label-box-form">
                          日程 <span className="danger-box">必須</span>
                        </label>
                        <DayPickerInput
                          placeholder="YYYY-MM-DD"
                          dayPickerProps={{firstDayOfWeek: 1}}
                          inputProps={{ className: "form-control text-input-daypicker-change-side" }}
                          formatDate={formatDate}
                          format={FORMAT}
                          parseDate={parseDate}
                          className={"form-control"}
                          onDayChange={this.handleChangeDateTimePicker}
                          value={date_workshop ? date_workshop : ""}
                        />
                      </div>
                      
                      <div className="form-group d-block">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          ワークショップ名
                        </label>
                        <div className="form-input">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {isUpdate && total_user === 0 ? (
                        <div className="btn-dis-box button-delete-active-box-fix-right">
                          <button
                            type="button"
                            className="btn button-delete-workshop-active bnt-submit-link-status"
                            onClick={this.deleteWorkshop}
                          >
                            削除する
                          </button>
                        </div>
                      ) : (
                          ""
                        )}
                      {isUpdate && total_user > 0 ? (
                        <div className="btn-dis-box">
                          <button
                            type="button"
                            className="btn button-delete-workshop"
                          >
                            削除する
                          </button>
                          <span>
                            参加者が登録されている <br />
                            ため削除できません
                          </span>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    <div className="form-group d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form label-box-radio"
                      >
                        ログイン案内
                      </label>
                      <div className="form-input form-input-radio">
                        <label className="container-radio">
                          ON
                          <input
                            type="radio"
                            name="is_login_status"
                            value={1}
                            onClick={this.handleRadioChange}
                            defaultChecked={isCheckedLoginStatusRadio}
                            checked={isCheckedLoginStatusRadio}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="container-radio">
                          OFF
                          <input
                            type="radio"
                            name="is_login_status"
                            value={0}
                            onClick={this.handleRadioChange}
                            checked={!isCheckedLoginStatusRadio}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="form-group d-block mt-30 mb-30">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        備考
                      </label>
                      <div className="form-textarea">
                        <textarea
                          className="form-control form-control-area phase2-form-input-auto-height"
                          id="exampleTextarea"
                          rows={2}
                          name="remark"
                          value={remark}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    {alert.message && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    <div className="btn-login text-left mt-40">
                      <button
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mr-20"
                        onClick={this.submitForm}
                      >
                        保存する
                      </button>
                      <Link
                        type="button"
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status"
                        to={`/admin/workshop/${company_uuid}`}
                      >
                        戻る
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    authAdminReducer,
    workshopAdminReducer,
    userAdminReducer
  } = state;
  const { workshopInfo } = workshopAdminReducer;
  const { admin } = authAdminReducer;
  const { company } = userAdminReducer;
  return { alert, admin, company, workshopInfo };
}

const connectedNewWorkshopPage = connect(mapStateToProps)(NewWorkshopPage);
export { connectedNewWorkshopPage as NewWorkshopPage };
