import { questionConstants } from "../_constants";
import { addAnswerToQuestionReducer } from "../_helpers/question.utils";

// let analystInfo = JSON.parse(localStorage.getItem("analystInfo"));
const INITIAL_STATE = {
  questions: [],
  answers: [],
  analystInfo: []
};

// const initialState = user ? { loggedIn: true, user } : {};

export function questions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case questionConstants.QUESTIONS_GETALL_REQUEST:
      return {
        ...state,
        questions: action.questions
      };
    case questionConstants.QUESTIONS_GETALL_FAILURE:
      return {
        error: action.error
      };

    case questionConstants.ADD_ANSWER:
      return {
        ...state,
        answers: addAnswerToQuestionReducer(
          state.answers,
          action.questionsAtEachPage
        )
      };
    case questionConstants.SUBMIT_ALL_ANSWERS:
      return {
        ...state,
        analystInfo: action.analystInfo
      };
    case questionConstants.GET_HISTORY_RESULT_ANSWER_QUESTIONS:
      return {
        ...state,
        analystInfo: action.analystInfo
      };
    ///send mail after completed all questions at Consequence Page
    case questionConstants.SEND_MAIL_COMPLETED_QUESTIONS:
    default:
      return state;
  }
}
