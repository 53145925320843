import React from "react";
import Modal from 'react-awesome-modal';
import { connect } from "react-redux";
import { questionActions } from "../../_actions";
import {
  addAnswerToArray,
  addAnswerToQuestions
} from "../../_helpers/question.utils";
import Header from "../../_components/Header";
import QuestionGroup from "../components/QuestionGroup";
import Load from "../../../assets/styles/img/load.png";

class PreAssessmentPage extends React.Component {
 state ={ disabled : false}
  constructor(props) {
    super(props);

    this.state = {
      questionsAtPage: [],
      questions: [],
      answers: [],
      notAllAttended : true,
      visible : false,
   
    };
  }

  componentDidMount() {
    //Component vua load het trang
    this.props.dispatch(questionActions.getAllQuestions());
  }

  componentDidUpdate(prevProps) {
    //trang thai updating
    if (prevProps.questions !== this.props.questions && Array.isArray(this.props.questions)) {
      this.setState({ questions: this.props.questions,
                      questionsAtPage: this.props.questions
          });
    }
  }

  onSubmitAllQuestions = () => {
    
    this.props.dispatch(
      questionActions.submitAllQuestions(this.state.answers)
    );
    this.setState({ disabled :true,})
  }

  //QuestionGroup component pass data
  onAddAnswerToArray = answer => {
    this.setState(
      {
        answers: addAnswerToArray(this.state.answers, answer),
        questionsAtPage : addAnswerToQuestions( this.state.questionsAtPage,answer)
      }, () => {
        let flag = this.state.questionsAtPage.find(checkQuestion => checkQuestion.point === undefined);
        if (flag === undefined){
          this.setState({notAllAttended : false});
        }
      });
  }

  openModal = () => {
    this.setState({visible : true});
  }

  closeModal = () => {
    this.setState({visible : false, disabled :false});
  }

  render() {
    const { alert } = this.props;
    return (
      <div
        className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo"
        style={{ background: "white" }}
      >
        <Header assessmentActive="true"></Header>
        <div className="content">
          <div className="content-mobile">
            <div className="modal-body mb-0 px-25 pb-0 pt-125">
              <h2 className="title-login text-left text-4d4150 fz-20 mb-2">
                3分間コーチ・インベントリー
              </h2>
              <p className="title-login-content mb-0">
                あなた自身についてお答えください。
                <br />
                ※「相手」は、部下の方（部下がいない方は周囲のメンバー）を想定してください。
              </p>
            </div>
            <form>
              {alert.message && (
                <div className={`alert ${alert.type}`}>{alert.message}</div>
              )}
              {this.state.questionsAtPage.map(question => {
                return (
                  <QuestionGroup
                    key={question.id}
                    question={question}
                    onAddAnswerToArray={this.onAddAnswerToArray}
                  />
                );
              })}

              <div
                className="from-group text-center mt-4 px-24"
                style={{
                  display: `${alert.message ? "none" : ""}`
                }}
              >
                <button
                  onClick={this.openModal}
                  type="button"
                  className="bnt-submit bg-color-4D4150 mb-24"
                  disabled={this.state.notAllAttended}
                >
                  回答を送信する
                </button>

             
              </div>
            </form>
          </div>
        </div>
        <Modal visible={this.state.visible} width="300" height="371" onClickAway={() => this.closeModal()}>
            {/* Modal content*/}
            <div id="assessment-modal" className="modal-content">
              <div className="modal-body">
                <div className="pt-5">
                  <div className="img-load text-center">
                    <img src={Load} alt="" />
                  </div>
                  <p className="fz-13 font-weight-bold text-center mt-3 mb-0">
                    入力内容に間違いはありませんか？<br/>
                    登録完了後は、回答内容の追加や修正はできませんのでご注意ください。
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={this.onSubmitAllQuestions}
                  type="button"
                  className="bnt-submit bg-color-4D4150 mb-24"
                  disabled ={this.state.disabled}
                >
                  送信する
                </button>
                <button
                  type="button"
                  className="bnt-submit bg-color-ffffff mb-24 btn-close"
                  onClick={this.closeModal}
                >
                  戻る
                </button>
              </div>
            </div>
        </Modal>
        <div className="design-by">
          © COACH A Co., Ltd. All Rights Reserved.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { questionsReducer, authentication, alert } = state;
  const { questions, answers } = questionsReducer;
  const { user } = authentication;
  return {
    questions,
    answers,
    user,
    alert
  };
}
const connectedPreAssessmentPage = connect(mapStateToProps)(PreAssessmentPage);
export { connectedPreAssessmentPage as PreAssessmentPage };
