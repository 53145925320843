import { questionAdminConstants } from "../_constants";
import { questionAdminService } from "../_services";
import { alertActions } from "./alert.actions";

export const questionAdminActions = {
  getHistoryResultAction,
};

function getHistoryResultAction (user_uuid) {
  return dispatch => {
    // dispatch(request());
    questionAdminService.getHistoryResult(user_uuid).then(analystInfo => {
      if (analystInfo.code !== 200) {
        // dispatch(failure(analystInfo));
        dispatch(alertActions.error(analystInfo.detail));
      } else {
        dispatch(success(analystInfo.data));
      }
    });
  };
  function success(analystInfo) {
    return {
      type: questionAdminConstants.GET_HISTORY_RESULT,
      analystInfo: analystInfo
    };
  }
}
