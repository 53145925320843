import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ErrorBoundary, PrivateErrorPage } from '../ErrorPage';
import { PrivateAdminErrorPage } from '../AdminDashboard/ErrorPage';

const Frontend = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? (
                <ErrorBoundary component={PrivateErrorPage}>
                    <Component {...props} />
                </ErrorBoundary>
            )
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);

const Admin = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('admin')
            ? (
                <ErrorBoundary component={PrivateAdminErrorPage}>
                    <Component {...props} />
                </ErrorBoundary>
            )
            : <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    )} />
);

export const PrivateRoute = {
    Frontend,
    Admin,
};
