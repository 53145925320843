import { loginConstants } from "../_constants";
import { loginServices } from "../_services";
import { alertActions } from "./";
import { history } from "../../_helpers";
import { loginMessages } from "../_messages";

export const loginActions = {
  login,
  logout,
  checkLoginAdmin,
  checkLoginRoleAdmin,
  checkLoginRoleSupperAdmin,
  forgotPasswordAdminAction,
  forgotPasswordConfirmAction,
  changePasswordAction
};

function checkLoginAdmin(token) {
  return dispatch => {
    dispatch(request());
    loginServices
      .checkLogin(token)
      .then(user => {
        if (user.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success());
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });

    function request() {
      return { type: loginConstants.CHECK_LOGIN };
    }

    function success() {
      return { type: loginConstants.CHECK_LOGIN_SUCCESS };
    }
  };
}

function checkLoginRoleAdmin(token) {
  return dispatch => {
    dispatch(request());
    loginServices
      .checkAdminLogin(token)
      .then(user => {
        if (user.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success());
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });

    function request() {
      return { type: loginConstants.CHECK_LOGIN };
    }

    function success() {
      return { type: loginConstants.CHECK_LOGIN_SUCCESS };
    }
  };
}

function checkLoginRoleSupperAdmin(token) {
  return dispatch => {
    dispatch(request());
    loginServices
      .checkSupperAdminLogin(token)
      .then(user => {
        if (user.status === 403) {
          history.push("/admin/login/");
        }
        dispatch(success());
      })
      .catch(error => {
        dispatch(alertActions.error(error.data));
      });

    function request() {
      return { type: loginConstants.CHECK_LOGIN };
    }

    function success() {
      return { type: loginConstants.CHECK_LOGIN_SUCCESS };
    }
  };
}

function login(username, password, url) {
  return dispatch => {
    dispatch(
      request({
        username
      })
    );

    loginServices
      .login(username, password)
      .then(admin => admin.data)
      .then(admin => {
        if (admin.code !== 200) {
          dispatch(failure(admin));
          dispatch(alertActions.error(admin.data.message));
        } else {
          dispatch(success(admin));
          if(url !== ""){
            history.push(url);
          }
          else if (admin.data.is_supper_admin === true)
            history.push("/admin/company");
          else history.push("/admin/detail-company/" + admin.data.company_uuid);
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };
  
  function request(admin) {
    return {
      type: loginConstants.LOGIN_ADMIN_REQUEST,
      admin: admin
    };
  }

  function success(admin) {
    return {
      type: loginConstants.LOGIN_ADMIN_SUCCESS,
      admin: admin
    };
  }

  function failure(error) {
    return {
      type: loginConstants.LOGIN_ADMIN_FAILURE,
      error
    };
  }
}

function logout() {
  loginServices.logout();
  return {
    type: loginConstants.LOGOUT_ADMIN
  };
}

////Admin forgot password action
function forgotPasswordAdminAction(email) {
  return dispatch => {
    dispatch(request());
    loginServices
      .forgotPasswordAdminService(email)
      .then(data => data.data)
      .then(data => {
        if (data.code !== 200) {
          dispatch(failure(data));
          dispatch(alertActions.error(data.data.message));
        } else {
          dispatch(success());
          dispatch(
            alertActions.success(loginMessages.FORGOT_PASSWORD_MESSAGES)
          );
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };
  function request() {
    return { type: loginConstants.FORGOT_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: loginConstants.FORGOT_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: loginConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}
////forgot password confirm action
function forgotPasswordConfirmAction(password, token) {
  return dispatch => {
    dispatch(request());
    loginServices
      .forgotPasswordConfirmService(password, token)
      .then(user => user.data)
      .then(user => {
        if (user) {
          if (user.code === 200) {
            dispatch(success());
            dispatch(
              alertActions.success(
                loginMessages.FORGOT_PASSWORD_CONFIRM_MESSAGES
              )
            );
            history.push("/admin/forgot-password-completed");
          } else if(user.code === 403){
            dispatch(failure(user));
            dispatch(
              alertActions.error(loginMessages.FORGOT_PASSWORD_EXPERT_TIME, 'forgot-password-expiry')
            );
          } else {
            dispatch(failure(user));
            dispatch(
              alertActions.error(user.password)
            );
          }
        } else {
          dispatch(alertActions.error(user.password));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function request() {
    return { type: loginConstants.CHANGE_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: loginConstants.CHANGE_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: loginConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}
//////Change password action
function changePasswordAction(current_password,password, confirmed_password) {
  return dispatch => {
    loginServices
      .changePasswordService(current_password,password, confirmed_password)
      .then(user => user.data)
      .then(user => {
        if (user.code !== 200) {
          dispatch(alertActions.error(user.data.message));
        } else {
            history.push("/admin/forgot-password-completed");
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data));
      });
  };

  function failure(error) {
    return { type: loginConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}
