import React from "react";

import {connect} from "react-redux";
import {userActions} from "../../_actions";

import {getAccessToken} from "../../_helpers"
class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
        };

        this.handleOnClick = this.handleOnClick.bind(this);
    }
    componentDidMount() {
        this.setState({token:getAccessToken()});
    }

    handleOnClick(e) {
        e.preventDefault();
        const {dispatch} = this.props;
        dispatch(userActions.logout(getAccessToken()));
    }

    render() {
        return (
            <a href="/logout" onClick={this.handleOnClick} className="list-menu-link">
                ログアウト
            </a>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedLogoutPage = connect(mapStateToProps)(Logout);
export {connectedLogoutPage as Logout};
