import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { questionActions } from "../../_actions";
import Header from "../../_components/Header";

class FirstTimeHomePage extends React.Component {
  componentDidMount() {
    this.props.dispatch(questionActions.checkPreAssessment());
  }

  render() {
    // const { user, users } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first-fix  mobile-first-time home-vertwo">
          {/* <div className="header header-fix-vertwo-mb">
            <div className="header-content bg-color-F4F5F4">
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div> */}

          <Header assessmentActive="true"></Header>

          <div className="content">
            <div className="content-vertwo">
              <div className="login-box login-box-vertwo">
                <form>
                  <div
                    className="modal-content"
                    style={{ background: "white" }}
                  >
                    <div className="modal-body">
                      <h2 className="title-login text-4d4150">
                        「3分間コーチ・インベントリー」
                      </h2>
                      <p className="title-login-content">
                        ワークショップの前に、24問の質問にご回答ください。あなたのコミュニケーションスタイルと強み・弱みの棚卸しができます。
                        <br></br>
                        なお、Internet Explorerは推奨環境外となっております。別のブラウザ（スマートフォン・タブレット含む）をご利用ください。
                      </p>
                      <div className="from-group  from-group-vertwo  mt-4">
                        <Link to="/pre-assessment-page">
                          <button
                            type="button"
                            className="bnt-submit bg-color-4D4150  mb-0"
                          >
                            実施する
                          </button>
                        </Link>
                      </div>
                      <p className="mb-0 mt-2 fz-14 notevertwo">
                        （所要時間：約10分）
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="design-by">
                © COACH A Co., Ltd. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users
  };
}

const connectedFirstTimeHomePage = connect(mapStateToProps)(FirstTimeHomePage);
export { connectedFirstTimeHomePage as FirstTimeHomePage };
