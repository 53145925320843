const $ = require("jquery");

$(document).ready(function() {
  // ===== Tab  js === >>

  $(".js-gr-box-text-title").css("display", "none");
  $(".nav-tab ul li").click(function() {
    var tab_id = $(this).attr("data-tab");
    $(".nav-tab ul li").removeClass("active");
    $(".tab-content").removeClass("active");
    $(this).addClass("active");
    $("#" + tab_id).addClass("active");
    if ($(this).hasClass("active") && $(this).attr("data-tab") === "tab-1") {
      $(".form-control-select").css("display", "none");
      $(".js-gr-box-text-title").css("display", "flex");
    } else {
      $(".form-control-select").css("display", "block");
      $(".js-gr-box-text-title").css("display", "none");
    }
  });

  // ===== Menu Reponsive === >>

  fixedtheadtbl();
  $(window)
    .resize(function() {
      fixedtheadtbl();
    })
    .trigger("resize");

  // var status_menu_rp = true;
  $(".menu-rp").click(function(e) {
    e.preventDefault();

    $(".menu")
      .find("ul:visible")
      .hide();
    if ($("body").hasClass("sidebar-collapse")) {
      $("body").removeClass("sidebar-collapse");
      // status_menu_rp = false;
    } else {
      $("body").addClass("sidebar-collapse");
      // status_menu_rp = true;
    }
  });

  // ===== Menu-left Efect === >>

  var subMenuSelector = ".sub-menu";
  $(".menu").on("click", "li a", function(e) {
    var checkElement = $(this).next();
    if (checkElement.is(subMenuSelector) && checkElement.is(":visible")) {
      checkElement.slideUp(0);
      $(this)
        .parent()
        .removeClass("active");
    } else if (
      checkElement.is(subMenuSelector) &&
      !checkElement.is(":visible")
    ) {
      var parent = $(this)
        .parents("ul")
        .first();
      parent.find("ul:visible").slideUp(0);
      parent.find("li").removeClass("active");

      checkElement.slideDown(0, function() {
        $(this)
          .parent("li")
          .addClass("active");
        $(this)
          .parents(".left-menu")
          .addClass("active-scroll");
      });
    }

    if (checkElement.is(subMenuSelector)) {
      e.preventDefault();
    }
  });
  // === Back to top === >>

  $(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {
      $("#return-to-top").fadeIn(200);
    } else {
      $("#return-to-top").fadeOut(200);
    }
  });
  $("#return-to-top").click(function() {
    $("body,html").animate(
      {
        scrollTop: 0
      },
      500
    );
  });

  // Modal select

  var list_input = $(".table-wrap-content .form-control");
  var list_diop = $(".dialog-modal .genric-btn");
  var this_input_oke;
  // var di_op;

  $(list_input).click(function(e) {
    e.preventDefault();
    this_input_oke = $(this);
  });

  $(list_diop).click(function(e) {
    e.preventDefault();
    var $this_diop = $(this);
    if ($this_diop.text() === "クリア") {
      $(this_input_oke).val("");
    } else {
      this_input_oke.val($this_diop.text());
    }
  });

  // Modal change Color

  var btn = $(".button-color");
  // var dropdown = $(".dropdown-options");
  // var code_color = $(".button-color .code-color");
  var optionLinks = $(".option a");
  $(btn).click(function(e) {
    e.preventDefault();
    $(this)
      .next()
      .toggleClass("open");
  });
  for (var i = 0; i < optionLinks.length; i++) {
    $(optionLinks[i]).click(function(e) {
      e.preventDefault();
      $(this)
        .parents(".dropdown-options")
        .removeClass("open");
      var btn_parent_text = $(this).parents(".select-color");
      var btn_child_text = $(btn_parent_text).find(".code-color");
      var view_color = $(btn_parent_text).find(".view-color");
      $(btn_child_text).text(
        $(this)
          .find(".code-color-01")
          .html()
      );
      $(view_color)
        .removeClass()
        .addClass(
          "view-color c" +
            $(this)
              .find(".code-color-01")
              .html()
        );
    });
  }

  $(".title-toggle").click(function(e) {
    e.preventDefault();
    var parent = $(this).parents(".title-common-lv3");
    if (
      $(parent)
        .next()
        .is(":visible")
    ) {
      $(parent)
        .next()
        .slideUp(300);
      $(this).addClass("xoay");
    } else {
      $(parent)
        .next()
        .slideDown(300);
      $(this).removeClass("xoay");
    }
  });

  // === End Script === >>

  // === Start Scroll Table == >

  function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }

  function customScroll() {
    $(".box-tbodytable").on("scroll", function(event) {
      $(this)
        .siblings(".box-theadtable")
        .scrollLeft($(this).scrollLeft());
    });
  }

  function hasScrollBar(obj) {
    if (obj.length === 0) return false;

    var height = obj.get(0).scrollHeight;
    if (height === "undefined") {
      height = obj.get(0).height();
    }

    return height > obj.height();
  }

  function fixedtheadtbl() {
    $(".box-tbodytable").each(function() {
      customScroll();
      if (hasScrollBar($(this))) {
        $(this)
          .parent()
          .find(".box-theadtable")
          .css("padding-right", getScrollbarWidth());
      }
    });
  }

  // === End Scroll Table == >

  //Click outside close navbar Header
  $(document).click(function() {
    $("#navbarSupportedContent").collapse("hide");
  });
});
//// Phase 2
$(document).ready(function () {
      //// resize button when zoom
      try {
        var scroll_left_button =
        $(".top-content-responsive").get(0).scrollWidth -
        $(".header").get(0).clientWidth -
        $(window).scrollLeft();

      $(".btn-header-responsive").animate(
        { width: scroll_left_button + "px" },
        0
      );
      } catch (error) {}
      //// end resize button when zoom
  var case_shadow = 0;
  setTimeout(() => {
    try {
      var scroll_left =
        $(".box-tbodytable").get(0).scrollWidth -
        $(".box-tbodytable").get(0).clientWidth;

      $(".box-tbodytable").scroll(function () {
        if (scroll_left === $(this).scrollLeft()) {
          $(".shadow-table").removeClass("js-shadow-table");
        } else {
          $(".shadow-table").addClass("js-shadow-table");
        }
      });
    } catch (error) {}
  }, 1000);

  $(window).scroll(function () {
    try {
      var scroll_left_button =
      $(".top-content-responsive").get(0).scrollWidth -
      $(".header").get(0).clientWidth -
      $(window).scrollLeft();

    $(".btn-header-responsive").animate(
      { width: scroll_left_button + "px" },
      0
    );   
    } catch (error) {}
  });

  $(window)
    .resize(function () {
      //// resize button when zoom
      try {
        var scroll_left_button =
        $(".top-content-responsive").get(0).scrollWidth -
        $(".header").get(0).clientWidth -
        $(window).scrollLeft();

      $(".btn-header-responsive").animate(
        { width: scroll_left_button + "px" },
        0
      );
      } catch (error) {}
      //// end resize button when zoom
      if (case_shadow === 0) {
        var scroll_left;
        try {
          if ($(".box-tbodytable").scrollLeft() < scroll_left) {
            $(".shadow-table").addClass("js-shadow-table");
          } else {
            $(".shadow-table").removeClass("js-shadow-table");
          }
          $(".box-tbodytable").scroll(function () {
            if (scroll_left === $(this).scrollLeft()) {
              $(".shadow-table").removeClass("js-shadow-table");
            } else {
              $(".shadow-table").addClass("js-shadow-table");
            }
          });
        } catch (error) {}
        case_shadow = case_shadow + 1;
      } else {
        try {
          var scroll_left_1 =
            $(".box-tbodytable").get(0).scrollWidth -
            $(".box-tbodytable").get(0).clientWidth;
          if ($(".box-tbodytable").scrollLeft() < scroll_left_1) {
            $(".shadow-table").addClass("js-shadow-table");
          } else {
            $(".shadow-table").removeClass("js-shadow-table");
          }
          $(".box-tbodytable").scroll(function () {
            if (scroll_left_1 === $(this).scrollLeft()) {
              $(".shadow-table").removeClass("js-shadow-table");
            } else {
              $(".shadow-table").addClass("js-shadow-table");
            }
          });
        } catch (error) {}
      }
    })
    .trigger("resize");
});
