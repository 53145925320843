import React from "react";

const ScrollToTop = () => (
  <a
    href="#/"
    id="return-to-top"
    onClick={event => event.preventDefault()}
  >
    <i className="fa fa-arrow-up" aria-hidden="true" />
  </a>
);
export default ScrollToTop;
