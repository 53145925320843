import { companyConstants } from "../_constants";

const INITIAL_STATE = {
  listUserCompany:[],
  listCompany: [],
  company: "",
  isDeleteCompanySuccess: "",
  companyNoUser :[],
};

export function companyAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case companyConstants.GET_USER_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        listUserCompany: action.listUserCompany
      };
    case companyConstants.GET_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        listCompany: action.listCompany
      };
    case companyConstants.CREATE_COMPANY_SUCCESS:
      return {
        ...state
      };
    case companyConstants.DETAIL_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company
      };
    case companyConstants.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        isDeleteCompanySuccess: true
      };
    case companyConstants.DELETE_COMPANY_FAIL:
      return {
        ...state,
        isDeleteCompanySuccess: false
      };
    case companyConstants.RESET_STATUS_DELETE_COMPANY:
      return {
        ...state,
        isDeleteCompanySuccess: ""
      };
      case companyConstants.COMPANY_WITH_NO_USER:
      return {
        ...state,
        listCompany: action.companyNoUser
      };
      case companyConstants.COMPANY_WITH_ONE_USER:
      return {
        ...state,
        listCompany: action.companyOneUser
      };
      case companyConstants.COMAPNY_WITH_MAIL_MAGAZINE_PERIOD:
      return {
        ...state,
        listCompany: action.MailMagazinePeriod
      };
      case companyConstants.COMPANY_WITH_USER_ACTIVE:
      return {
        ...state,
        listCompany: action.UserActive
      };
    default:
      return state;
  }
}
