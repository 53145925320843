import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store, setupSentry } from './_helpers';
import { App } from './App';
import '../assets/styles/css/font-awesome.min.css';
import '../assets/styles/css/all.css';
import '../assets/styles/css/bootstrap.css';
import '../assets/styles/css/perfect-scrollbar.css';
import '../assets/styles/css/animate.min.css';
import '../assets/styles/css/main.css';
import '../assets/styles/css/fix-css-component.css';

import  '../assets/styles/js/bootstrap.min.js';
import  '../assets/styles/js/main.js';

setupSentry();

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);
