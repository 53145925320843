export const loginMessages = {
  EMAIL_NOT_NULL: "メールアドレスが入力されていません",
  EMAIL_NOT_TRUE: "不正なメールアドレスです",
  PASSWORD_NOT_NULL: "パスワードが入力されていません",

  FORGOT_PASSWORD_MESSAGES: "メールを送信しました",

  PASSWORD_NOT_MATCH: "パスワードが一致しません",
  PASSWORD_IS_WEAK: "パスワード要件を満たしていません",

  FORGOT_PASSWORD_CONFIRM_MESSAGES: "パスワードの変更が完了しました",
  FORGOT_PASSWORD_EXPERT_TIME: "リンクが無効です。こちらをクリックの上、再度パスワードお忘れの場合よりお手続きください。",

  CHANGE_PASSWORD_SUCCESS: "パスワードの変更が完了しました"
};
