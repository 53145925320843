export const userMessages = {
  FORGOT_PASSWORD_CONFIRM_MESSAGES: "パスワードの変更が完了しました",
  FORGOT_PASSWORD_MESSAGES: "メールを送信しました",
  FORGOT_PASSWORD_EXPERT_TIME: "リンクが無効です。こちらをクリックの上、再度パスワードお忘れの場合よりお手続きください。",
  PASSWORD_NOT_MATCH: "パスワードが一致しません",
  PASSWORD_IS_WEAK: "パスワード要件を満たしていません",
  PASSWORD_NOT_NULL: "パスワードを入力してください",
  ERROR_SERVER: "サーバーエラーが発生しました",
  CHANGE_PASSWORD_SUCCESS: "パスワードの変更が完了しました",
  FILE_CSV_NOT_EMPTY: "ファイルCSVが空ではありません",
  FILE_CSV_CHECK_OK: "OKファイルCSV、ファイルを送信してください",
  WORKSHOP_REQUIRE: "参加予定日を選択する必要があります",
  DEPARTMENT_REQUIRE: "部署が必要です",
  IMPORT_SUCCESS: "インポート成功",
  EXPORT_SUCCESS: "ダウンロード成功",
  IMPORT_WAITING: "プロセッシング...",
  PROCESSING: "プロセッシング...",
  FIRST_NAME_NOT_NULL: "最初の名前は空ではありません",
  LAST_NAME_NOT_NULL: "姓は空ではありません",
  EMAIL_NOT_NULL: "メールアドレスが入力されていません",
  EMAIL_NOT_TRUE: "不正なメールアドレスです",
  COMPLETED_SEND_EMAIL: "メール送信ユーザーが完了しました",
  COMPLETED_DELETE_USERS: "削除ユーザーの完了",
  COMPLETED_CREATE_WORKSHOP: "作成ワークショップを完了",
  NOT_FILE_CSV: ".csv拡張子のファイルをアップロードしてください",
  INPUT_IS_EMAIL: "メールアドレスを入力してください",

  CREATE_SUCCESS: "ユーザー作成の成功",

  DELETE_USER_SUCCESS: "DELETE USER SUCESS",
  SEND_EMAIL_INVITE_USER_SUCCESS: "SEND MAIL SUCCESS"
};
