import React from "react";

const ModalQuestionCommon = () => {
  return (
    <div
      id="myModal-common"
      className="modal-common modal-mail modal fade"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-assessment-consequence modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              ×
            </button>
            <h4 className="modal-title fz-14">閉じる</h4>
          </div>
          <div className="modal-body">
            <div className="assessment-result-modal">
            <div className="modal-dialog-assessment-consequence-daily-involvement">
              <p className="modal-assessment-consequence-green-pill fz-14 ">日常の関わり</p>
              <p className="modal-assessment-consequence-green-main fz-13">相手との信頼関係の構築を促す</p>
              <p className="modal-assessment-consequence-green-sub fz-13 ">日常の関わり</p>
            </div>
            <div className="mb-5 pt-20">
              <p className="fz-18 font-weight-bold">継続</p>
              <font className="fz-14">
              定期的に相手の目標の進捗について話をすること
              </font>
            </div>
            <div className="mb-5">
              <p className="fz-18 font-weight-bold">方向性共有</p>
              <font className="fz-14">
              組織の方向性と相手の目指していることを互いに共有すること
              </font>
            </div>
            <div className="mb-5">
              <p className="fz-18 font-weight-bold">個別対応</p>
              <font className="fz-14">相手の性格や状況に合った関わりをすること</font>
            </div>
            <div className="modal-dialog-assessment-consequence-skills">
              <p className="modal-assessment-consequence-red-pill fz-14 ">対話力</p>
              <p className="modal-assessment-consequence-red-sub fz-13 ">相手の自発的な行動を促す対話のスキル</p>
            </div>
            <div className="mb-5 pt-20">
              <p className="fz-18 font-weight-bold">目的志向</p>
              <font className="fz-14">目的を持って相手と話をし、明確な結論を互いに共有すること</font>
            </div>
            <div className="mb-5">
              <p className="fz-18 font-weight-bold">質問</p>
              <font className="fz-14">
              相手の気付き、探求、自発性を促すような質問をすること
              </font>
            </div>

            <div className="mb-5">
              <p className="fz-18 font-weight-bold">傾聴</p>
              <font className="fz-14">
              相手に関心を示し、最後まで話を聞くこと
              </font>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalQuestionCommon;
