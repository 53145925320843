export const validateMessages = {
  EMAIL_NOT_NULL: "メールアドレスを入力してください",
  EMAIL_NOT_TRUE: "不正なメールアドレスです",

  FIRST_NAME_NOT_NULL: "名を入力してください",
  LAST_NAME_NOT_NULL: "姓を入力してください",

  NUMBER_NOT_TRUE: "数字を入力してください",
  DATE_NOT_EXIST: "存在しない日付です"
};
