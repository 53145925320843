// import config from "config";
import axios from "axios";
import { authHeaderAdmin } from "../_helpers";

const API_LOGIN = process.env.URL_API + "/v1/auth/login-admin";
const API_GET_USER = process.env.URL_API + "/v1/users/";
const API_DOWNDATA_FILE = process.env.URL_API + "/v1/users/export-csv";
const API_UPDATA_FILE = process.env.URL_API + "/v1/users/import-csv";
const API_CREATE_USER = process.env.URL_API + "/v1/users/create";
const API_GET_DEPARTMENT = process.env.URL_API + "/v1/department/";
const API_GET_WORKSHOPS = process.env.URL_API + "/v1/workshop/";
const API_DELETE_USER = process.env.URL_API + "/v1/users/delete-list/";
const API_SEND_MAIL_INVITE_USER =
  process.env.URL_API + "/v1/users/send-email-request-user/";
// const API_CREATE_WORKSHOP = process.env.URL_API + "/v1/workshop/create";
const API_CHECK_LOGIN = process.env.URL_API + "/v1/users/check_login/";

export const adminService = {
  login,
  logout,
  getUser,
  importFile,
  exportFile,
  createUser,
  pagination,
  nextAndPrevious,
  getDepartment,
  getWorkshops,
  deleteUserAccount,
  sendMailInviteUserAccount,
  sortUserByService,
  checkLogin
};

const headers = {
  "Content-Type": "application/json",
  Authorization: authHeaderAdmin()
};

function checkLogin(token) {
  if (token !== "") {
    const AuthStr = "Bearer ".concat(token);
    return axios
      .get(API_CHECK_LOGIN, { headers: { Authorization: AuthStr } })
      .then(response => {
      })
      .catch(error => {
        if (error.response.status === 401) {
          logout();
          window.location.reload(true);
        }
        return error.response;
      });
  }
}

function login(username, password) {
  const body = {
    username,
    password
  };
  return axios
    .post(API_LOGIN, body)
    .then(res => {
      localStorage.setItem("admin", JSON.stringify(res.data));
      return res;
    })
    .catch(error => {
      return error.response;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("admin");
}

function getUser() {
  return axios
    .get(API_GET_USER, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function exportFile(){
  const FileDownload = require('js-file-download');
  return axios.get(API_DOWNDATA_FILE, {headers}).then(res => {
    FileDownload(res.data, 'user_details.csv');
  }).catch(error => {
    return error.response;
  })
}

function importFile(file, workshop_id) {
  const data = new FormData();
  data.append("user_csv", file);
  data.append("workshop_id", workshop_id);

  return axios
    .post(API_UPDATA_FILE, data, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function createUser(user) {
  return axios
    .post(API_CREATE_USER, JSON.stringify(user), {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function pagination(offset) {
  const api = API_GET_USER + "?limit=10&offset=" + offset;
  return axios
    .get(api, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function nextAndPrevious(urlNextAndPrevious) {
  return axios
    .get(urlNextAndPrevious, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function getDepartment() {
  return axios
    .get(API_GET_DEPARTMENT, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}

function getWorkshops(user_id) {
  return axios
    .get(API_GET_WORKSHOPS + "?user_id=" + user_id, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
    });
}

////////// Delete user
function deleteUserAccount(listUser) {
  const body = JSON.stringify({
    list_users: listUser
  });
  return axios
    .post(API_DELETE_USER, body, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error.response;
    });
}

////////// Send mail invite user
function sendMailInviteUserAccount(listUser) {
  const body = JSON.stringify({
    list_users: listUser
  });
  return axios
    .post(API_SEND_MAIL_INVITE_USER, body, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error.response;
    });
}

////////// Sort user by
function sortUserByService(offset, sortMail, sortDep) {
  const api =
    API_GET_USER +
    "?limit=10&offset=" +
    offset +
    "&email_desc=" +
    sortMail +
    "&dep_desc=" +
    sortDep;
  return axios
    .get(api, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}
