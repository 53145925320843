import { magazineConstants } from "../_constants";

const INITIAL_STATE = {
  magazines: [],
  is_send_email: "",
  magazine: ""
};

// const initialState = user ? { loggedIn: true, user } : {};

export function magazines(state = INITIAL_STATE, action) {
  switch (action.type) {
    case magazineConstants.MAGAZINE_GETALL_REQUEST:
      return {
        ...state,
        magazines: action.magazines
      };
    case magazineConstants.MAGAZINE_GET_SETTING_SEND_MAIL_USER:
      return {
        ...state,
        is_send_email: action.is_send_email
      };
    case magazineConstants.MAGAZINE_UPDATE_SETTING_SEND_MAIL:
      return {
        ...state,
        is_send_email: action.is_send_email
      };
    case magazineConstants.MAGAZINE_GET_DETAIL_ARTICLE:
      return {
        ...state,
        magazine: action.magazine
      };
    default:
      return state;
  }
}
