// import config from "config";
import axios from "axios";
import { authHeaderAdmin, forceReloadWindow, logout } from "../_helpers";

const API_GET_WORKSHOPS = process.env.URL_API + "/v1/workshop/?company_uuid=";

const API_CREATE_WORKSHOPS = process.env.URL_API + "/v1/workshop/create/";
const API_WORKSHOP_DETAIL = process.env.URL_API + "/v1/workshop/";

export const workshopAdminServices = {
  getListWorkshopsService,
  createWorkshopService,
  getWorkshopDetailService,
  editWorkshopDetailService,
  deleteWorkshopDetailService
};

// const headers = {
//   "Content-Type": "application/json",
//   Authorization: authHeaderAdmin()
// };

function getListWorkshopsService(companyUUID) {
  // const body = JSON.stringify({
  //   list_users: listUser
  // });
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_WORKSHOPS + companyUUID, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

function createWorkshopService(workshop) {
  const body = JSON.stringify(workshop);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };

  return axios
    .post(API_CREATE_WORKSHOPS, body, {
      headers
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
      return error.response;
    });
}

/////// Get workshop detail info active
function getWorkshopDetailService(workshop_uuid, company_uuid) {
  let API_GET_WORKSHOP_INFO =
    API_WORKSHOP_DETAIL + workshop_uuid + "/detail/?company_uuid=" + company_uuid;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .get(API_GET_WORKSHOP_INFO, { headers })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}

/////// Edit workshop detail info active
function editWorkshopDetailService(workshop_uuid, workshop) {
  let API_EDIT_WORKSHOP_INFO = API_WORKSHOP_DETAIL + workshop_uuid + "/edit/";

  const body = JSON.stringify(workshop);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .post(API_EDIT_WORKSHOP_INFO, body, { headers })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
    });
}
/////// Delete workshop detail info active
function deleteWorkshopDetailService(workshop_uuid) {
  let API_DELETE_WORKSHOP_INFO = API_WORKSHOP_DETAIL + workshop_uuid + "/delete/";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authHeaderAdmin()
  };
  return axios
    .delete(API_DELETE_WORKSHOP_INFO, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        forceReloadWindow();
      }
    });
}
