import { adminConstants } from '../_constants';

let admin = JSON.parse(localStorage.getItem('admin'));
const initialState = admin ? { loggedIn: true, admin } : {};

export function authAdmin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.LOGIN_ADMIN_REQUEST:
      return {
        loggingIn: true,
        admin: action.admin
      };
    case adminConstants.LOGIN_ADMIN_SUCCESS:
      return {
        loggedIn: true,
        admin: action.admin
      };
    case adminConstants.LOGIN_ADMIN_FAILURE:
      return {};
    case adminConstants.LOGOUT_ADMIN:
      return {};
    default:
      return state
  }
}