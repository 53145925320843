import React from "react";

import HeaderAdmin from "../Components/Header/HeaderAdmin";
import FooterAdmin from "../Components/Footer/FooterAdmin";


export function AdminNotFoundErrorPage (props) {
  return (
    <div class="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
      <HeaderAdmin />
      <div class="content">
        <div class="content-vertwo">
          <div class="login-box document-home">
            <div class="modal-content">
              <div class="modal-body">
                <h2 class="title-login text-left text-4d4150">
                  404 Page not found. <br/>
                  <small>ページが表示できません</small>
                </h2>
                <p class="title-login-content text-333333">
                  申し訳ございませんが、ページが正しく表示されませんでした。<br/>
                  ページを開き直しても正しく表示されない場合には、しばらく時間をおいてから再度お試しください。
                </p>
                <div class="from-group note text-left">
                  <p class="note-para">
                    <a class="link-submit" href="/login">ログイン画面へ戻る</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mail-magzine-copyright-scroll">© COACH A Co., Ltd. All Rights Reserved.</div>
      <FooterAdmin />
    </div>
  );
}
