import React from "react";
import { connect } from "react-redux";
import queryString from 'query-string';

import { userActions } from "../_actions";
import { userMessages } from "../_messages";
import { isStagingServer } from "../_helpers";

import Logomb from "../../assets/styles/img/Logover2.png";


class ChangePasswordFirstTime extends React.Component {

  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = {
      current_password:0,
      password: "",
      confirmed_password: "",
      submitted: false,
      key:params.token,
      agreementCheck:true,
      isStaging:false,
      pgmAgreementCheck: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAgreementCheck = this.onAgreementCheck.bind(this);
    this.onProgramAgreementCheck = this.onProgramAgreementCheck.bind(this);

  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  componentDidMount() {
    this.props.dispatch(userActions.checkFirstTimeLogin(this.state.key));
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }  
  }
  onAgreementCheck(e){
    // e.preventDefault();
   let isChecked = !this.state.agreementCheck;
   this.setState({agreementCheck:isChecked})
  }
  
  onProgramAgreementCheck(e){
    // e.preventDefault();
   let isChecked = !this.state.pgmAgreementCheck;
   this.setState({pgmAgreementCheck:isChecked})
  }
  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { current_password,password, confirmed_password,key } = this.state;
    const { dispatch } = this.props;
    // if (confirmed_password && password) {
    //   dispatch(userActions.changePassword(password, confirmed_password));
    // }
    if (this.isValidatorPassword(password, confirmed_password) === true) {
      dispatch(userActions.changePassword(current_password,password, confirmed_password, true,key));
    }
  }
  isValidatorPassword(password, confirmed_password) {
    let flag = false;
    if (!password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!confirmed_password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (confirmed_password !== password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_MATCH;
      this.props.alert.type = "alert-danger";
      return flag;
    }

    var strongRegex = new RegExp("[A-Za-z0-9@#$%^&+=]{8,}");
    const isOk = strongRegex.test(password);
    if (!isOk) {
      this.props.alert.message = userMessages.PASSWORD_IS_WEAK;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  }

  render() {
   const { alert } = this.props;
    const { confirmed_password, password } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo">
        {(this.state.isStaging===true) && (
           <div
             style={{backgroundColor:"#cac715",textAlign:"center",fontWeight:"bold",fontSize:"18px"}}
           >
	             <p>Staging</p>
           </div>
         )}
          <div className="header">
            <div className="header-content bg-color-F4F5F4">
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box">
                <div className="modal-content" style={{ background: "white" }}>
                  <div className="modal-body">
                  <h2 className="title-login text-left text-4d4150">
                    パスワード設定
                    </h2>
                    <form
                      className="container-form-input-login p-15"
                      name="form"
                      onSubmit={this.handleSubmit}
                      style={{ background: "white" }}
                    >
                      <div className="form-group">
                        <label htmlFor="mail-address">
                        新しいパスワード（英字大文字・英字小文字・数字（・記号@#$%^&+=のみ）を含む8文字以上）
                        </label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" form-group">
                        <label htmlFor="confirmed_password">
                          パスワードをもう一度入力
                        </label>
                        <input
                          name="confirmed_password"
                          type="password"
                          className="form-control"
                          id="confirmed_password"
                          value={confirmed_password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      {alert.type === "alert-danger" && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            {alert.message}
                          </div>
                        </div>
                      )}

                      <div className="from-group text-center">
                        <div className="change-password">
                          <p className="change-password-required-text">必須</p>
                          <div className="agreement-box">
                            <input
                              type="checkbox"
                              value="お客様の個人情報に関する同意事項"
                              onChange={this.onAgreementCheck}
                            />
                            <label>
                              <a
                                href="https://www.coacha.com/etc/privacy.html"
                                className="link-submit"
                                style={{fontWeight:'bold'}}
                                target="_blank"
                                rel="noreferrer"
                              >
                                お客様の個人情報に関する同意事項
                              </a>
                              <span className='agreement-box-info'>
                                に同意する
                              </span>
                            </label>
                          </div>
                        </div>

                        <div className="change-password">
                          <p className="change-password-required-text">必須</p>
                          <div className="agreement-box">
                            <input
                              type="checkbox"
                              value="プログラム参加同意事項"
                              onChange={this.onProgramAgreementCheck}
                            />
                            <label>
                              <a
                                href="https://www.coacha.com/etc/agreement_dcd_g-module.html"
                                className="link-submit"
                                style={{fontWeight:'bold'}}
                                target="_blank"
                                rel="noreferrer"
                              >
                                プログラム参加同意事項 
                              </a>
                              <span className='agreement-box-info'>
                                 に同意する
                              </span>
                            </label>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="bnt-submit bg-color-4D4150 mb-3"
                          disabled={this.state.agreementCheck || this.state.pgmAgreementCheck}
                        >
                          パスワードを設定する
                        </button>
                      </div>
                      {alert.type === "alert-success" && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            {alert.message}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
{/*           <Footer></Footer> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedChangePasswordFirstTime = connect(mapStateToProps)(
  ChangePasswordFirstTime
);
export { connectedChangePasswordFirstTime as ChangePasswordFirstTime };
