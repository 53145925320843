import React from "react";
import NavItem from "../NavItem";

const Footer = props => {
  return (
    <footer className="footer footer-fix">
      <div className="content-footer">
        <NavItem {...props}></NavItem>
      </div>
    </footer>
  );
};
export default Footer;
