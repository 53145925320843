import React from "react";
import emailValidator from "email-validator";
import { connect } from "react-redux";
import { alertActions, loginActions } from "../_actions";
import { isStagingServer } from "../../_helpers";
import {userAdminMessages} from "../_messages"
import Loginpc from "../../../assets/styles/img/logologin.png";
import ErrorIcon from "../../../assets/styles/img/error.png";
import SuccessIcon from "../../../assets/styles/img/success.png";

class AdminForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false,
      isStaging:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }   
  }

  validateEmail(email) {
    if (emailValidator.validate(email)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username } = this.state;
    const { dispatch } = this.props;
    if (username && this.validateEmail(username)) {
      dispatch(loginActions.forgotPasswordAdminAction(username.toLowerCase()));
    } else {
      dispatch(alertActions.error(userAdminMessages.EMAIL_NOT_TRUE));
    }
  }

  render() {
    const { alert } = this.props;
    const { username } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper login">
        {(this.state.isStaging===true) && (
           <div
             style={{textAlign:"center",fontWeight:"bold",fontSize:"18px",backgroundColor:"rgb(178, 92, 96)",color:"#fff"}}
           >
	             <font>Staging</font>
           </div>
         )}
          <div className="login-content">
            <div className="logo-login">
              <a href="#/" className="link-logo-login" onClick={event => event.preventDefault()}>
                <img src={Loginpc} alt="" className="icon-logo-login" />
              </a>
            </div>
            <div className="form-login">
              <div className="title-login">パスワードをお忘れの場合</div>
              <div className="title-detail-login">
                登録されているメールアドレスを入力し、送信してください。入力したメー
                <br />{" "}
                ルアドレスにパスワード再設定のための確認メールが送信されます。
              </div>
              <div className="form-login-content">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className="form-group d-block">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-form"
                    >
                      メールアドレス
                    </label>
                    <div className="form-input">
                      <input
                        name="username"
                        type="input"
                        className="form-control"
                        value={username}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {alert.message && (
                    <div className={`danger-cm ${alert.type}`}>
                      {alert.type === "alert-success" ? (
                        <img src={SuccessIcon} alt="" />
                      ) : (
                        <img src={ErrorIcon} alt="" />
                      )}
                      <span className="error-detail">{alert.message}</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="btn-login text-center mt-24">
              <button
                className=" btn bnt-submit-form-phase2 bnt-submit-form1"
                onClick={this.handleSubmit}
              >
                確認メールを送信する
              </button>
            </div>
          </div>
          <div className="footer-btn fix-position-footer-btn">
            <div className="wrap-button text-center">
              <p className="design-by text-center">
                © COACH A Co., Ltd. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedAdminForgotPasswordPage = connect(mapStateToProps)(
  AdminForgotPasswordPage
);
export { connectedAdminForgotPasswordPage as AdminForgotPasswordPage };
