import React, { Component } from "react";
import Content from "../_components/Header/ContentDownloadHeader";
import Staticimg from "../../assets/styles/img/3mincoach_Bookcover.jpg";

const CONTENT_DOWNLOAD_BOOK1 =
  process.env.URL_API + "/content-download/book/1/";
const CONTENT_DOWNLOAD_BOOK2 =
  process.env.URL_API + "/content-download/book/2/";
export class ContentDownload extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
        window.location.href = CONTENT_DOWNLOAD_BOOK1;
  };
  handleSubmit1 = (e) => {
    e.preventDefault();
    window.location.href = CONTENT_DOWNLOAD_BOOK2;
  };
  render() {
    return (
      <>
        <div className="divw">
          <div className="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
            <Content></Content>

            <div className="content">
              <div className="content-vertwo">
                <div className="login-box document-home ">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="content-download-div-center">
                        <img
                          src={Staticimg}
                          className="content-download-bookcover"
                          title=""
                          alt=""
                        ></img>
                      </div>
                      <div className="content-download-description">
                        『コーチングノート』は『3分間コーチ』をもとに開発された日付記入式ワークブックです。（株式会社ディスカヴァー・トゥエンティワン出版）ワークショップご参加後、フォローアップメールマガジンと共に「3分間コーチ」を実践するためのツールとしてお役立てください。
                      </div>
                      <div className="content-download-div-form text-center">
                        <div>
                          <form name="form" onSubmit={this.handleSubmit}>
                            <p className="label-content">テキストコンテンツ</p>
                            <button type="submit" className="content-download-btn">
                              ダウンロードする
                            </button>
                          </form>
                        </div>
                        <form name="form" onSubmit={this.handleSubmit1}>
                            <p className="label-content"> 記入式コンテンツ</p>
                            <button type="submit" className="content-download-btn">
                              ダウンロードする
                            </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="content-download-div-form text-center">
                    <p className="content-download-message">
                      {" "}
                      ※各PDFファイルは100MB以上のため、 
                      <br />ダウンロードの際はネットワーク環境にご留意ください。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
        </div>
      </>
    );
  }
}
export default ContentDownload;
