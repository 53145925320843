import React from "react";

import { connect } from "react-redux";
import moment from "moment";
import emailValidator from "email-validator";
import { userAdminActions, workshopAdminActions } from "../_actions";

import { validateMessages } from "../_messages";

import "../../../assets/styles/css/main-phase-2.css";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";

import ErrorIcon from "../../../assets/styles/img/error.png";
class UserRegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_user_admin: "",
      employee_id: undefined,
      first_name: "",
      last_name: "",
      first_furigana: "",
      last_furigana: "",
      email: "",
      username: "",
      is_send_email: "",
      birthday_year: "",
      birthday_month: "",
      birthday_day: "",
      sex: undefined,
      department: "",
      position_department: "",
      company_date_joined_year: "",
      company_date_joined_month: "",
      workshop_id: "",
      company_id: "",
      company_uuid:"",
      remarks: "",

      alert_validate_email: "",
      alert_validate_first_name: "",
      alert_validate_last_name: "",
      alert_validate_birthday: "",
      alert_validate_workshop: "",
      alert_validate_company_date_joined: "",
      listWorkshop: [],
      isShowNotHasWorkshop: false,
      user_uuid: ""
    };
  }
  componentDidMount = () => {
    //Component vua load het trang

    if(this.state.is_send_email===""){
      this.setState({is_send_email:true});
    }

    this.props.dispatch(
      workshopAdminActions.getListWorkshopsAction(
        this.props.match.params.companyUUID
      )
    );

    if (this.props.match.params.userUUID) {
      this.setState({ user_uuid: this.props.match.params.userUUID });
      this.props.dispatch(
        userAdminActions.getUserInfoAction(
          this.props.match.params.userUUID
        )
      );
    } else {
      this.setState({ company_uuid: this.props.match.params.companyUUID });
      this.props.dispatch(
        userAdminActions.getCompanyDetailInfoAction(
          this.props.match.params.companyUUID
        )
      );
    }
  };
  componentDidUpdate = prevProps => {
    //trang thai updating
    if (prevProps.workshopsAdmin !== this.props.workshopsAdmin) {
      this.setState({
        listWorkshop: this.props.workshopsAdmin
      });
    }

    if (this.props.match.params.userUUID) {
      if (prevProps.userInfo !== this.props.userInfo) {
        this.getInfoDataUser(this.props.userInfo);
      }
    }
  };

  getInfoDataUser = user => {
    var birthday = new Date(user.birthday);
    var company_date_joined = new Date(user.company_date_joined);

    this.setState({
      email: user.email ? user.email : "",
      first_name: user.first_name ? user.first_name : "",
      last_name: user.last_name ? user.last_name : "",
      birthday_year: user.birthday ? birthday.getFullYear() : "",
      birthday_month: user.birthday ? birthday.getMonth() + 1 : "",
      birthday_day: user.birthday ? birthday.getDate() : "",
      company_date_joined_year: user.company_date_joined
        ? company_date_joined.getFullYear()
        : "",
      company_date_joined_month: user.company_date_joined
        ? company_date_joined.getMonth() + 1
        : "",
      is_user_admin: user.is_admin,
      employee_id: user.employee_id ? user.employee_id : undefined,
      first_furigana: user.first_furigana ? user.first_furigana : "",
      last_furigana: user.last_furigana ? user.last_furigana : "",
      is_send_email: user.is_send_email,
      sex: user.sex,
      department: user.department ? user.department : "",
      position_department: user.position_department
        ? user.position_department
        : "",
      workshop_id: user.workshop_id ? user.workshop_id : "",
      remarks: user.remarks ? user.remarks : ""
    });
  };
  alertAtPage = (name_of_alert, alertInfo) => {
    this.setState({ [name_of_alert]: alertInfo });
  };
  isCheckedRadio = () => {};
  isValidatorNameNotNull = (first_name, last_name) => {
    let flag = true;
    var alertInfoFirstName = {
      type: "",
      message: ""
    };
    var alertInfoLastName = {
      type: "",
      message: ""
    };
    if (first_name === "") {
      alertInfoFirstName.type = "alert_validate_first_name";
      alertInfoFirstName.message = validateMessages.FIRST_NAME_NOT_NULL;
      flag = false;
      this.alertAtPage("alert_validate_first_name", alertInfoFirstName);
    } else this.alertAtPage("alert_validate_first_name", "");

    if (last_name === "") {
      alertInfoLastName.type = "alert_validate_last_name";
      alertInfoLastName.message = validateMessages.LAST_NAME_NOT_NULL;
      flag = false;
      this.alertAtPage("alert_validate_last_name", alertInfoLastName);
    } else this.alertAtPage("alert_validate_last_name", "");

    if (flag === true) {
      return true;
    } else return false;
  };
  isValidatorEmail = email => {
    let flag = false;
    var alertInfo = {
      type: "",
      message: ""
    };
    if (!email) {
      alertInfo.type = "alert_validate_email";
      alertInfo.message = validateMessages.EMAIL_NOT_NULL;
      this.alertAtPage("alert_validate_email", alertInfo);
      return flag;
    }
    if (!emailValidator.validate(email)) {
      alertInfo.type = "alert_validate_email";
      alertInfo.message = validateMessages.EMAIL_NOT_TRUE;
      this.alertAtPage("alert_validate_email", alertInfo);
      return flag;
    }
    this.alertAtPage("alert_validate_email", "");
    flag = true;
    return flag;
  };
  isValidatorBirthday = (birthday_year, birthday_month, birthday_day) => {
    let flag = false;
    if (birthday_year === "" && birthday_month === "" && birthday_day === "") {
      return true;
    }

    var alertInfo = {
      type: "",
      message: ""
    };
    var numberRegex = new RegExp("^[0-9]+$");
    var isOk = numberRegex.test(birthday_year);
    if (isOk === true) isOk = numberRegex.test(birthday_month);
    if (isOk === true) isOk = numberRegex.test(birthday_day);

    if (!isOk) {
      alertInfo.type = "alert_validate_birthday";
      alertInfo.message = validateMessages.NUMBER_NOT_TRUE;
      this.alertAtPage("alert_validate_birthday", alertInfo);
      return flag;
    }

    birthday_month = ("0" + birthday_month).slice(-2);
    let birthday = birthday_year + "-" + birthday_month + "-" + birthday_day;
    let isValidBirthday = moment(
      birthday,
      "YYYY-MM-DD",
      true
    ).isValid();
    if (!isValidBirthday) {
      alertInfo.type = "alert_validate_birthday";
      alertInfo.message = validateMessages.DATE_NOT_EXIST;
      this.alertAtPage("alert_validate_birthday", alertInfo);
      return flag;
    }

    this.alertAtPage("alert_validate_birthday", "");
    flag = true;
    return flag;
  };
  isValidatorCompanyDateJoined = (
    company_date_joined_year,
    company_date_joined_month
  ) => {
    let flag = false;
    if (company_date_joined_year === "" && company_date_joined_month === "") {
      return true;
    }
    var alertInfo = {
      type: "",
      message: ""
    };
    var numberRegex = new RegExp("^[0-9]+$");
    var isOk = numberRegex.test(company_date_joined_year);
    if (isOk === true) isOk = numberRegex.test(company_date_joined_month);

    if (!isOk) {
      alertInfo.type = "alert_validate_company_date_joined";
      alertInfo.message = validateMessages.NUMBER_NOT_TRUE;
      this.alertAtPage("alert_validate_company_date_joined", alertInfo);
      return flag;
    }

    company_date_joined_month = ("0" + company_date_joined_month).slice(-2);
    let company_date_joined = company_date_joined_year + "-" + company_date_joined_month;
    let isValidCompanyDateJoined = moment(
      company_date_joined,
      "YYYY-MM",
      true
    ).isValid();
    
    if (!isValidCompanyDateJoined) {
      alertInfo.type = "alert_validate_company_date_joined";
      alertInfo.message = validateMessages.DATE_NOT_EXIST;
      this.alertAtPage("alert_validate_company_date_joined", alertInfo);
      return flag;
    }

    this.alertAtPage("alert_validate_company_date_joined", "");
    flag = true;
    return flag;
  };
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name] : value });
  };
  handleRadioChange = event => {
    const { name, value } = event.target;
    const stateValue = value === "1" ? true : false;
    this.setState({ [name] : stateValue });
  }
  numberOfMonths = () => {
    let listOptionsMonth = [];
    for (var i = 1; i <= 12; i++) {
      listOptionsMonth.push(<option key={i}>{i}</option>);
    }
    return listOptionsMonth;
  };
  numberOfDays = () => {
    let listOptionsDay = [];
    for (var i = 1; i <= 31; i++) {
      listOptionsDay.push(<option key={i}>{i}</option>);
    }
    return listOptionsDay;
  };
  submitFormSave = () => {
    const {
      email,
      first_name,
      last_name,
      birthday_year,
      birthday_month,
      birthday_day,
      company_date_joined_year,
      company_date_joined_month,

      is_user_admin,
      employee_id,
      first_furigana,
      last_furigana,

      is_send_email,
      sex,
      department,
      position_department,
      workshop_id,
      remarks
    } = this.state;
    let isValidateMail = this.isValidatorEmail(email);
    let isValidateName = this.isValidatorNameNotNull(first_name, last_name);
    let isValidateBirthday = this.isValidatorBirthday(
      birthday_year,
      birthday_month,
      birthday_day
    );
    let isValidateCompanyDateJoined = this.isValidatorCompanyDateJoined(
      company_date_joined_year,
      company_date_joined_month
    );

    let birthday_check = "";
    let day_join_check = "";
    if (birthday_year) {
      birthday_check = birthday_year;
    }
    if (birthday_month) {
      birthday_check = birthday_check + "-" + birthday_month;
    }
    if (birthday_day) {
      birthday_check = birthday_check + "-" + birthday_day;
    }

    if (company_date_joined_year) {
      day_join_check = company_date_joined_year;
    }
    if (company_date_joined_month) {
      day_join_check = day_join_check + "-" + company_date_joined_month + "-1";
    }

    if (
      isValidateMail === true &&
      isValidateName === true &&
      isValidateBirthday === true &&
      isValidateCompanyDateJoined === true
    ) {
      let user = {
        is_user_admin: is_user_admin === "" ? false : is_user_admin,
        employee_id: employee_id ? employee_id : undefined,
        first_name: first_name,
        last_name: last_name,
        first_furigana: first_furigana,
        last_furigana: last_furigana,
        email: email.toLowerCase(),
        username: email.toLowerCase(),
        is_send_email: is_send_email === "" ? false : is_send_email,
        birthday: birthday_check,
        sex: sex,
        department: department,
        position_department: position_department,
        company_date_joined: day_join_check,
        workshop_id: workshop_id,
        company_id: this.props.company.id
      };
      if (remarks) {
        user["remarks"] = remarks;
      }
      if (this.state.user_uuid) {
        this.props.dispatch(
          userAdminActions.updateUserAction(
            user,
            this.state.user_uuid,
            this.props.match.params.companyUUID
          )
        );
      } else {
        this.props.dispatch(
          userAdminActions.createUserAction(
            user,
            this.props.match.params.companyUUID
          )
        );
      }
    }
  };
  onClickDeleteUser = () => { 
    const list_users = [this.props.userInfo];
    this.props.history.push({
      pathname: `/admin/${this.props.match.params.companyUUID}/admin-confirm-completed/2`,
      state: { users: list_users, company: this.props.match.params.companyUUID },
    }) 
  };
  render() {
    const { alert, company } = this.props;
    const { is_send_email, is_user_admin } = this.state;

    let isCheckedSendMailRadio;
    if (is_send_email === true || is_send_email==="") {
      isCheckedSendMailRadio = true;
    }else{
      isCheckedSendMailRadio = false;
    }
    let isCheckedAdminRadio;
    if (is_user_admin === true) {
      isCheckedAdminRadio = true;
    }
    const {  admin } = this.props;

    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies user-registration">
          <HeaderAdmin isCoachaAdmin="true"></HeaderAdmin>
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb active-line">
                  <span className="content-item color-4d4150-header-title">
                    ユーザー情報の登録・変更
                  </span>
                </li>
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {company.name}
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-930 mt-40">
              <div className="content-form-580">
                <div className="form-box form-box-place mb-3">
                  <form>
                    <div className="form-group d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form label-box-radio"
                      >
                        ユーザー権限
                      </label>
                      <div className="form-input form-input-radio">
                        <label className="container-radio">
                          一般参加者
                          <input
                            type="radio"
                            name="is_user_admin"
                            value={0}
                            onClick={this.handleRadioChange}
                            defaultChecked={!isCheckedAdminRadio}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="container-radio">
                          管理者
                          <input
                            type="radio"
                            name="is_user_admin"
                            value={1}
                            onClick={this.handleRadioChange}
                            defaultChecked={isCheckedAdminRadio}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="form-group  form-group-id  d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        社員ID
                      </label>
                      <div className="form-input">
                        <input
                          type="input"
                          name="employee_id"
                          className="form-control"
                          placeholder="社員ID"
                          onChange={this.handleChange}
                          value={this.state.employee_id}
                        />
                      </div>
                    </div>
                    <div className="group-form-control clearfix mb-20">
                      <div className="form-group d-block mb-10">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          名前 <span className="danger-box">必須</span>
                        </label>
                        <div className="form-input">
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="姓"
                            onChange={this.handleChange}
                            value={this.state.last_name}
                          />
                        </div>
                      </div>
                      <div className="form-group d-block mb-10">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          &nbsp;
                        </label>
                        <div className="form-input">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="名"
                            onChange={this.handleChange}
                            value={this.state.first_name}
                          />
                        </div>
                      </div>
                      {this.state.alert_validate_last_name ? (
                        <div className="danger-cm mt-10 clearboth">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_last_name.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.alert_validate_first_name ? (
                        <div className="danger-cm mt-10 clearboth">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_first_name.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="group-form-control clearfix mb-20">
                      <div className="form-group  d-block mb-10">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          名前（ふりがな）
                        </label>
                        <div className="form-input">
                          <input
                            type="text"
                            className="form-control"
                            name="last_furigana"
                            placeholder="せい"
                            onChange={this.handleChange}
                            value={this.state.last_furigana}
                          />
                        </div>
                      </div>
                      <div className="form-group  d-block mb-10">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          &nbsp;
                        </label>
                        <div className="form-input">
                          <input
                            type="text"
                            className="form-control"
                            name="first_furigana"
                            placeholder="めい"
                            onChange={this.handleChange}
                            value={this.state.first_furigana}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group  form-group-mail  d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        メールアドレス <span className="danger-box">必須</span>
                      </label>
                      <div className="form-input">
                        <input
                          type="input"
                          className="form-control"
                          name="email"
                          placeholder="example@coach.co.jp"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                      </div>
                      {this.state.alert_validate_email ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_email.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {alert.name==="email" && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    </div>
                    <div className="form-group d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form label-box-radio"
                      >
                        メルマガ配信
                      </label>
                      <div className="form-input form-input-radio">
                        <label className="container-radio">
                          ON
                          <input
                            type="radio"
                            name="is_send_email"
                            value={1}
                            onClick={this.handleRadioChange}
                            defaultChecked={isCheckedSendMailRadio}
                            checked={isCheckedSendMailRadio}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="container-radio">
                          OFF
                          <input
                            type="radio"
                            name="is_send_email"
                            value={0}
                            onClick={this.handleRadioChange}
                            checked={!isCheckedSendMailRadio}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="group-form-control group-form-control-seclect clearfix mb-20">
                      <div className="form-group  form-group-select  d-block mb-20">
                        <label
                          htmlFor="exampleInputEmail9"
                          className="label-box-form"
                        >
                          生年月日
                        </label>
                        <div className="form-input">
                          <input
                            type="year"
                            className="form-control"
                            name="birthday_year"
                            placeholder="19XX"
                            onChange={this.handleChange}
                            value={this.state.birthday_year}
                          />
                        </div>
                      </div>
                      <div className="d-inline-block space">年</div>
                      <div className="form-group d-block">
                        <label htmlFor="exampleSelect1">&nbsp;</label>
                        <div className="form-select">
                          <select
                            className="form-control"
                            name="birthday_month"
                            onChange={this.handleChange}
                            value={this.state.birthday_month}
                          >
                            <option defaultValue="" hidden>
                              選択
                            </option>
                            {this.numberOfMonths()}
                          </select>
                        </div>
                      </div>
                      <div className="d-inline-block space">月</div>
                      <div className="form-group d-block">
                        <label htmlFor="exampleSelect1">&nbsp;</label>
                        <div className="form-select">
                          <select
                            className="form-control"
                            name="birthday_day"
                            onChange={this.handleChange}
                            value={this.state.birthday_day}
                          >
                            <option defaultValue="" hidden>
                              選択
                            </option>
                            {this.numberOfDays()}
                          </select>
                        </div>
                      </div>
                      <div className="d-inline-block space">日</div>

                      {this.state.alert_validate_birthday ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_birthday.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group form-group-gender d-block">
                      <label
                        htmlFor="exampleSelect1"
                        className="label-box-form"
                      >
                        性別
                      </label>
                      <div className="form-select">
                        <select
                          name="sex"
                          className="form-control"
                          onChange={this.handleChange}
                        >
                          <option defaultValue="" hidden>
                            {this.state.sex === undefined ? "選択" : ""}
                            {this.state.sex === true ? "男性" : ""}
                            {this.state.sex === false ? "女性" : ""}
                          </option>
                          <option value={1}>男性</option>
                          <option value={0}>女性</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group  form-group-position  d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        役職
                      </label>
                      <div className="form-input">
                        <input
                          type="input"
                          className="form-control"
                          name="position_department"
                          onChange={this.handleChange}
                          value={this.state.position_department}
                        />
                      </div>
                    </div>
                    <div className="form-group  form-group-deploy  d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        部署
                      </label>
                      <div className="form-input">
                        <input
                          type="input"
                          className="form-control"
                          name="department"
                          onChange={this.handleChange}
                          value={this.state.department}
                        />
                      </div>
                    </div>
                    <div className="group-form-control group-form-control-seclect clearfix mb-20">
                      <div className="form-group  form-group-select  d-block mb-20">
                        <label
                          htmlFor="exampleInputEmail9"
                          className="label-box-form"
                        >
                          入社月
                        </label>
                        <div className="form-input">
                          <input
                            type="email"
                            className="form-control"
                            name="company_date_joined_year"
                            placeholder="19XX"
                            onChange={this.handleChange}
                            value={this.state.company_date_joined_year}
                          />
                        </div>
                      </div>
                      <div className="d-inline-block space">年</div>
                      <div className="form-group d-block">
                        <label htmlFor="exampleSelect1">&nbsp;</label>
                        <div className="form-select">
                          <select
                            className="form-control"
                            name="company_date_joined_month"
                            onChange={this.handleChange}
                            value={this.state.company_date_joined_month}
                          >
                            <option defaultValue="" hidden>
                              選択
                            </option>
                            {this.numberOfMonths()}
                          </select>
                        </div>
                      </div>
                      <div className="d-inline-block space">月</div>

                      {this.state.alert_validate_company_date_joined ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {
                              this.state.alert_validate_company_date_joined
                                .message
                            }
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="link-tolip mb-20">
                      ワークショップ日程
                      <span className="link-totip-content">
                        <i
                          className="fa fa-question-circle"
                          aria-hidden="true"
                        />
                        <span
                          onClick={() => {
                            this.setState({
                              isShowNotHasWorkshop: !this.state
                                .isShowNotHasWorkshop
                            });
                          }}
                        >
                          参加日程が選択肢にない場合
                        </span>
                        {this.state.isShowNotHasWorkshop === true ? (
                          <span className="toltip-position">
                            予定されている日程の記載がない場合は、ユーザー一覧画面に戻り、「ワークショップ日程を登録・変更する」ボタンを押して登録してください。
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="form-group d-block">
                      <div className="form-select">
                        <select
                          className="form-control"
                          name="workshop_id"
                          onChange={this.handleChange}
                          value={this.state.workshop_id}
                        >
                          <option defaultValue="" value="">
                            選択
                          </option>
                          {this.state.listWorkshop.map(workshop => (
                            <option key={workshop.id} value={workshop.id}>
                              {workshop.code} | {workshop.date_workshop} |
                              {workshop.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {this.state.alert_validate_workshop ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_workshop.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {alert.name==="workshop_id" && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    </div>
                    {admin.data.is_supper_admin === true ? (
                      
                    <div className="form-input">
                      <label
                      htmlFor="exampleInputRemarks"
                      className="label-box-form"
                    >
                      備考
                      <p style={{display:"inline",marginLeft:"15px",color:"#333333",fontWeight:"bold"}}>※</p>
                    </label>
                          <input
                            type="text"
                            className="form-control"
                            name="remarks"
                            onChange={this.handleChange}
                            value={this.state.remarks}
                          />
                      </div>
                      ) : (
                        ""
                      )}

                    <div className="btn-login text-left mt-20">
                      <button
                        type="button"
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mr-20"
                        onClick={this.submitFormSave}
                      >
                        保存する
                      </button>
                      <a
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status"
                        href={`/admin/detail-company/${this.props.match.params.companyUUID}`}
                      >
                        戻る
                      </a>
                    </div>

                    {((alert.name !== "email") && (alert.name !=="workshop_id" )) && alert.message &&(
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              {this.props.match.params.userUUID ? (
                <button
                  type="button"
                  className=" btn bnt-submit-form bnt-submit-form4"
                  onClick={this.onClickDeleteUser}
                >
                  ユーザーを削除
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <FooterAdmin></FooterAdmin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    userAdminReducer,
    workshopAdminReducer,
    authAdminReducer,
    alert
  } = state;
  const { admin } = authAdminReducer;
  const { listUsers, activePercent, userInfo, company } = userAdminReducer;
  const { workshopsAdmin } = workshopAdminReducer;
  return {
    listUsers,
    workshopsAdmin,
    activePercent,
    admin,
    alert,
    userInfo,
    company
  };
}

const connectedUserRegistrationPage = connect(mapStateToProps)(
  UserRegistrationPage
);
export { connectedUserRegistrationPage as UserRegistrationPage };
