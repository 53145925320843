import queryString from 'query-string';
import { userActions } from "../_actions";
import { isStagingServer } from "../_helpers";
import React from "react";
import Content from "../_components/Header/ContentDownloadHeaderToken";
import Staticimg from "../../assets/styles/img/3mincoach_Bookcover.jpg";
import { connect } from 'react-redux'
import Modal from 'react-awesome-modal';
import emailValidator from "email-validator";
import ErrorIcon from "../../assets/styles/img/error.png";

const mapDispatchToProps = dispatch => ({
  dispatch                
})

const initialState = { }

class ContentDownloadToken extends React.Component {

  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = {
      key:params.token,
      visible : false,
      email : "",
      alert_validate_email: "",
      status : 0,
      contentdownload :[],
      emailerror: false,
      errormessage :"登録されていないメールアドレスです。"
     
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  componentDidMount() {
    this.props.dispatch(userActions.checkContentDownloadToken(this.state.key));
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }  
  }
  openModal = () => {
    this.setState({visible : true});
  }
  alertAtPage = (name_of_alert, alertInfo) => {
    this.setState({ [name_of_alert]: alertInfo });
  };
  isValidatorEmail = (email) => {
    let flag = false;
    var alertInfo = {
      type: "",
      message: "",
    };
    if (!email) {
      alertInfo.type = "alert_validate_email";
      alertInfo.message = "メールアドレスが入力されていません";
      this.alertAtPage("alert_validate_email", alertInfo);
      this.setState({ emailerror : false });
      return flag;
    }
    if (!emailValidator.validate(email)) {
      alertInfo.type = "alert_validate_email";
      alertInfo.message = "不正なメールアドレスです";
      this.alertAtPage("alert_validate_email", alertInfo);
      this.setState({ emailerror : false });
      return flag;
    }
    this.alertAtPage("alert_validate_email", "");
    flag = true;
    return flag;
  };
  closeModal = () => {
    this.setState({visible : false, disabled :false,email: "",visible : false,emailerror: false, alert_validate_email: ""});
  }

  handleSubmit = (e) => {
    e.preventDefault();
     this.state.book = 1
    this.openModal();
  };
  handleSubmit1 = (e) => {
    e.preventDefault();
    this.state.book = 2
    this.openModal();
  };
  submitFormSave = (prevProps) => {
    const { email } = this.state;
   
    let isValidateMail = this.isValidatorEmail(email);
     if(isValidateMail === true)
     {
      this.props.dispatch(userActions.checkContentDownloadEmail(encodeURIComponent(email),this.state.book));
      setTimeout(() => {    
      if (prevProps.contentDownload !== this.props.contentDownload) {
        this.setState({ status : this.props.contentDownload.code }); 
      if (this.state.status == 200)
      {
        this.setState({ emailerror : false }); 
        this.closeModal();
      }
      else if (this.state.status == 404)
      {
        this.setState({ emailerror : true });
      }
    }
    }, 500);
     }
  }
  render() {
    const { email } = this.state;
    return (
      <>
        <div className="divw">
          <div className="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
            <Content></Content>

            <div className="content">
              <div className="content-vertwo">
                <div className="login-box document-home ">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="content-download-div-center">
                        <img
                          src={Staticimg}
                          className="content-download-bookcover"
                          title=""
                          alt=""
                        ></img>
                      </div>
                      <div className="content-download-description">
                        『コーチングノート』は『3分間コーチ』をもとに開発された日付記入式ワークブックです。（株式会社ディスカヴァー・トゥエンティワン出版）ワークショップご参加後、フォローアップメールマガジンと共に「3分間コーチ」を実践するためのツールとしてお役立てください。
                      </div>
                      <div className="content-download-div-form text-center">
                        <div>
                          <form name="form" onSubmit={this.handleSubmit}>
                            <p className="label-content">テキストコンテンツ</p>
                            <button type="submit" className="content-download-btn">
                              ダウンロードする
                            </button>
                          </form>
                        </div>
                        <form name="form" onSubmit={this.handleSubmit1}>
                            <p className="label-content"> 記入式コンテンツ</p>
                            <button type="submit" className="content-download-btn">
                              ダウンロードする
                            </button>
                        </form>
                      </div>
                    </div>
                    <Modal visible={this.state.visible} width="300" height="371" onClickAway={() => this.closeModal()}>
            {/* Modal content*/}
            <div id="assessment-modal" className="modal-content">
            <form
                      className="container-form-input-login p-15-1"
                      name="form"
                     
                 
                    >
              <div className="modal-body">
                <div className="pt-5">
                
                <div className="form-group">
                        <label htmlFor="mail-address">メールアドレス</label>
                        <label for="mail-text">メールマガジンを受信したメールアドレスをご入力ください。</label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="example@coach.co.jp"
                          value={email}
                          autoComplete="off"
                          onChange={this.handleChange}
                        />
                      </div>
                      {this.state.alert_validate_email ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.alert_validate_email.message}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.emailerror ? (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />
                          <span className="error-detail">
                            {this.state.errormessage}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                </div>
                
              </div>
              <div className="modal-footer">
                <button

                  type="button"
                  className="content-download-btn mb-24"
                  onClick={this.submitFormSave}
                >
                  ダウンロード
                </button>

              </div>
              </form>
            </div>
           
        </Modal>
                  </div>
                  <div className="content-download-div-form text-center">
                    <p className="content-download-message">
                      {" "}
                      ※各PDFファイルは100MB以上のため、 
                      <br />ダウンロードの際はネットワーク環境にご留意ください。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
        </div>
      </>
    );
  }
  
}

function mapStateToProps(state) {
  const { users } = state;
  const { contentDownload }  = users
  return {
    contentDownload,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDownloadToken)