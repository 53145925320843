import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Pagination from "react-js-pagination";

import "../../../assets/styles/css/main-phase-2.css";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { getAccessTokenAdmin } from "../../_helpers";
import { companyActions, loginActions } from "../_actions";

class ListCompanyPage extends React.Component {
  constructor(props) {
    super(props);
      this.active = false;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;
    const admin = JSON.parse(localStorage.getItem("admin"));
    this.state = { admin };
    this.state = {
      activePage: 1,
      activeUserPage: 1,
      totalCompanies: 0,
      totalUsers: 0,
      filterId:0,
      visible :'',
      listColumnTableCompany: [
        { id: 0, name: "企業名", request: "sort_company_name", sortBy: 0 },
        { id: 1, name: "事務局様アカウント発行日", request: "sort_start_date", sortBy: 0 },
        { id: 2, name: "事務局様サイト閲覧期限", request: "sort_end_date", sortBy: 0 },
        { id: 3, name: "備考" },
      ],
      listColumnTableUserCompany: [
        { id: 0, name: "企業名"},
        { id: 1, name: "名前"},
        { id: 2, name: "メールアドレス"},
        { id: 3, name: "部署"},
        { id: 4, name: "ふりがな（姓）" },
        { id: 5, name: "ふりがな（名）"},
      ],
      sortByColumn: "",
      searchText: "",
      userSearchText: "",
      userSearchTextFlag: false,
      userCompanyList: [],
      listCompany :[],
      
    };
  }
  componentDidMount() {
    if (getAccessTokenAdmin() !== "") {
      this.props.dispatch(loginActions.checkLoginAdmin(getAccessTokenAdmin()));
      this.props.dispatch(
        loginActions.checkLoginRoleSupperAdmin(getAccessTokenAdmin())
      );
    }
    this.props.dispatch(companyActions.list(0));
  }
  componentDidUpdate = prevProps => {
    if (prevProps.listCompany !== this.props.listCompany) {
      this.setState({ totalCompanies: this.props.listCompany.count,
        listCompany : this.props.listCompany });
    }
    if (prevProps.listUserCompany !== this.props.listUserCompany) {
      this.setState({ userCompanyList: this.props.listUserCompany.results,
        totalUsers : this.props.listUserCompany.count });
   }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageChange(pageNumber) {
    if (this.state.userSearchTextFlag){
      if (pageNumber !== this.state.activeUserPage) {
        this.setState({activeUserPage : pageNumber},
          () => {this.props.dispatch(companyActions.userList(this.state.activeUserPage - 1,this.state.userSearchText));}
        );
      }
    } else {
      if (pageNumber !== this.state.activePage) {
        this.setState({activePage : pageNumber})
          if( this.active === true){
                this.setState({activePage : pageNumber},
                  () => {this.props.dispatch(companyActions.companyList(this.state.activePage-1,this.state.sortByColumn,0));}
                );
          }
          else if( this.active1 === true){
            this.setState({activePage : pageNumber},
              () => {this.props.dispatch(companyActions.companyList(this.state.activePage-1,this.state.sortByColumn,1));}
            );
          }
          else if( this.active2 === true){
            this.setState({activePage : pageNumber},
              () => {this.props.dispatch(companyActions.companyList(this.state.activePage-1,this.state.sortByColumn,2));}
            );
          }
          else if( this.active3 === true){
            this.setState({activePage : pageNumber},
              () => {this.props.dispatch(companyActions.companyList(this.state.activePage-1,this.state.sortByColumn,3));}
            );
          }
          else{   
            this.setState({activeUserPage : pageNumber},
              () => {this.props.dispatch(companyActions.list(this.state.activePage-1, this.state.sortByColumn, this.state.searchText));}
            );    
          }
      }
    }
  }

  onClickSortActive = (event) => {
    let id_column = parseInt(event.target.getAttribute("id"));
    let array_column_name = this.state.listColumnTableCompany;

    let sortByColumn = array_column_name[id_column].request;
    if (array_column_name[id_column].sortBy < 2)
      array_column_name[id_column].sortBy++;
    else array_column_name[id_column].sortBy = 0;

    array_column_name.forEach((column_name) => {
      if (column_name.id !== id_column) column_name.sortBy = 0;
    });
    if (array_column_name[id_column].sortBy === 1) {
      sortByColumn += "=0";
    }
    if (array_column_name[id_column].sortBy === 2) {
      sortByColumn += "=1";
    }
    if (array_column_name[id_column].sortBy === 0) {
      sortByColumn = "";
    }
    this.setState(
      {
        listColumnTableCompany: array_column_name,
        sortByColumn: sortByColumn,
      },
      () => this.requestListCompanySortSearch()
    );
  };

  requestListCompanySortSearch = () => {
    const {
      sortByColumn,
      searchText
    } = this.state;
    this.setState({userSearchTextFlag : false, activePage : 1});
    if( this.active === true){
        this.props.dispatch(companyActions.companyList(0,sortByColumn,0));
    }
    else if( this.active1 === true){
        this.props.dispatch(companyActions.companyList(0,sortByColumn,1));
    }
    else if( this.active2 === true){
        this.props.dispatch(companyActions.companyList(0,sortByColumn,2));
    }
    else if( this.active3 === true){
      this.props.dispatch(companyActions.companyList(0,sortByColumn,3));
    }
    else{
      this.props.dispatch(companyActions.list(0, sortByColumn, searchText));
    }
  };

  requestListCompanyUserSearch = () => {
    const { userSearchText } = this.state;
    this.setState({userSearchTextFlag : true, activeUserPage : 1});
    this.props.dispatch(companyActions.userList(0, userSearchText));
  };
  
  filterCompanyWithNoUsers = (event) => {
    event.preventDefault();
    this.filterId=0
    const {
      sortByColumn,
    } = this.state;
    this.buttonActive(this.filterId)
    this.setState({ activePage : 1});
    if( this.active === true){
      this.props.dispatch(companyActions.companyList(0,sortByColumn,this.filterId));
    }
    else{
      this.props.dispatch(companyActions.list(0));
    }
  }

  filterCompanyWithOneUser = (event) => {
    event.preventDefault();
    this.filterId=1
    const {
      sortByColumn,
    } = this.state;
    this.buttonActive(this.filterId)
    if( this.active1 === true){
      this.setState({activePage : 1});
      this.props.dispatch(companyActions.companyList(0,sortByColumn,this.filterId));
    }
    else{
      this.props.dispatch(companyActions.list(0));
    }
  }

  filterCompanyWithMailMagazinePeriod = (event) => {
    event.preventDefault();
    this.filterId=2
    const {
      sortByColumn,
    } = this.state;
    this.buttonActive(this.filterId)
    if( this.active2 === true){
      this.setState({activePage : 1});
      this.props.dispatch(companyActions.companyList(0,sortByColumn,this.filterId));
    }
    else{
      this.props.dispatch(companyActions.list(0));
    }
 
  }
  
  filterCompanyWithUserActive = (event) => {
    event.preventDefault();
    this.filterId=3
    const {
      sortByColumn,
    } = this.state;
    this.buttonActive(this.filterId)
    if( this.active3 === true){
      this.setState({activePage : 1});
      this.props.dispatch(companyActions.companyList(0,sortByColumn,this.filterId));
    }
    else{
      this.props.dispatch(companyActions.list(0));
    }
  }

  buttonActive = (filterId) => {
    if (filterId === 0){
      this.active1 = false
      this.active2 = false
      this.active3 = false
      this.active = !this.active
    }
    else if(filterId === 1){
      this.active2 = false
      this.active3 = false
      this.active  = false
      this.active1 = !this.active1
    }
    else if(filterId === 2){
      this.active1 = false
      this.active  = false
      this.active3 = false
      this.active2 = !this.active2
    }
    else if (filterId === 3){
      this.active1 = false
      this.active2 = false
      this.active =  false
      this.active3 = !this.active3
    }
    else{
      this.active1 = false
      this.active2 = false
      this.active =  false
      this.active3 = false
    }
  }

  handleSubmitSearch = (event) => {
    event.preventDefault();
    this.state.visible= 'visible'
    this.setState({ activePage: 1 }, () => {
      this.requestListCompanySortSearch();
    });
  };

  handleSubmitUserSearch = (event) => {
    event.preventDefault();
    this.state.visible= 'hidden'
    this.setState({ activePage: 1}, () => {
      this.requestListCompanyUserSearch();
    });
  };
  render() {
    const { searchText, userSearchText } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies">
          <HeaderAdmin isCoachaAdmin="true"></HeaderAdmin>
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item">企業一覧</span>
                  <p style={{display:"inline",marginLeft:"25px",color:"#333333",fontSize: "20px"}}>※</p>
                </li>
              </ul>
              <Link to="/admin/company/new" className="bnt-submit-cm bg-4D4150">
                企業を追加する
              </Link>
              <p style={{display:"inline",marginLeft:"25px",color:"#333333",fontWeight:"bold",fontSize:"20px",marginTop:"10px"}}>※</p>
            </div>
            <div className="content-140">
              <div className="wrap-table">
                <div className="form-table clearfix">
                  <div className="wrapform">
                    <div className="wrapbtn">
                      <div className="search-container">
                        <form onSubmit={this.handleSubmitSearch}>
                          <input
                            type="text"
                            placeholder="企業名で検索"
                            className="txt-search"
                            name="searchText"
                            value={searchText}
                            onChange={this.handleChange}
                          />
                          <button
                            type="button"
                            className="search"
                            onClick={this.handleSubmitSearch}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div style={{marginLeft:"10px"}}className="wrapform">
                    <div className="wrapbtn">
                      <div className="search-container">
                        <form onSubmit={this.handleSubmitUserSearch}>
                          <input
                            type="text"
                            placeholder="名前、メールアドレス、部署、ふりがなで検索"
                            className="txt-search"
                            name="userSearchText"
                            value={userSearchText}
                            onChange={this.handleChange}
                          />
                          <button
                            type="button"
                            className="search"
                            onClick={this.handleSubmitUserSearch}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </form>
                        </div>
                        <div className="link-tggle" style={{visibility: this.state.visible}} >
                        <button
                                
                                className={this.active? 'bnt-filter-active' : 'bnt-filter'}
                                onClick={this.filterCompanyWithNoUsers}
                              >
                           回答期間前
                        </button>
                        <button
                                className={this.active1? 'bnt-filter-active1' : 'bnt-filter1'}
                                onClick={this.filterCompanyWithOneUser}
                              >
                             回答期間中
                        </button>
                        <button
                                className={this.active2? 'bnt-filter-active2 bg-F4A460' : 'bnt-filter2 bg-F4A460'}
                                onClick={this.filterCompanyWithMailMagazinePeriod}
                              >
                             メルマガ配信中
                        </button>
                        <button
                                className={this.active3? 'bnt-filter-active3 bg-8FBC8F' : 'bnt-filter3 bg-8FBC8F'}
                                onClick={this.filterCompanyWithUserActive}
                              >
                             配信完了＆有効
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-wrap-content ">
                  <div className="box-theadtable">
                  {this.state.userSearchTextFlag ? (
                    <div className="user-companies">
                    <table
                      id="dtBasicExample"
                      className="table table-striped table-sm js-table"
                    >
                      <thead>                        
                        <tr>
                        {this.state.listColumnTableUserCompany.map(
                          (column) => (
                            <th
                              key={column.id}
                              className={`th-sm hiden-sort`}
                              id={column.id}
                            >
                              {column.name}
                            </th>
                          )
                        )}
                      </tr>
                      </thead>
                    </table>
                    </div>
                        ) : (
                    <table
                      id="dtBasicExample"
                      className="table table-striped table-sm js-table"
                    >
                      <thead>
                        <tr>
                          {this.state.listColumnTableCompany.map(
                            (column) => (
                              <th
                                key={column.id}
                                className={`th-sm ${
                                  column.sortBy === 1
                                    ? "active-th-dow"
                                    : ""
                                  } ${
                                  column.sortBy === 2
                                    ? "active-th-up"
                                    : ""
                                  }
                                          ${
                                  column.name === '備考' ?
                                    "hiden-sort" : ""
                                  }
                                        `}
                                id={column.id}
                                onClick={this.onClickSortActive}
                              >
                                {column.name}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      </table>                        
                        ) }
                  </div>
                  <div className="box-tbodytable">
                  {this.state.userSearchTextFlag ? (
                    <div className="user-companies">
                    <table className="table table-striped table-sm">
                      <tbody>
                      {this.state.userCompanyList.length !== 0 
                      ? this.state.userCompanyList.map((user) => (
                          <tr key={user.id}>
                            <td>
                              <span className="text-line color-689B88">
                                <a
                                  href={
                                    "/admin/detail-company/" +user.company_uuid
                                  }
                                  className="text-line color-689B88"
                                >
                                  {user.company}
                                </a>
                              </span>
                            </td>
                            <td>
                            <span className="text-line color-689B88">
                                <a
                                  href={
                                    "/admin/"+user.company_uuid+"/user-registration/"+user.uuid
                                  }
                                  className="text-line color-689B88"
                                >
                              {user.full_name}
                              </a>
                              </span>
                            </td>
                            <td>
                              {user.email}
                            </td>
                            <td>
                              {user.department}
                            </td>
                            <td>
                              {user.last_furigana}
                            </td>
                            <td>
                              {user.first_furigana}
                            </td>
                          </tr>
                        ))
                      : null}
                    </tbody>
                    </table>
                    </div>
                      ) : (
                    <table className="table table-striped table-sm">
                      <tbody>
                        {this.state.listCompany.results
                          ? Object.keys(this.state.listCompany.results).map((item, i) => (
                            <tr key={i}>
                              <td>
                                <span className="text-line color-689B88">
                                  <a
                                    href={
                                      "/admin/detail-company/" +
                                      this.state.listCompany.results[item].uuid
                                    }
                                    className="text-line color-689B88"
                                  >
                                    {this.state.listCompany.results[item].name}
                                  </a>
                                </span>
                              </td>
                              <td>
                                {
                                  this.state.listCompany.results[item]
                                    .date_contract_start
                                }
                              </td>
                              <td>
                                {this.state.listCompany.results[item].date_contract_end}
                              </td>
                              <td>
                                {this.state.listCompany.results[item].remark}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                      </table>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-comtainer-custom">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                hideDisabled
                activePage={this.state.userSearchTextFlag ? this.state.activeUserPage : this.state.activePage }
                itemsCountPerPage={20}
                totalItemsCount={this.state.userSearchTextFlag ? this.state.totalUsers : this.state.totalCompanies}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <FooterAdmin></FooterAdmin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { listCompany, listUserCompany } = state.companyReducer;
  return {
    alert,
    listCompany,
    listUserCompany
  };
}

const connectedListCompanyPage = connect(mapStateToProps)(ListCompanyPage);
export { connectedListCompanyPage as ListCompanyPage };
