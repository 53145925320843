// import config from "config";
import { authHeaderAdmin, forceReloadWindow, logout } from "../_helpers";

const API_QUESTION = process.env.URL_API;

export const questionAdminService = {
  getHistoryResult,
};

async function getHistoryResult(user_uuid) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeaderAdmin()
    }
  };
  const response = await fetch(
    API_QUESTION + `/v1/questions/history-result/${user_uuid}`,
    requestOptions
  );
  if (response.status === 401) {
    logout();
    forceReloadWindow();
    return;
  }
  const data = await response.json();
  return data;
}
