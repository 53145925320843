import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { authAdmin } from "./auth-admin.reducer";

import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { questions } from "./questions.reducer";
import { admin } from "./admin.reducer";
import { magazines } from "./magazine.reducer";
import { workshops } from "./workshop.reducer";

import { authAdminPhase2 } from "../AdminDashboard/_reducers/authentication.reducer";
import { userAdmin } from "../AdminDashboard/_reducers/userAdmin.reducer";
import { companyAdmin } from "../AdminDashboard/_reducers/company.reducer";
import { workshopsAdmin } from "../AdminDashboard/_reducers/workshopAdmin.reducer";
import { questionAdmin } from "../AdminDashboard/_reducers/questionAdmin.reducer";

const rootReducer = combineReducers({
  authentication,
  authAdmin,
  users,
  alert,
  questionsReducer: questions,
  admin,
  magazinesReducer: magazines,
  workshopReducer: workshops,
  authAdminReducer: authAdminPhase2,
  userAdminReducer: userAdmin,
  companyReducer: companyAdmin,
  workshopAdminReducer: workshopsAdmin,
  questionAdminReducer: questionAdmin,
});

export default rootReducer;
