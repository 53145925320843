import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { isStagingServer } from "../../_helpers";
import { Logout } from "../Logout";

import Group3mb from "../../../assets/styles/img/Group3-mb.png";
import SettingIcon from "../../../assets/styles/img/icon-setting-mb2.png";
import BackIcon from "../../../assets/styles/img/back.png";
import NavItem from "../NavItem";

const Header = props => {
  const isStaging = isStagingServer();

  const { consequenceActive, assessmentActive, articleActive, user,date } = props;
  let userInfo;
  if (user) {
    userInfo = user.data;
  } else userInfo = [];
  return (
    <div className="header content-logo-mb-horizontal-style-fix content-logo-mb-horizontal-fix-head">
      <div className="header-content">
      <div className="content-logo-mb-horizontal">
          {consequenceActive ? (        
            <Link to="/" className="link-back">
              <img src={BackIcon} alt="" />
            </Link>           
          ) : (
            ""
          )}
          {articleActive ? (
            <Link to="/mail-magazine" className="link-back-article">
              <img src={BackIcon} alt="" />
              <p>一覧へ戻る</p>
            </Link>
          ) : (
            ""
          )}
        {assessmentActive ? (
           <img src={Group3mb} alt="" />
          ) : (
            <span className="consequence-header">
            <Link className="article-logo" to="/">
            <img className={(props.articleActive==="true") ? "header-article-active" : "header-article-inactive"} src={Group3mb} alt="" />
          </Link>
          {consequenceActive ? (
          <p className="consequence-header-user">{userInfo.user_last_name +"   " +userInfo.user_first_name+"さん"}</p>):("")}
          <p className="consequence-header-date">回答日：{date}</p>
          </span>
          )}
     
           <a
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              href="#navbarSupportedContent"
            >
              <span className="setting-box">
                <img src={SettingIcon} alt="" />
              </span>
            </a>
          <div
            className="list-menu-mb mb-0 collapse navbar-collapse"
            id="navbarSupportedContent"
          >

          {assessmentActive ? (
            <ul className="list-menu-mb-ct">
              <li className="list-menu-mb-item">
                {userInfo !== "" ? (
                  <div className="list-menu-link">
                    <div className="icon-user-info">
                      <i className="fas fa-user text-center"></i>
                    </div>
                    <div className="name-user-info">
                      <span>
                        {userInfo.user_last_name +
                          " " +
                          userInfo.user_first_name}
                      </span>
                    </div>
                    <div className="email-user-info">
                      <span>{userInfo.email}</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </li>

              <li className="list-menu-mb-item">
                <Logout></Logout>
              </li>
            </ul>
          ) : (
            <ul className="list-menu-mb-ct">
              <li className="list-menu-mb-item">
                {userInfo !== "" ? (
                  <div className="list-menu-link">
                    <div className="icon-user-info">
                      <i className="fas fa-user text-center"></i>
                    </div>
                    <div className="name-user-info">
                      <span>
                        {userInfo.user_last_name +
                          " " +
                          userInfo.user_first_name}
                      </span>
                    </div>
                    <div className="email-user-info">
                      <span>{userInfo.email}</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </li>
              <li className="list-menu-mb-item">
                <Link className="list-menu-link" to="/change-password">
                  パスワード変更
                </Link>
              </li>
              <li className="list-menu-mb-item">
                <Logout></Logout>
              </li>
            </ul>
          )}

          </div>
          <div>
            <NavItem {...props}></NavItem>
          </div>
        </div>
      </div>
      {isStaging ? (<div className="staging-header-marker"> Staging </div>):""}
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, null)(Header);
