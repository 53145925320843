import React from "react";
import { connect } from "react-redux";
import Content from "../_components/Header/ContentDownloadHeaderToken";


const Error = () => {
    return (
        <div className="divw">
            <div className="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
                <Content></Content>
                <div className="content">
                    <div className="login-box document-home ">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="content-930 mt-5">
                                    <div className="form-box form-box-place mb-12">
                                        <h1>このページを閲覧する権限がありません。!</h1>
                                    </div>
                                </div>

                            </div>
                            <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    );
};

function mapStateToProps(state) {
    return {};
}

const connectedError = connect(mapStateToProps)(Error);
export { connectedError as Error };
