import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import dateFnsFormat from "date-fns/format";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";

import "../../../assets/styles/css/main-phase-2.css";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { getAccessTokenAdmin } from "../../_helpers";
import { companyActions, loginActions } from "../_actions";
import ErrorIcon from "../../../assets/styles/img/error.png";
import { alertActions } from "../../_actions";
import { companyAdminMessages } from "../../AdminDashboard/_messages";

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

class NewCompanyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      date_contract_end: "",
      date_contract_start: "",
      remark: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDateTimePickerStart = this.handleChangeDateTimePickerStart.bind(
      this
    );
    this.handleChangeDateTimePickerEnd = this.handleChangeDateTimePickerEnd.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (getAccessTokenAdmin() !== "") {
      this.props.dispatch(
        loginActions.checkLoginRoleSupperAdmin(getAccessTokenAdmin())
      );
    }
  }

  handleChangeDateTimePickerStart(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    const date = input.value.trim();
    if (date === ""){
      this.setState({ date_contract_start: "" });      
    } else {
      this.setState({ date_contract_start: date });
    }
  }

  handleChangeDateTimePickerEnd(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    const date = input.value.trim();
    if (date === "") {
      this.setState({ date_contract_end: "" });
    } else {
      this.setState({ date_contract_end: date });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let start_date;
    let end_date;
    const { name, date_contract_end, date_contract_start, remark } = this.state;
    if(date_contract_end.includes("/")){
      end_date = date_contract_end.split("/").join("-")
    }
    else{
      end_date = date_contract_end
    }
    if(date_contract_start.includes("/")){
      start_date = date_contract_start.split("/").join("-")
    }
    else{
      start_date = date_contract_start
    }

    const company = {
      name: name,
      date_contract_end: end_date,
      date_contract_start: start_date,
      remark: remark
    };
    const { dispatch } = this.props;

    if (
      this.isValidatorForm(name, date_contract_end, date_contract_start, remark)
    ) {
      dispatch(companyActions.create(company));
    }
  }

  isValidatorForm(name, date_contract_end, date_contract_start, remark) {
    const allowedDateFormats = ['YYYY-MM-DD','YYYY/MM/DD'];
    if (name === "" || name === undefined) {
      const { dispatch } = this.props;
      dispatch(alertActions.error(companyAdminMessages.NAME_NOT_NULL, "name"));
      return false;
    }

    if ((date_contract_start === "" || date_contract_start === undefined) && (date_contract_end === "" || date_contract_end === undefined)){
      const { dispatch } = this.props;
      const message = companyAdminMessages.START_DATE_NOT_NULL + "<br/>" + companyAdminMessages.END_DATE_NOT_NULL;
      dispatch(
        alertActions.error(
          message,
          "date_contract_start_end"
        )
      );
      return false;
    }

    if (date_contract_start === "" || date_contract_start === undefined) {
      const { dispatch } = this.props;
      dispatch(
        alertActions.error(
          companyAdminMessages.START_DATE_NOT_NULL,
          "date_contract_start"
        )
      );
      return false;
    }
    if (date_contract_end === "" || date_contract_end === undefined) {
      const { dispatch } = this.props;
      dispatch(
        alertActions.error(
          companyAdminMessages.END_DATE_NOT_NULL,
          "date_contract_end"
        )
      );
      return false;
    }
    
    let isValid_date_contract_end = moment(
      date_contract_end,
      allowedDateFormats,
      true
    ).isValid();
    let isValid_date_contract_start = moment(
      date_contract_start,
      allowedDateFormats,
      true
    ).isValid();

    if (!isValid_date_contract_end) {
      const { dispatch } = this.props;
      dispatch(
        alertActions.error(
          companyAdminMessages.DATE_NOT_FORMAT,
          "date_contract_end"
        )
      );
      return false;
    }

    if (!isValid_date_contract_start) {
      const { dispatch } = this.props;
      dispatch(
        alertActions.error(
          companyAdminMessages.DATE_NOT_FORMAT,
          "date_contract_end"
        )
      );
      return false;
    }
    
    if (date_contract_end < date_contract_start) {
      const { dispatch } = this.props;
      dispatch(
        alertActions.error(
          companyAdminMessages.COMPARE_DATE_START_END,
          "date_contract_end"
        )
      );
      return false;
    }    
    return true;
  }

  createMarkup = (message) => {
    return {
      __html: message,
    };
  };

  render() {
    const FORMAT = "yyyy-MM-dd";
    const { alert } = this.props;
    const { name, remark } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies">
          <HeaderAdmin isCoachaAdmin="true"></HeaderAdmin>
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    企業情報の登録・変更
                  </span>
                  <p style={{display:"inline",marginLeft:"15px",color:"#333333"}}>※</p>
                </li>
              </ul>
            </div>

            <div className="content-930 mt-40">
              <div className="content-form-580">
                <div className="form-box form-box-place mb-3">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group d-block mb-20">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        企業名 <span className="danger-box">必須</span>
                      </label>
                      <div className="form-input">
                        <input
                          onChange={this.handleChange}
                          type="text"
                          name="name"
                          value={name}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      {alert.message && alert.name === "name" && (
                        <div className="danger-cm mt-10">
                          <img src={ErrorIcon} alt="" />{" "}
                          <span className="error-detail">{alert.message}</span>
                        </div>
                      )}
                    </div>
                    <div className="group-form-control com-fix-css clearfix">
                      <div className="form-group  d-block">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          事務局様アカウント発行日 <span className="danger-box">必須</span>
                        </label>
                        <DayPickerInput
                          placeholder="YYYY-MM-DD"
                          dayPickerProps={{firstDayOfWeek: 1}}
                          inputProps={{ className: "form-control" }}
                          value={""}
                          formatDate={formatDate}
                          format={FORMAT}
                          parseDate={parseDate}
                          className={"form-control"}
                          onDayChange={this.handleChangeDateTimePickerStart}
                        />
                      </div>
                      <div className="form-group  d-block">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="label-box-form"
                        >
                          事務局様サイト閲覧期限 <span className="danger-box">必須</span>
                        </label>
                        <DayPickerInput
                          placeholder="YYYY-MM-DD"
                          dayPickerProps={{firstDayOfWeek: 1}}
                          inputProps={{ className: "form-control" }}
                          value={""}
                          formatDate={formatDate}
                          format={FORMAT}
                          parseDate={parseDate}
                          className={"form-control"}
                          onDayChange={this.handleChangeDateTimePickerEnd}
                        />
                      </div>
                    </div>
                    {alert.message && alert.name === "date_contract_start_end" && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />{" "}
                    <span className="error-detail"
                    dangerouslySetInnerHTML={this.createMarkup(alert.message)}></span>
                      </div>
                    )}
                    {alert.message && alert.name === "date_contract_start" && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />{" "}
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    {alert.message && alert.name === "date_contract_end" && (
                      <div className="danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />{" "}
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    <div className="form-group d-block mt-30">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="label-box-form"
                      >
                        備考
                      </label>
                      <div className="form-textarea">
                        <textarea
                          onChange={this.handleChange}
                          name="remark"
                          value={remark}
                          className="form-control form-control-area phase2-form-input-auto-height"
                          id="exampleTextarea"
                          rows="2"
                        />
                      </div>
                    </div>
                    <div className="btn-login text-left mt-20">
                      <button
                        type="submit"
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-send-mail mr-20"
                      >
                        保存する
                      </button>
                      <Link
                        to={"/admin/company"}
                        className="bnt-submit-link-footer modal-mes-phase2-button bnt-submit-link-status"
                      >
                        戻る
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin></FooterAdmin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedNewCompanyPage = connect(mapStateToProps)(NewCompanyPage);
export { connectedNewCompanyPage as NewCompanyPage };
