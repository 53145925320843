const authHeaderAdmin = () => {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem("admin"));
    if (user && user.data.access_token) {
      let bearer = user.data.token_type;
      let access_token = user.data.access_token;
      return bearer + " " + access_token;
    } else {
      return "";
    }
  };

  module.exports = {
    authHeaderAdmin
  };
