import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { userActions } from "../../_actions";
import { userMessages } from "../../_messages";
import { isStagingServer } from "../../_helpers";

import Logomb from "../../../assets/styles/img/Logover2.png";

class ForgotPasswordConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmed_password: "",
      token: "",
      submitted: false,
      isStaging:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({ token: values.token });
    this.props.dispatch(userActions.checkFirstTimeLoginForgotPassword(values.token));
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { password, confirmed_password, token } = this.state;
    const { dispatch } = this.props;
    if (this.isValidatorPassword(password, confirmed_password) === true) {
      dispatch(userActions.forgotPasswordConfirm(password, token));
    }
  }

  isValidatorPassword(password, confirmed_password) {
    let flag = false;
    if (!password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (confirmed_password !== password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_MATCH;
      this.props.alert.type = "alert-danger";
      return flag;
    }

    var strongRegex = new RegExp("[A-Za-z0-9@#$%^&+=]{8,}");
    const isOk = strongRegex.test(password);
    if (!isOk) {
      this.props.alert.message = userMessages.PASSWORD_IS_WEAK;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  }

  render() {
    const { confirmed_password, password } = this.state;
    const { alert } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo">
        {(this.state.isStaging===true) && (
           <div
             style={{backgroundColor:"#cac715",textAlign:"center",fontWeight:"bold",fontSize:"18px"}}
           >
	             <p>Staging</p>
           </div>
         )}
          <div className="header">
            <div className="header-content bg-color-F4F5F4">
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box">
                <div className="modal-content" style={{ background: "white" }}>
                  <div className="modal-body">
                    <h2 className="title-login text-4d4150">パスワード再設定</h2>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="mail-address">
                        新しいパスワード（英字大文字・英字小文字・数字（・記号@#$%^&+=のみ）を含む8文字以上）
                        </label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className=" form-group">
                        <label htmlFor="confirmed_password">
                          パスワードをもう一度入力
                        </label>
                        <input
                          name="confirmed_password"
                          type="password"
                          className="form-control"
                          id="confirmed_password"
                          value={confirmed_password}
                          onChange={this.handleChange}
                        />
                      </div>

                      {alert.message && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            { alert.name === 'forgot-password-expiry' ?
                             (<a href={'/forgot-password'}> {alert.message} </a>) : (alert.message) 
                            }
                          </div>
                        </div>
                      )}
                      <div className="from-group text-center">
                        <button
                          type="submit"
                          className="bnt-submit bg-color-4D4150 mb-3"
                        >
                          パスワードを変更する
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedForgotPasswordConfirm = connect(mapStateToProps)(
  ForgotPasswordConfirm
);
export { connectedForgotPasswordConfirm as ForgotPasswordConfirm };
