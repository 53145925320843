import React from "react";

import { connect } from "react-redux";

import "../../../../assets/styles/css/main-phase-2.css";
import WorkShopItem from "../WorkShopItem";
import { Link } from "react-router-dom";

// import Logo from "../../../../assets/styles/img/logo.png";
const LeftContentNav = props => {
  const {
    companyUUID,
    listWorkshop,
    isShowWorkShowDetailInfo,
    onClickShowInfoWorkshop,
    companyInfo,
    adminAuth
  } = props;
  return (
    <div className="left-nav">
      <ul className="list-left">
      {adminAuth.data.is_supper_admin === true ? (
        <li className="list-left-item">
          <p className="title">
            企業詳細
              <Link
                to={"/admin/company/" + companyUUID + "/edit"}
                className="bnt-submit-cm-1"
              >
                変更する
              </Link>
              <p style={{display:"inline",marginLeft:"25px",fontWeight:"bold",fontSize:"20px"}}>※</p>
          </p>
          <div className="list-content">
            <p className="list-content-title">事務局様アカウント発行日</p>
            <p className="date-content mb-16">
              {companyInfo.date_contract_start}
            </p>
            <p className="list-content-title">事務局様サイト閲覧期限</p>
            <p className="date-content">{companyInfo.date_contract_end}</p>
            <p className="list-content-title" onClick={onClickShowInfoWorkshop}>
              備考
            </p>
          </div>
        </li>
        ) : (
          ""
        )}
        <li className="list-left-item">
          <p className="title">
            ワークショップ日程
            <Link
              to={"/admin/workshop/" + companyUUID}
              className="workshop-schedule-fix-href"
            >
              詳細一覧
            </Link>
          </p>
          <div className="list-content scroll-left-nav-workshop">
            {listWorkshop.map(workshop => (
              <WorkShopItem
                key={workshop.id}
                workshop={workshop}
                isShowInfoWorkshop={isShowWorkShowDetailInfo}
              ></WorkShopItem>
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(null, mapDispatchToProps)(LeftContentNav);
