import React from "react";

import { reportToSentry } from "../_helpers";


export class ErrorBoundary extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    reportToSentry(error, errorInfo);
  }

  render () {
    if (!this.state.hasError) {
      return this.props.children;
    }
    const Component = this.props.component;
    return <Component />;
  }

}


export function connectErrorBoundary (Component, ErrorPage) {
  return props => (
    <ErrorBoundary component={ErrorPage}>
      <Component {...props} />
    </ErrorBoundary>
  );
}
