// import config from "config";
import { authHeader } from "../_helpers";

const API_QUESTION = process.env.URL_API;

export const questionService = {
  getAllQuestion,
  submitAnswers,
  logout,
  getHistoryResultAnswerQuestion,
  checkPreAssessment
};
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
function getAllQuestion() {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: authHeader()
    }
  };
  return fetch(API_QUESTION + "/v1/questions/list", requestOptions)
    .then(response => {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      } else return response.json();
    })
    .then(data => {
      return data;
    });
}
function submitAnswers(answer_data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    },
    body: JSON.stringify({
      answer_data: answer_data
    })
  };
  return fetch(API_QUESTION + "/v1/questions/answer-questions", requestOptions)
    .then(response => {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      } else return response.json();
    })
    .then(data => {
      // localStorage.setItem("analystInfo", JSON.stringify(data));
      return data;
    });
}

function getHistoryResultAnswerQuestion() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  return fetch(
    API_QUESTION + "/v1/questions/history-result-answer-question",
    requestOptions
  )
    .then(response => {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      } else return response.json();
    })
    .then(data => {
      return data;
    });
}

function checkPreAssessment() {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: authHeader()
    }
  };
  return fetch(API_QUESTION + "/v1/questions/pre-assessment-check", requestOptions)
    .then(response => {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      } else return response.json();
    })
    .then(data => {
      return data;
    });
}
