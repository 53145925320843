import React from "react";

import { connect } from "react-redux";

import "../../../../assets/styles/css/main-phase-2.css";

// import Logo from "../../../../assets/styles/img/logo.png";
const WorkShopItem = props => {
  const { workshop, isShowInfoWorkshop } = props;
  return (
    <div>
      <p className="list-content-title">
        <span
          className="text-rectang color-white"
          style={{ backgroundColor: workshop.color }}
        >
          {workshop.code}
        </span>
        <span className="text-rectang-fix bg-white">
          {workshop.date_workshop}
        </span>
      </p>
      <p className="date-content mb-16">{workshop.name}</p>
      {isShowInfoWorkshop === true ? (
        <div>
          <div className="list-box-line">
            <p className="list-box-line-dt">
              有効ユーザー　
              <span className="font-weight-bold">
                {workshop.total_user_active}人
              </span>
              / {workshop.total_user}人
            </p>
            <p className="list-box-line-dt">
              インベントリー回答済　
              <span className="font-weight-bold">
                {workshop.user_active_complete_assessment}人
              </span>
              / {workshop.user_complete_assessment}人
            </p>
          </div>
          <p className="list-box-line-des">{workshop.remark}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, null)(WorkShopItem);
