import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userActions, questionActions } from "../_actions";

import Header from "../_components/Header";
import Footer from "../_components/Footer";

import Banner from "../../assets/styles/img/banner.png";
import { getAccessToken } from "../_helpers";

class HomePage extends React.Component {

  componentDidMount() {
    if (getAccessToken() !== "") {
      this.props.dispatch(userActions.checkLogin(getAccessToken()));
    }
    this.props.dispatch(questionActions.checkPreAssessment());
  }

  render() {
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo">
          <Header homeActive="true"></Header>
          <section className="banner-page-vertwo">
            <div className="banner-page-vertwo-content">
              <div className="img-bannner">
                <img src={Banner} alt="" />
              </div>
            </div>
          </section>
          <section className="content home-page">
            <div className="content-vertwo">
              <ul className="vertwo-list">
                <li className="vertwo-list-item clearfix">
                  <div className="icon-vertwo bg-color-ffebec  text-ff868d">
                  <i class="fas fa-file-medical-alt"></i>
                  </div>
                  <div className="list-content-icon-vertwo">
                    <h3 className="title">3分間コーチ・インベントリー</h3>
                    <p className="detail">
                    あなたのコミュニケーションスタイルと強み・課題を把握しましょう。
                      <br />
                    </p>
                    <Link to="/consequence" className="link">
                    結果を見る
                    </Link>
                  </div>
                </li>
              </ul>
              <div className="design-by">
                © COACH A Co., Ltd. All Rights Reserved.
              </div>
            </div>
          </section>
          <Footer homeActive="true"></Footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
