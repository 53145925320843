import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { userMessages } from "../_messages";

import Header from "../_components/Header";
import Footer from "../_components/Footer";
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current_password:"",
      password: "",
      confirmed_password: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { current_password,password, confirmed_password } = this.state;
    const { dispatch } = this.props;
    // if (confirmed_password && password) {
    //   dispatch(userActions.changePassword(password, confirmed_password));
    // }
    if (this.isValidatorPassword(current_password,password, confirmed_password) === true) {
      dispatch(userActions.changePassword(current_password,password, confirmed_password, false));
    }
  }
  isValidatorPassword(current_password,password, confirmed_password) {
    let flag = false;
    if (!current_password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!confirmed_password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (confirmed_password !== password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_MATCH;
      this.props.alert.type = "alert-danger";
      return flag;
    }

    var strongRegex = new RegExp("[A-Za-z0-9@#$%^&+=]{8,}");
    const isOk = strongRegex.test(password);
    if (!isOk) {
      this.props.alert.message = userMessages.PASSWORD_IS_WEAK;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  }

  render() {
    const { current_password,confirmed_password, password } = this.state;
    const { alert } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
          <Header></Header>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box document-home">
                <div className="modal-content">
                  <div className="modal-body">
                    <h2 className="title-login text-left text-4d4150">
                      パスワード変更
                    </h2>

                    <form
                      className="container-form-input-login p-15"
                      name="form"
                      onSubmit={this.handleSubmit}
                      style={{ background: "white" }}
                    >
                      <div className=" form-group">
                        <label htmlFor="current_password">
                        現在のパスワード
                        </label>
                        <input
                          name="current_password"
                          type="password"
                          className="form-control"
                          id="current_password"
                          value={current_password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mail-address">
                        新しいパスワード（英字大文字・英字小文字・数字（・記号@#$%^&+=のみ）を含む8文字以上）
                        </label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" form-group">
                        <label htmlFor="confirmed_password">
                          パスワードをもう一度入力
                        </label>
                        <input
                          name="confirmed_password"
                          type="password"
                          className="form-control"
                          id="confirmed_password"
                          value={confirmed_password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      {alert.type === "alert-danger" && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            {alert.message}
                          </div>
                        </div>
                      )}
                      <div className="from-group text-center">
                        <button
                          type="submit"
                          className="bnt-submit bg-color-4D4150 mb-3"
                        >
                          パスワードを変更する
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedChangePassword = connect(mapStateToProps)(ChangePassword);
export { connectedChangePassword as ChangePassword };
