import { loginConstants } from "../_constants";

let admin = JSON.parse(localStorage.getItem("admin"));
const initialState = admin ? { loggedIn: true, admin } : {};

export function authAdminPhase2(state = initialState, action) {
  switch (action.type) {
    case loginConstants.LOGIN_ADMIN_REQUEST:
      return {
        loggingIn: true,
        admin: action.admin
      };
    case loginConstants.LOGIN_ADMIN_SUCCESS:
      return {
        loggedIn: true,
        admin: action.admin
      };
    case loginConstants.LOGIN_ADMIN_FAILURE:
      return {};
    case loginConstants.LOGOUT_ADMIN:
      return {};
    default:
      return state;
  }
}
