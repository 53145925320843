import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../../../assets/styles/css/main-phase-2.css";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";
import { workshopAdminActions, userAdminActions } from "../_actions";

class ListWorkshopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listWorkshop: [], company_uuid: "", isLoginStatus:""  };
  }
  componentDidMount = () => {
    this.checkButtonHeader();
    this.setState({
      company_uuid: this.props.match.params.companyUUID
    });
    this.props.dispatch(
      workshopAdminActions.getListWorkshopsAction(
        this.props.match.params.companyUUID
      )
    );
    this.props.dispatch(
      userAdminActions.getCompanyDetailInfoAction(
        this.props.match.params.companyUUID
      )
    );
  };
  componentDidUpdate = prevProps => {
    //trang thai updating
    if (prevProps.workshopsAdmin !== this.props.workshopsAdmin) {
      this.setState({
        listWorkshop: this.props.workshopsAdmin,
        isLoginStatus: this.props.workshopsAdmin
      });
    }
  };
  checkButtonHeader = () => {
    const $ = require("jquery");
    var scroll_left_button =
      $(".top-content-responsive").get(0).scrollWidth -
      $(".header").get(0).clientWidth -
      $(window).scrollLeft();
    $(".btn-header-responsive").animate(
      { width: scroll_left_button + "px" },
      0
    );
  };
  render() {
    const { listWorkshop,isLoginStatus } = this.state;
    const { company, admin } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies enterprise-details workshop-list">
          <HeaderAdmin isCoachaAdmin="true" />
          <div className="content content-fix-phase2">
            <div className="top-content top-content-responsive">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {company.name}
                  </span>
                </li>
              <a
                href={`/admin/detail-company/${this.state.company_uuid}`}
                className="linktab"
              >
                企業詳細・ユーザーー覧
              </a>
              </ul>
              <div className="breadcrumb breadcrumb-fix-phase2 mb-0">
              {admin.data.is_supper_admin === true ? (
                <div>
                <Link
                  to={`/admin/create-workshop/${this.state.company_uuid}`}
                  className="bnt-submit-cm-2"
                >
                  ワークショップを追加する
                </Link>
                <p style={{display:"inline",marginLeft:"15px",color:"#333333",fontSize:"18px",fontWeight:"bold"}}>※</p>
                </div>
              ) : (
                ""
              )}</div>
              <div className="btn-header-responsive"></div>
            </div>

            <div className="tab-data clearfix">
              <div className="group-tab-content clearfix">
                <div id="tab-1" className="tab-content active clearfix">
                  <div className="content-50">
                    <div className="wrap-tab">
                      <div className="wrap-table">
                        <div className="form-table clearfix">
                          <div className="table-wrap-content">
                            <div className="box-theadtable">
                              <table
                                id="workshop-list-layout"
                                className="table table-striped table-sm js-table"
                              >
                                <thead>
                                  <tr>
                                    <th className="th-sm hiden-sort">
                                      ワークショップ日程
                                    </th>
                                    <th className="th-sm hiden-sort">
                                      ワークショップ名
                                    </th>
                                    <th className="th-sm hiden-sort">
                                      有効ユーザー/全体
                                    </th>
                                    <th className="th-sm hiden-sort">
                                      インベントリー回答済/ 有効ユーザー
                                    </th>
                                    <th className="th-sm hiden-sort">ログイン案内</th>
                                    <th className="th-sm hiden-sort">メルマガ進捗</th>
                                    <th className="th-sm hiden-sort">vol.0 配信完了日</th>
                                    <th className="th-sm hiden-sort">vol.26 配信完了日</th>
                                    {admin.data.is_supper_admin === true ? (
                                    <th className="th-sm hiden-sort">備考</th>) : (
                                      <th className="th-sm hiden-sort"></th>
                                    )}
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="box-tbodytable">
                              <table id="workshop-list-layout" className="table table-striped table-sm">
                                <tbody>
                                  {listWorkshop.map(workshop => (
                                    <tr key={workshop.id}>
                                      <td className="td-sm">
                                        <span
                                          className="text-rectang color-white"
                                          style={{
                                            backgroundColor: workshop.color
                                          }}
                                        >
                                          {workshop.code}
                                        </span>
                                        <span className="text-date">
                                          {workshop.date_workshop}
                                        </span>
                                      </td>
                                      <td className="td-sm">
                                        {admin.data.is_supper_admin === true ? (
                                          <Link
                                            to={`/admin/workshop/${this.state.company_uuid}/edit/${workshop.uuid}`}
                                          >
                                            <span className="text-line color-689B88 cursor-pointer">
                                              {workshop.name
                                                ? workshop.name
                                                : "-(" + workshop.code + ")"}
                                            </span>
                                          </Link>
                                        ) : (
                                          <span className="text-line color-689B88 cursor-pointer">
                                            {workshop.name
                                              ? workshop.name
                                              : "-(" + workshop.code + ")"}
                                          </span>
                                        )}
                                      </td>
                                      <td className="td-sm">
                                        <span className="list-box-line-dt">
                                          <span className="font-weight-bold">
                                            {workshop.total_user_active}人
                                          </span>
                                          / {workshop.total_user}人
                                        </span>
                                      </td>
                                      <td className="td-sm">
                                        <span className="list-box-line-dt">
                                          <span className="font-weight-bold">
                                            {workshop.user_active_complete_assessment}
                                            人
                                          </span>
                                          / {workshop.total_user_active}人
                                        </span>
                                      </td>
                                      <td>
                                      {workshop.is_login_status === true ? (
                                        <div className="text-action-on">
                                          ON
                                        </div>
                                      ) : (
                                          <div className="text-action-off">
                                            OFF
                                          </div>
                                        )}
                                    </td>
                                      <td className="td-sm">
                                        <span className="color-4D4150">
                                            {Math.round(workshop.mail_magazine_progress)}/27 ({Math.round(workshop.mail_magazine_progress * 100 / 27)}%)
                                        </span>
                                      </td>
                                      <td className="td-sm color-4D4150">
                                        {workshop.start_date}
                                      </td>
                                      <td className="td-sm color-4D4150">
                                        {workshop.end_date}
                                      </td>
                                      <td className="td-sm">
                                        {admin.data.is_supper_admin === true ? (
                                          <span className="color-4D4150">
                                            {workshop.remark}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    workshopAdminReducer,
    authAdminReducer,
    userAdminReducer
  } = state;
  const { workshopsAdmin } = workshopAdminReducer;
  const { company } = userAdminReducer;
  const { admin } = authAdminReducer;
  return {
    alert,
    workshopsAdmin,
    admin,
    company
  };
}

const connectedListWorkshopPage = connect(mapStateToProps)(ListWorkshopPage);
export { connectedListWorkshopPage as ListWorkshopPage };
