import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userAdminActions } from "../_actions";
import { alertActions } from "../../_actions";
import { isStagingServer } from "../../_helpers";

import HeaderAdmin from "../Components/Header";
import FooterAdmin from "../Components/Footer";

import "../../../assets/styles/css/main-phase-2.css";
import WarnIcon from "../../../assets/styles/img/ Exclamation.png";
import ErrorIcon from "../../../assets/styles/img/error.png";
import ErrorSuccess from "../../../assets/styles/img/success.png";


class CSVRegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: null, isShowMessageNotInput: false, fileName:"", error_list:[] };
  }
  componentDidMount() {
    //Component vua load het trang
    this.props.dispatch(
      userAdminActions.getCompanyDetailInfoAction(
        this.props.match.params.companyUUID
      )
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.error_list !== this.props.error_list) {
      this.setState({ error_list: this.props.error_list });
    }
  }

  triggerInputFile = () => {
    this.fileInput.click();
    this.setState({error_list: []});
    this.props.dispatch(alertActions.clear());
  };

  handleForce = (event) => {
    if (event.target.files[0]) {
      this.setState({
        file: event.target.files[0],
        isShowMessageNotInput: true,
        fileName:event.target.files[0].name
      });
      this.props.dispatch(alertActions.clear());
    }
  };

  onClickUpload = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    if (this.state.file) {
      dispatch(
        userAdminActions.importCSVUserAction(
          this.state.file,
          this.props.company.id,
          this.props.match.params.companyUUID
        )
      );
    }
  };

  downloadCSV = () => {
    let file = "";
    if (isStagingServer()) {
      file = 'https://test.3mins.coacha.com/static/template/sample_user.csv'
    } else {
      file = 'https://3mins.coacha.com/static/template/sample_user.csv'
    }
    setTimeout(() => {
      const response = {
        file: file,
      };
      window.location.href = response.file;
    });
  };
  createMarkup = (message) => {
    return {
      __html: message,
    };
  };
  render() {
    const { alert, company } = this.props;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper list-companies batch-registration">
          <HeaderAdmin />
          <div className="content content-fix-phase2">
            <div className="top-content">
              <ul className="breadcrumb breadcrumb-fix-phase2 mb-0">
                <li className="item-breadcrumb active-line">
                  <span className="content-item color-4d4150-header-title">
                    ユーザー情報の一括登録・更新
                  </span>
                </li>
                <li className="item-breadcrumb">
                  <span className="content-item color-4d4150-header-title">
                    {company.name}
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-930 mt-40">
              <div className="form-box form-box-place mb-3">
                <div className="gr-container mb-40">
                  <div className="title">CSVファイルのアップロード</div>
                  <div className="btn-bath">
                    <input
                      ref={(fileInput) => (this.fileInput = fileInput)}
                      type="file"
                      className="display-none-csv-file"
                      id="customCSVFile"
                      onChange={this.handleForce}
                      accept=".csv"
                    />
                    <button
                      type="button"
                      className="btn bnt-submit-form-csv-phase2 bnt-submit-form3"
                      onClick={this.triggerInputFile}
                    >
                      ファイルを選択
                    </button>
                    {this.state.isShowMessageNotInput === false ? (
                      <span>選択されていません</span>
                    ) : (
                      <span>{this.state.fileName}</span>
                      )}
                  </div>
                  <button
                    type="button"
                    className="btn bnt-submit-form-csv-phase2 bnt-submit-form1 bnt-submit-form1-fix mb-20"
                    onClick={this.onClickUpload}
                  >
                    アップロードする
                  </button>
                  <div className="content-form-580">
                    {alert.message && alert.type === "alert-warning" && (
                      <div className="csv-import-message-box danger-cm mt-10 alert-warning">
                        <span className="error-detail">{alert.message}</span>
                      </div>
                    )}
                    {alert.message && alert.type === "alert-danger" && (
                      <div className="csv-import-message-box danger-cm mt-10">
                        <img src={ErrorIcon} alt="" />
                        <span
                          className="error-detail"
                          dangerouslySetInnerHTML={this.createMarkup(alert.message)}
                        ></span>
                      </div>
                    )}
                    {alert.message && alert.type === "alert-success" && (
                      <div className="csv-import-message-box danger-cm alert-success mt-10">
                        <img src={ErrorSuccess} alt="" />
                        <span className="error-detail"
                        dangerouslySetInnerHTML={this.createMarkup(alert.message)}
                        ></span>
                      </div>
                    )}
                    {this.state.error_list.length !== 0 && (
                    <div>
                      <div className="csv-import-message-box danger-cm mt-10">
                      <img src={ErrorIcon} alt="" />
                        <span
                        className="error-detail"
                        dangerouslySetInnerHTML=
                        {this.createMarkup('登録に失敗しました（エラー件数：'+this.state.error_list.length+'件） データ修正の上、再アップロードください。')}
                      ></span>
                    </div>
                    <div className={this.state.error_list.length>5 ? 'csv-import-error-list' :''}>                    
                      {this.state.error_list.map((error) => (
                      <div className="csv-import-message-box danger-cm mt-10 alert-warning">
                        <img src={WarnIcon} alt="" />
                          <span
                          className="error-detail"
                          dangerouslySetInnerHTML={this.createMarkup(error)}
                        ></span>
                      </div>)
                      )}
                      </div>
                    </div>)
                    }
                  </div>
                  <div className="csv-download-wrapper">
                  <p className="title">記入用テンプレート</p>
                  <button
                    type="button"
                    className="btn bnt-submit-form-csv-phase2 bnt-submit-form3"
                    onClick={this.downloadCSV}
                  >ダウンロードする</button>
                  <ul>
                    <li>必須項目：姓・名・メールアドレス・ワークショップコード（WS+数字3桁）</li>
                    <li>ファイル形式は必ず「CSV　UTF-8（コンマ区切り）」で保存してください。</li>
                    <li>一括登録の上限数はないため、複数開催分の参加者を同ファイルで登録可能です。</li>        
                    <li>不正なデータを含む場合はエラー表示され、該当行以外のユーザが登録されます。 
                      登録失敗したユーザデータはcsvファイルで保存されます。ファイル名： "year_month_date-#"(# the number of failed records) 
                      保存されたファイルを修正の上、再アップロードしてください。</li>
                    <li>複数開催分を一つのファイルで登録する場合、ファイル内のWSコードに紐づく「ログイン案内on/off」を統一してください。
                      混在した場合、一番下の行の「on/off」が全体へ反映される仕様のため、「ログイン案内on/off」が混在する場合は、ファイルを分けて登録が必要です。</li>
                    <li>「メルマガ配信」が空白の場合はY（配信）、「管理者」が空白の場合はN（ユーザ）で登録されます。</li>
                  </ul>
                </div>
                  <div className="btn-login text-left mt-20">
                    <Link
                      to={
                        "/admin/detail-company/" +
                        this.props.match.params.companyUUID + "/?return=true"
                      }
                      className="btn fix-button-back bnt-submit-form3 fw-bold fz-15"
                    >
                      戻る
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin></FooterAdmin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    userAdminReducer,
    workshopAdminReducer,
    authAdminReducer,
    alert,
  } = state;
  const { admin } = authAdminReducer;
  const { listUsers, activePercent, company, error_list } = userAdminReducer;
  const { workshopsAdmin } = workshopAdminReducer;
  return { listUsers, workshopsAdmin, activePercent, admin, alert, company, error_list };
}

const connectedCSVRegistrationPage = connect(mapStateToProps)(
  CSVRegistrationPage
);
export { connectedCSVRegistrationPage as CSVRegistrationPage };
