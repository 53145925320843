import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    waiting,
    error,
    clear
};

function waiting(message) {
    return { type: alertConstants.WAITING, message };
}

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message, name) {
    return { type: alertConstants.ERROR, message, name: name ? name : ""};
}

function clear() {
    return { type: alertConstants.CLEAR };
}
