import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import emailValidator from "email-validator";
import { userActions } from "../_actions";
import { userMessages } from "../_messages";
import { isStagingServer } from "../_helpers";

import Logomb from "../../assets/styles/img/Logover2.png";


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      username: "",
      password: "",
      submitted: false,
      isStaging:false
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }  
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  isValidatorPassword = (email, password) => {
    let flag = false;
    if (!email) {
      this.props.alert.message = userMessages.EMAIL_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!emailValidator.validate(email)) {
      this.props.alert.message = userMessages.EMAIL_NOT_TRUE;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  };

  handleSubmit(e) {
    e.preventDefault();
    let url = "";
    if (this.props.location.state !== undefined) {
      url = this.props.location.state.from.pathname;
      if (this.props.location.state.from.search) {
        url = url + this.props.location.state.from.search;
      }
    }

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    this.isValidatorPassword(username, password);
    if (this.isValidatorPassword(username, password) === true) {
      dispatch(userActions.login(username, password, url));
    }
  }

  render() {  
    const { alert } = this.props;
    const { username, password } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo">
          <div className="header">
            <div className="header-content bg-color-F4F5F4">
            {this.state.isStaging===true ? (<div className="staging-header-marker"> Staging </div>):""}
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box">
                <div className="modal-content" style={{ background: "white" }}>
                  <div className="modal-body">
                    <h2 className="title-login">ログイン</h2>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="mail-address">メールアドレス</label>
                        <input
                          name="username"
                          type="input"
                          className="form-control"
                          placeholder="example@coach.co.jp"
                          value={username}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className=" form-group">
                        <label htmlFor="password">パスワード</label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="from-group note text-left">
                        <p className="note-para">
                          <Link to="/forgot-password" className="link-submit">
                            パスワードをお忘れの場合
                          </Link>
                        </p>
                      </div>

                      {alert.message && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            {alert.message}
                          </div>
                        </div>
                      )}
                      <div className="from-group text-center">
                        <button
                          type="submit"
                          className="bnt-submit bg-color-4D4150 mb-3"
                        >
                          ログインする
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
