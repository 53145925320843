import { connect } from "react-redux";
import React from "react";
import { adminActions } from "../../../_actions";


class ExtractCSV extends React.Component {
  constructor(props) {
    super(props);
    this.onClickDownload = this.onClickDownload.bind(this);
  }

  onClickDownload() {
    const { dispatch } = this.props;
    dispatch(adminActions.exportFile());
  }

  render() {
    return (
      <div id="tab-3" className="tab-content active col-6">
        <div className="form-box">
          <div className="form-group d-block">
            <button onClick={this.onClickDownload} className="btn-primary--bg mb-3 mt-60 btn-import" type="button">
              Download CSV
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const connectedAdminDashboard = connect()(ExtractCSV);
export { connectedAdminDashboard as ExtractCSV };
