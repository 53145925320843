import Logo from "../../../assets/styles/img/logo-white.png";
import ArrowDow from "../../../assets/styles/img/Arrow-dow.png";
import IconHeader from "../../../assets/styles/img/icon-header.png";
import React from "react";

const HeaderAdmin = props => (
  <header className="header">
    <div className="header-content">
      <div className="content-logo-mb-horizontal content-logo-mb-horizontal-style-fix style-fix-position">
        <img src={Logo} alt="" />
        <ul className="list-text">
          <li className="list-text-item list-text-item-active">
            <span className="color-C8B7CC">管理画面</span>
          </li>
          <li className="list-text-item">
            <span className="color-white">企業一覧</span>
          </li>
        </ul>
        <span className="setting-box">
          <img src={ArrowDow} alt="" />
        </span>
        <div className="clearfix list-menu-fix-phase2">
          <li className="list-menu-item">
            <a
              href="#/"
              className="link-list-menu d-block"
              onClick={event => event.preventDefault()}
            >
              <span className="img-gr">
                <img src={IconHeader} alt="" />
              </span>
              <span className="title-menu ">大塚 良</span>
            </a>
          </li>
        </div>
      </div>
    </div>
  </header>
);
export default HeaderAdmin;
