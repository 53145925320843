export const userAdminConstants = {
  GET_LIST_USER_ADMIN_REQUEST: "GET_LIST_USER_ADMIN_REQUEST",
  GET_ACTIVE_ACCOUNT_COMPANY_REQUEST: "GET_ACTIVE_ACCOUNT_COMPANY_REQUEST",
  ACTIVE_USER_COMPANY_REQUEST: "ACTIVE_USER_COMPANY_REQUEST",
  DEACTIVE_USER_COMPANY_REQUEST: "DEACTIVE_USER_COMPANY_REQUEST",
  GET_COMPANY_DETAIL_INFO_REQUEST: "GET_COMPANY_DETAIL_INFO_REQUEST",

  GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",

  CSV_DOWNLOAD_SUCCESS: "CSV_DOWNLOAD_SUCCESS",
  CSV_UPLOAD_SUCCESS: "CSV_UPLOAD_SUCCESS",
  USER_ENABLE_TAG_NONE: "USER_ENABLE_TAG_NONE",
  GET_LIST_SUPER_USER__REQUEST: "GET_LIST_SUPER_USER__REQUEST",

};
