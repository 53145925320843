import { workshopConstants } from "../_constants";

const INITIAL_STATE = {
  workshops: []
};

export function workshops(state = INITIAL_STATE, action) {
  switch (action.type) {
    case workshopConstants.WORKSHOP_GET_ALL_REQUEST:
      return {
        ...state,
        workshops: action.workshops
      };
    case workshopConstants.WORKSHOP_CREATE_WORKSHOP:
      return {
        ...state,
        workshops: action.workshops
      };
    default:
      return state;
  }
}
