import React from "react";

import Header from "../_components/Header";
import Footer from "../_components/Footer";

export function SorryPage(props) {
  return (
    <div class="wrapper mobile assessment-first assessment-first-fix mailmagazine-fix home-vertwo">
      <Header />
      <div class="content">
        <div class="content-vertwo">
          <div class="login-box document-home">
            <div class="modal-content">
              <div class="modal-body">
                <h2 class="title-login text-left text-4d4150">
                  お探しのページが見つかりませんでした。
                </h2>
                <div class="container-form-input-login">
                  <h3 class="container-form-heading">
                    フォローアップメールマガジン”3 Minutes Tips”
                    <br />
                    リニューアルのお知らせ
                  </h3>
                  <p class="container-form-content text-333333">
                    2022年4月からメールマガジンをよりご覧いただきやすいよう、内容及び形式を変更いたしました。
                    <br />
                    上記に伴い、本ページは削除させていただいております。ご理解いただければ幸いです。
                  </p>
                  <h4 class="container-form-title text-333333">
                    ＜リニューアル概要＞
                  </h4>
                  <ul className="container-form-ulheading">
                    <li class="container-form-ul title-login-content text-333333">
                      閲覧しやすいテキストコンテンツに限定しました。
                    </li>
                    <li class="container-form-ul title-login-content text-333333">
                      より実践につながるヒントをお伝えする内容にリニューアルしました。
                    </li>
                    <li class="container-form-ul title-login-content text-333333">
                      従来の専用サイト経由での閲覧ではなく、現在は配信メールに直接コンテンツを掲載する形式でお届けしております。
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mail-magzine-copyright-scroll">
        © COACH A Co., Ltd. All Rights Reserved.
      </div>
      <Footer />
    </div>
  );
}
