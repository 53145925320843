import { questionConstants } from "../_constants";
import { questionService } from "../_services";
import { alertActions } from "./alert.actions";
import { history } from "../_helpers";

export const questionActions = {
  checkPreAssessment,
  getAllQuestions,
  addAnswer,
  submitAllQuestions,
  getHistoryResultAnswerQuestionAction
};

function checkPreAssessment() {
  return () => {
    questionService.checkPreAssessment().then(response => {
      if (response.code !== 200) {
        history.push("/");
      } else {
        history.push("/first-time-home-page");
      }
    });
  }
}

function getAllQuestions() {
  return dispatch => {
    dispatch(request());
    questionService.getAllQuestion().then(questions => {
      if (questions.code !== 200) {
        dispatch(alertActions.error(questions.detail));
        history.push("/");
      } else {
        dispatch(success(questions.data));
        dispatch(alertActions.success(questions.detail));
      }
    });
  };
  function success(questions) {
    return {
      type: questionConstants.QUESTIONS_GETALL_REQUEST,
      questions: questions
    };
  }
  function request() {
    return { type: questionConstants.QUESTIONS_GETALL_REQUEST };
  }
}
// Add answer to AnswerReducer
// function addAnswer(answer) {
//   return dispatch => {
//     dispatch(request(answer));
//   };
//   function request(answer) {
//     console;
//     return { type: questionConstants.ADD_ANSWER, answer: answer };
//   }
// }
function addAnswer(questionsAtEachPage) {
  return dispatch => {
    dispatch(request(questionsAtEachPage));
  };
  function request(questionsAtEachPage) {
    return {
      type: questionConstants.ADD_ANSWER,
      questionsAtEachPage: questionsAtEachPage
    };
  }
}

function submitAllQuestions(answer_data) {
  return dispatch => {
    // dispatch(request());
    questionService.submitAnswers(answer_data).then(analystInfo => {
      if (analystInfo.code !== 200) {
        dispatch(failure(analystInfo));
        dispatch(alertActions.error(analystInfo.detail));
      } else {
        dispatch(success(analystInfo.data));
        history.push("/consequence");
      }
    });
  };
  function success(analystInfo) {
    return {
      type: questionConstants.SUBMIT_ALL_ANSWERS,
      analystInfo: analystInfo
    };
  }
  // function request() {
  //   return { type: questionConstants.SUBMIT_ALL_ANSWERS };
  // }
  function failure(error) {
    return { type: questionConstants.SUBMIT_ALL_ANSWERS_FAILURE, error };
  }
}

function getHistoryResultAnswerQuestionAction() {
  return dispatch => {
    // dispatch(request());
    questionService.getHistoryResultAnswerQuestion().then(analystInfo => {
      if (analystInfo.code !== 200) {
        // dispatch(failure(analystInfo));
        dispatch(alertActions.error(analystInfo.detail));
      } else {
        dispatch(success(analystInfo.data));
      }
    });
  };
  function success(analystInfo) {
    return {
      type: questionConstants.GET_HISTORY_RESULT_ANSWER_QUESTIONS,
      analystInfo: analystInfo
    };
  }
}
