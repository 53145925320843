import React from "react";
import { connect } from "react-redux";

import { userActions } from "../_actions";
import { userMessages } from "../_messages";
import Header from "../_components/Header";

class FirstTimeLogin extends React.Component {
  constructor(props) {
    super(props);
    // const query = new URLSearchParams(this.props.location.search);
    // const mail = query.get("mail");

    this.state = {
      password: "",
      mail: "",
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * handleChange
   * @param {*} e
   */
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  isValidatorPassword = (email, password) => {
    let flag = false;
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!email) {
      this.props.alert.message = userMessages.EMAIL_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!testEmail.test(email)) {
      this.props.alert.message = userMessages.EMAIL_NOT_TRUE;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    if (!password) {
      this.props.alert.message = userMessages.PASSWORD_NOT_NULL;
      this.props.alert.type = "alert-danger";
      return flag;
    }
    flag = true;
    return flag;
  };
  /**
   * handleSubmit
   * @param {*} e
   */
  handleSubmit(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    // if (this.state.password != "") {
    //   dispatch(userActions.login(this.state.mail, this.state.password));
    // }
    this.setState({ submitted: true });
    if (
      this.isValidatorPassword(this.state.mail, this.state.password) === true
    ) {
      dispatch(userActions.login(this.state.mail, this.state.password));
    }
  }

  /**
   * render
   */
  render() {
    const { alert } = this.props;
    const { password, mail } = this.state;
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo"
         style={{ background: "white" }}
        >
          {/* <div className="header">
            <div className="header-content bg-color-F4F5F4">
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div> */}
          <Header assessmentActive="true"></Header>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div
                    className="modal-content"
                    style={{ background: "white" }}
                  >
                    <div className="modal-body">
                      <h2 className="title-login text-4d4150">
                        初回利用ログイン
                      </h2>
                      <p className="title-login-content">
                        ご案内メールに記載のパスワードを入力し、下記よりログインしてください。
                      </p>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          メールアドレス
                        </label>
                        <input
                          name="mail"
                          type="input"
                          className="form-control"
                          placeholder="example@coach.co.jp"
                          value={mail}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className=" form-group">
                        <label htmlFor="password">パスワード</label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={this.handleChange}
                          placeholder="メールに記載のパスワードを入力"
                          autoComplete="off"
                        />
                      </div>
                      {alert.message && (
                        <div className={`alert-success-done ${alert.type}`}>
                          <span
                            className={`${
                              alert.type === "alert-success"
                                ? "check-line-success"
                                : "alert-danger-line"
                            }`}
                          />
                          <div className="alert-message-content">
                            {alert.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="from-group text-center mt-4">
                    {/* <Link to="/firsttimehomepage"> */}
                    <button
                      type="submit"
                      className="bnt-submit bg-color-4D4150 mb-0"
                    >
                      ログインする
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="design-by">
            © COACH A Co., Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}

/**
 * mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedFirstTimeLogin = connect(mapStateToProps)(FirstTimeLogin);
export { connectedFirstTimeLogin as FirstTimeLogin };
