import React from "react";
import { connect } from "react-redux";

import { isStagingServer } from "../../_helpers";

import Logomb from "../../../assets/styles/img/Logover2.png";


class ChangePasswordSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStaging:false
    };
  }
  componentDidMount() {
    if(isStagingServer()){
      this.setState({isStaging:true});
    }else{
      this.setState({isStaging:false});
    }  
  }

  render() {
    return (
      <div className="bg-color-F4F5F4">
        <div className="wrapper mobile assessment-first assessment-first-fix first-fix home-vertwo">
        {(this.state.isStaging===true) && (
           <div
             style={{backgroundColor:"#cac715",textAlign:"center",fontWeight:"bold",fontSize:"18px"}}
           >
	             <p>Staging</p>
           </div>
         )}
          <div className="header">
            <div className="header-content bg-color-F4F5F4">
              <div className="content-logo-mb-vetical pt-120 mb-40">
                <img src={Logomb} alt="" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content-vertwo">
              <div className="login-box">
                <form>
                  <div className="modal-content" style={{ background: "white" }}>
                    <div className="modal-body">
                      <h2 className="title-login text-4d4150">パスワード変更完了</h2>
                      <p className="title-login-content" style={{textAlign:"center"}}>
                        パスワードの変更が完了しました。新しいパスワードでログインしてください。
                      </p>
                      <div className="from-group note text-center">
                        <p className="note-para">
                          <a
                            href="/login"
                            className="link-submit fz-16 link-submit-forget"
                          >
                            ログイン画面へ
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="design-by">
          © COACH A Co., Ltd. All Rights Reserved.
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedChangePasswordSuccess = connect(mapStateToProps)(
  ChangePasswordSuccess
);
export { connectedChangePasswordSuccess as ChangePasswordSuccess };
