import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary, PublicErrorPage } from '../ErrorPage';
import { PublicAdminErrorPage } from '../AdminDashboard/ErrorPage';


function Frontend ({ component: Component, ...rest }) {
  return (
    <Route
      render={props => (
        <ErrorBoundary component={PublicErrorPage}>
          <Component {...props} />
        </ErrorBoundary>
      )}
      {...rest}
    />
  );
}


function Admin ({ component: Component, ...rest }) {
  return (
    <Route
      render={props => (
        <ErrorBoundary component={PublicAdminErrorPage}>
          <Component {...props} />
        </ErrorBoundary>
      )}
      {...rest}
    />
  );
}


export const PublicRoute = {
  Frontend,
  Admin,
};
