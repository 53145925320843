// import config from "config";
import axios from "axios";
import { authHeaderAdmin } from "../_helpers";

const API_CREATE_WORKSHOP = process.env.URL_API + "/v1/workshop/create";

export const workshopService = {
  createWorkShopService
};

const headers = {
  "Content-Type": "application/json",
  Authorization: authHeaderAdmin()
};

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("admin");
}

////////// Create workshop datetime
function createWorkShopService(workshop) {
  const body = JSON.stringify(workshop);
  return axios
    .post(API_CREATE_WORKSHOP, body, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logout();
        window.location.reload(true);
      }
      return error.response;
    });
}
